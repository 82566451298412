<template>
  <v-layout class="ggfont greytext">
    <v-container class="mt-0">
      <v-row>
        <v-col cols="12" md="9">
          <h1>Debatte um Homöopathie als Satzungsleistung in der GKV</h1>
          In letzter Zeit steht die Abschaffung homöopathischer Leistungen der
          Gesetzlichen Krankenversicherung (GKV) vermehrt im Mittelpunkt.
          <br />
          <br />
          Das Team der DornsteinTabelle hat dazu ausgewählte
          Naturheilkunde-Experten nach deren Einschätzung zu einem möglichen
          Wegfall homöopathischer Leistungen (Satzungsleistung GKV) befragt:
          <br /><br />
          <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
          <h2>Naturheilpraxis Karl Faulhaber, München</h2>
          Sowohl die GKV, als auch die Zusatzversicherungen übernehmen meist
          keine Kosten für homöopathische Mittel und begründen dies zunehmend
          damit, dass der wissenschaftliche Nachweis fehlt.
          <br />
          <br />
          Das beunruhigt mich nicht so sehr. Nehmen wir mal als Beispiel die
          Gabe von Arnika bei Rippenprellung. Da zahlt man ca. 7 Euro und kann
          das für weitere Beschwerden nehmen. Ist also nicht die "Welt".
          <br />
          <br />

          Was mich viel mehr beunruhigt ist die ständige polarisierte Diskussion
          mit falsch-positiven Dichotomien, ob man Homöopathie völlig verbieten
          kann oder den Verkauf in Apotheken untersagen kann usw.
          <br />
          <br />
          Das sind ja oft sehr intolerante und unwürdige Diskussionen, bei denen
          man sich auf die Wissenschaft beruht und sehr unwissenschaftlich und
          menschenwürdig argumentiert. Die Menschen machen ja nicht was
          "Verbotenes" sondern vertrauen ihrer Erfahrung und Wahrnehmung und
          sollten die Schmerzlinderung auf Suggestion beruhen,wäre das auch
          nicht schädlich. Die Werbung wird ja auch nicht abgeschafft.
          <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
          <h2>
            Naturheilpraxis & Reiki-und Yogazentrum Christiane Horst, Stoltebüll
          </h2>
          Meines Erachtens ist das ein erneuter Versuch, die Homöopathie auf
          längere Sicht gänzlich abzuschaffen. Da die Einsparungen nach
          Berechnungen lediglich zu 0,05 Prozent Kostenersparnis führen würden,
          ist das wirtschaftliche Argument nicht überzeugend. Außerdem wird ja
          auch die Forderung erhoben, homöopathische Mittel in den Apotheken zu
          verbieten?! Das macht deutlich, was hier gespielt wird...
          <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
          <h2>
            Praxis für Traditionelle Chinesische Medizin Silke Schreiber,
            Göttingen
          </h2>
          Für einen kritischen Menschen, der sich umfassend auch abseits der
          Mainstream Medien informiert und sich intensiv mit alternativen
          Heilmethoden und der Schulmedizin aber auch politischen Themen
          auseinandersetzt, dürfte sich ein klares Bild ergeben.
          <br />
          <br />
          Unsere Gesellschaft ist an Profit interessiert und das zeigt sich auch
          in der Medizin. Ein gesunder Mensch bringt kein Geld ein, ein Mensch,
          der homöopathische Mittel einnimmt, braucht keine teuren Arzneimittel.
          Mehr und mehr wird der Mensch zum unmündigen Bürger, der auch noch die
          Verantwortung für seinen eigenen Körper und seine Gesundheit abgeben
          soll. Es geht nicht um homöopathische Mittel, sondern um
          Selbstbestimmung auf allen Ebenen. Immer deutlicher wird, dass wir in
          einem kranken, korrupten und gewinnorientierten System leben, in dem
          Menschen nur noch als willenlose Konsumsklaven gehalten werden, die
          funktionstüchtig erhalten werden sollen, solange sie Geld
          erwirtschaften und auch wieder ausgeben. Es wird in allen Bereichen
          des Lebens deutlich: der Bildung, der Medizin, der Politik und vielen
          anderen Lebensbereichen.Gesund ist ein Mensch dann, wenn er vor allem
          geistig oder seelisch gesund ist.
          <br />
          <br />
          Die Medizin ist eine reine Reparaturmedizin geworden. In der
          traditionellen chinesischen Medizin und auch sicher in der Homöopathie
          schaut man auf die Gesundheit von Körper, Seele und Geist. In vielen
          Naturvölkern und in der Geschichte heilt man auf allen Ebenen. Die
          Heilungserfolge übertreffen sogar die der Schulmedizin. Uns hat man
          über Jahrhunderte erzählt, es sei nur Aberglaube. Doch der wahre
          Aberglaube ist das, was wir Schulmedizin nennen. Ein Mensch wird dann
          krank, wenn sein Leben nicht mehr zu ihm passt und es geht darum, die
          Wurzel der Erkrankung zu erkennen und diese zu verändern.
          <br />
          <br />
          Wenn man sich mit Epigenetik, Quantenheilung und anderen Methoden, die
          auch unseren Geist einbeziehen beschäftigt versteht man, dass ein
          Mensch eben ein Mensch ist und kein Roboter bei dem Teile ausgetauscht
          oder Warnsignale, die wir Krankheit nennen, einfach durch Pillen
          ausschaltet werden.. Mittlerweile gibt es dazu sehr viel Forschung und
          es wird immer deutlicher, was der Mensch wirklich braucht, um heil zu
          sein. Ein gesundes und glückliches Leben und Umfeld. Siehe dazu auch
          die Arte Dokumentation: Die Macht der Gedanken oder man höre sich
          Vorträge auf Youtube von Prof. Dr. Dr. Christian Schubert über
          Psychoneuroimmunologie an. Es geht vor allem darum, was den Menschen
          gesund hält. Es ist also nicht eine Frage, ob homöopathische Mittel
          verboten werden oder nicht, es geht darum, die Gesellschaft von
          Irrglauben zu heilen, dass die Schulmedizin das Beste für den Menschen
          sei.
          <br />
          <br />
          Meiner Meinung nach dient die Schulmedizin in großen Bereichen vor
          allem dem Profit der Pharmaindustrie. Es wird Zeit dafür, dass
          Menschen unabhängig von Profitinteressen einen Zugang zu den Methoden
          bekommen, die ihnen wirklich helfen. Und dass Menschen wieder selbst
          erkennen lernen, was gut für sie ist und wie sie ein gesundes und
          glückliches Leben führen können.
          <br />
          <br />
          Würde man schulmedizinische Behandlungen nach Erfolg überprüfen und
          davon die Zahlung durch Krankenkassen abhängig machen, so würden viele
          Methoden wegfallen (siehe Gerac Studie zu Rückenschmerzen und Migräne,
          in der die Akupunktur eindeutig erfolgreicher war.). Es gibt
          zahlreiche erfolgreiche alternative Verfahren für die
          unterschiedlichsten Beschwerden, doch in diesen Bereichen wird nicht
          geforscht, weil es keine Gelder dafür gibt. Warum wohl? Es gibt kein
          Interesse daran zu belegen, dass alternative Verfahren erfolgreicher
          sind als die kostenintensive Schulmedizin.
          <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
          <h2>Dipl.-Biol. Susanne W., Heilpraktikerin</h2>
          Da GKV-Versicherte Patient*innen sowieso keine Homöopathie in
          HP-Praxen unterstützt bekommen (wir können nicht mit den GKV
          abrechnen), macht das keinen Unterschied. <br /><br />Allerdings wird
          die Folge sein, dass auch die PKV und Beihilfen "ins Nachdenken"
          kommen, das trifft dann auch Patient*innen und Behandler*innen in
          HP-Praxen.<br /><br />
          Vor allem aber geht es um die Diskreditierung der Methode, weil nicht
          sein kann, was nicht sein darf. Die Einsparungen sind marginal,
          vielleicht ist es gerade das: Mit Homöopathie kann man nicht so große
          Gewinne machen....

          <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
          <h2>Naturheilpraxis Dr. Christiane Ewers, Eckernförde</h2>
          Ich glaube, es geht hier weniger um den Verlust für die Patienten, als
          darum, die Homöopathie zu diskreditieren!
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  onMounted(() => {
    proxy.$updateDocumentHead(
      "Debatte um Homöopathie Leistung der GKV",
      "Das Team der DornsteinTabelle hat Naturheilkunde-Experten nach ihrer Einschätzung zu einem möglichen Wegfall homöopathischer GKV-Leistungen befragt."
    );
  });
</script>
