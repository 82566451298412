<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <h1>Heilpraktiker- und Brillenzusatzversicherungen 2024</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <h2>
          Die besten Heilpraktikerversicherungen mit Leistungen für Sehhilfen im
          Vergleich
        </h2>
        Gibt es eine Heilpraktiker-Zusatzversicherung mit Leistungen für Brille?
        Ja. Viele ambulante Zusatzversicherungen bieten starke Leistungen für
        Sehhilfen (Brille, Kontaktlinsen, LASIK).
        <ul>
          <li>
            Die DornsteinTabelle bietet 8 leistungsstarke Heilpraktiker- und
            Brillenzusatzversicherungen
          </li>
          <li>
            Das Sehhilfen-Budget ist je Versicherung und Tarif unterschiedlich
            und variiert von 240,- € bis 400,- € alle zwei Jahre
          </li>
          <li>
            Das Budget für Sehhilfen umfasst meist Leistungen für Brillen,
            Kontaktlinsen, Sonnenbrillen sowie einem Budget für die LASIK
            Behandlung
          </li>
        </ul>
        Brillenträger kennen das. Wer vom Augenarzt eine Sehschwäche
        diagnostiziert bekommen hat, benötigt eine Sehhilfe, z.B. eine Brille.
        Und wer Brillenträger ist, muss dann mit wiederkehrenden Kosten für eine
        neue Brille in der Zukunft rechnen. Für Brillenträger fallen also in
        regelmäßigen Abständen Kosten für das neue Brillengestell und die beiden
        neuen Gläser an. Und wer eine modische bzw. zeitgemäße Brille möchte,
        muss als Erwachsener schon gerne zwischen 400 € - 1.000 € für die Brille
        veranschlagen. Je nach Hersteller des Brillengestells und den
        Anforderungen an die Brillengläser. Neben der sich verändernden
        Sehstärke (Dioptrie) und dem damit verbundenen Kauf einer neuen Brille,
        kann die Brille aber auch mal kaputt gehen. Das geht dann schon ins
        Geld. Ähnlich ist es für Kontaktlinsenträger. Auch hier fallen
        wiederkehrende Kosten an, z.B. für Kontaktlinsen und die
        Reinigungsflüssigkeit.
        <br /><br />
        Eine leistungsstarke Heilpraktiker- und Brillenzusatzversicherung hilft
        jenen Personen, die alternative Heilmethoden / Naturheilkunde nutzen
        möchten und zudem Brillenträger sind. Und laut Zentralverband der
        Augenoptiker und Optometristen tragen 41,1 Millionen Erwachsene (ab 16
        Jahren) in Deutschland eine Brille. Gerade Erwachsene müssen in der
        Regel alle Kosten für ihre Sehhilfe (Brille / Kontaktlinsen) selbst
        bezahlen. Behandlungskosten beim Heilpraktiker waren eh von jeher vom
        Patienten selbst zu zahlen. Hier gibt es dann folgende zwei
        Möglichkeiten. Anfallende Kosten privat zu bezahlen oder diese Kosten
        durch ambulante Zusatztarife für Heilpraktikerbehandlungen und
        Kostenerstattung für die Brille zu überschaubaren monatlichen Beiträgen
        abzusichern.

        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <h2>
          DornsteinTabelle Tarif-Empfehlungen: Starke Heilpraktiker- und
          Brillenzusatzversicherungen
        </h2>
        Leistungen starker Heilpraktiker- und Brillenzusatzversicherung im
        Überblick:
        <br /><br />
        <router-link
          to="/heilpraktiker_zusatzversicherung/continentale_easy_ambulant_1200"
        >
          Heilpraktiker- und Brillenzusatzversicherung Continentale Easy
          Ambulant 1200
        </router-link>
        <br />
        Naturheilkunde-Budget: 2.400 €, 100 % Erstattungssatz, alle zwei Jahre
        Sehhilfe-Budget: 300 € alle zwei Kalenderjahre, 100 % Erstattungssatz
        LASIK-Budget: 600 € je Auge / je Versicherungsfall
        <br /><br />
        <router-link to="/heilpraktiker_zusatzversicherung/arag_483">
          Heilpraktiker- und Brillenzusatzversicherung ARAG 483
        </router-link>
        <br />
        Naturheilkunde-Budget: 2.250 €, 90 % Erstattungssatz, alle zwei Jahre
        Sehhilfe-Budget: 330 € in 3 Jahren für Erwachsene, 165 € in 3 Jahren bei
        Kindern, 100 % Erstattungssatz LASIK-Budget: 1.000 € nach 3 Jahren
        Laufzeit im 4. Jahr

        <br /><br />
        <router-link
          to="/heilpraktiker_zusatzversicherung/gothaer_medi_ambulant"
        >
          Heilpraktiker- und Brillenzusatzversicherung Gothaer Medi Ambulant
        </router-link>
        <br />
        Naturheilkunde-Budget: 2.000 €, 80 % Erstattungssatz, alle zwei Jahre
        Sehhilfe-Budget: 240 € alle zwei Kalenderjahre, 100 % Erstattungssatz
        LASIK-Budget: keine Leistung

        <br /><br />
        <router-link to=" /heilpraktiker_zusatzversicherung/axa_med_komfort_u">
          Heilpraktiker- und Brillenzusatzversicherung AXA MED Komfort-U
        </router-link>
        <br />
        Naturheilkunde-Budget: 2.000 €, 80 % Erstattungssatz, alle zwei Jahre
        Sehhilfe-Budget: 300 € innerhalb von zwei aufeinanderfolgenden
        Versicherungsjahren, 100 % Erstattungssatz LASIK-Budget: 1.000 € gesamt
        (umfasst zwei Eingriffe in der Vertragslaufzeit) nach anfänglicher
        Begrenzung

        <br /><br />
        <router-link
          to="/heilpraktiker_zusatzversicherung/universa_uni_med_a_exklusiv"
        >
          Heilpraktiker- und Brillenzusatzversicherung Universa uni-med A
          Exklusiv
        </router-link>
        <br />
        Naturheilkunde-Budget: 1.800, 90 % Erstattungssatz, innerhalb von zwei
        Kalenderjahren Sehhilfe-Budget: 300 € alle zwei Kalenderjahre, 100 %
        Erstattungssatz LASIK-Budget: 1.500 € ab dem 5. Jahr, 750 € ab dem 3.
        Jahr

        <br /><br />
        <router-link
          to="/heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat"
        >
          Heilpraktiker- und Brillenzusatzversicherung Union Krankenversicherung
          NaturPRIVAT + VorsorgePRIVAT
        </router-link>
        <br />
        Naturheilkunde-Budget: 1.800 €, 80 % Erstattungssatz, 500 € im ersten
        Kalenderjahr, 1.000 € in den ersten beiden Kalenderjahren
        Sehhilfe-Budget: 400 € alle zwei Kalenderjahre, 80 % Erstattungssatz
        LASIK-Budget: 1.500 € einmalige Kostenerstattung für die
        LASIK-Operationen der Augen

        <br /><br />
        <router-link
          to="/heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus_pur"
        >
          Heilpraktiker- und Brillenzusatzversicherung Signal Iduna
          AmbulantPLUSpur
        </router-link>
        <br />
        Naturheilkunde-Budget: 1500 €, 80.00 % Erstattungssatz, für zwei
        Kalenderjahre Sehhilfe-Budget: 375 € alle zwei Kalenderjahre, 100 %
        Erstattungssatz LASIK-Budget: 1.000 € ab dem 4. Jahr

        <br /><br />
        <router-link
          to="/heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus"
        >
          Heilpraktiker- und Brillenzusatzversicherung Signal Iduna AmbulantPLUS
        </router-link>
        <br />
        Naturheilkunde-Budget: 1500 €, 80.00 % Erstattungssatz, für zwei
        Kalenderjahre Sehhilfe-Budget: 375 € alle zwei Kalenderjahre, 100 %
        Erstattungssatz LASIK-Budget: 1.000 € ab dem 4. Jahr

        <br /><br />
        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <h3>
          Für wen ist eine Heilpraktiker- und Brillenzusatzversicherung
          sinnvoll?
        </h3>
        <h3>Lohnt sich eine Heilpraktiker- und Brillenzusatzversicherung?</h3>

        Wenn Sie gesetzlich krankenversichert sind, an Naturheilkunde
        interessiert und Brillenträger sind, können Sie Kosten beim
        Heilpraktiker erstattet bekommen und zudem weitestgehend Kosten der
        neuen Brille von der Zusatzversicherung erstattet bekommen. Für diese
        Personengruppe ist eine ambulante Zusatzversicherung mit Heilpraktiker-
        und Brillen-Zusatzleistungen absolut sinnvoll.
        <br /><br />

        Aktuell übernehmen gesetzliche Krankenkassen (GKVs) nur noch die Kosten
        von Sehhilfen für Jugendliche unter 18 Jahren. Heilpraktikerleistungen
        werden fast ausnahmslos, weder für Kinder noch für Erwachsene seitens
        der GKV übernommen. Diese sog. Versorgungslücken der gesetzlichen
        Krankenversicherung (GKV) können mit einem Kombiangebot der
        Heilpraktiker- und Brillenzusatzversicherung geschlossen werden. Ein
        Blick in die Zukunft lässt zudem erahnen, dass sich an dieser Situation
        nichts ändern wird, da die gesetzlichen Kassen künftig noch mehr
        Leistungen kürzen, als verbessern werden.
        <br /><br />
        <h3>Was bietet eine Heilpraktiker-Zusatzversicherung?</h3>
        Die Heilpraktiker-Zusatzversicherung erstattet anfallende
        Behandlungskosten für Naturheilkunde / alternative Heilmethoden wie z.B.
        Akupunktur, TCM oder Osteopathie. Wichtig dabei ist, dass der gewählte
        Tarif für Behandlungen durch einen Heilpraktiker und Arzt für
        Naturheilverfahren leistet und bis zum Höchstsatz der Gebührenordnung
        für Heilpraktiker (GebüH) und dem Hufeland-Leistungsverzeichnis
        abrechnet.
        <br /><br />
        <h3>Was bietet eine Brillenzusatzversicherung?</h3>
        Die Brillenversicherung erstattet für Sehhilfen bis zu einem vertraglich
        festgelegten Budget für meist alle zwei Jahre z.B. 300 € und einen
        darauf bezogenen Prozentwert (Höchstsatz) z.B. 80 %. Dabei werden Kosten
        für eine Brille, Sonnenbrille mit Sehstärke oder für Kontaktlinsen
        erstattet. Zudem leisten einige Anbieter auch noch für
        LASIK-Behandlungen, ein chirurgisches Verfahren zur Korrektur der
        Fehlsichtigkeit.
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  import BaseCalls from "@/services/BaseCalls";
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 40);
    proxy.$updateDocumentHead(
      "Heilpraktiker- und Brillenzusatzversicherungen",
      "Heilpraktikerversicherungen mit Leistungen für Brille, Kontaktlinsen und LASIK. Wir haben die besten Zusatztarife 2024 ausgewertet."
    );
  });
</script>
