<template>
  <div class="ggfont greytext">
    <v-card elevation="0" style="background-color: #f2f2f2">
      <v-card-text>
        <!-- <v-app-bar dark color="grey">
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
  
              <v-toolbar-titled
                >Mein Clearment | Login | Anspruch prüfen</v-toolbar-title
              >
  
              <v-spacer></v-spacer>
  
             <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-app-bar> -->
        <v-container v-if="user">
          <v-row>
            <v-col cols="12" md="12">
              <h2 class="green--text">Sie sind jetzt erfolgreich eingeloggt</h2>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!user">
          <h2>
            <span class="redtext">Heilpraktiker-Login</span> DornsteinTabelle.de
          </h2>
          <v-row dense>
            <v-col cols="12" md="10">
              <v-text-field
                v-model="select.email"
                label="E-Mail"
              ></v-text-field>
              <v-text-field
                v-model="select.checkcheck"
                label="Passwort"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="10" class="pt-4"
              ><v-btn
                color="turk"
                @click="startLogin()"
                class="nocaps"
                outlined
                tile
                >jetzt einloggen</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script setup>
  import BaseCalls from "@/services/BaseCalls";
  import { ref } from "vue";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import { useUserStore } from "@/stores/user";
  import { useRouter } from "vue-router";

  const router = useRouter();
  const userStore = useUserStore();
  const errorStore = useErrorStore();
  const loaderStore = useLoaderStore();
  const select = ref({
    email: "",
    checkcheck: "",
    action: "startLogin",
  });

  const user = ref(false);

  async function startLogin() {
    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(select.value); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.user) {
        user.value = resp.data.user;
        userStore.setUser(resp.data.user);
        setLoading(false);
        router.push("/kostenlos/anfordern");
      } else {
        setLoading(false);
        errorStore.setError(true, "Fehler beim einloggen.");
      }
    } catch (err) {
      setLoading(false);
      errorStore.setError(true, "Fehler beim einloggen.");
    }
  }

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
</script>
