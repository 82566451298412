<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>RealWert Heilpraktiker Durchschnittserstattung</h2>
        Der Dornstein RealWert Heilpraktiker gibt die Durchschnittserstattung
        der Naturheilkunde Leistungen auf Basis eines Warenkorbes von
        Muster-Behandlungsverläufen an. Dieser ist realitätsnah zusammengestellt
        und setzt sich aus jeweils 4 Jahren mit verschiedenen Erst- und
        Folgebehandlungen zusammen. Dieser umfasst klassische Anwendungen aus
        den Bereichen Homöopathie,Akupunktur, Chiropraktik, Osteopathie. Der
        Dornstein RealWert Heilpraktiker gibt die prozentuale Erstattungshöhe
        des jeweiligen Heilpraktiker-Zusatztarifes in Bezug auf den Warenkorb
        wieder.
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
