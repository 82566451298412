<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-12" style="max-width: 1000px">
      <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs>
      <v-row class="mb-8">
        <v-col cols="12" md="12">
          <h1>Heilpraktiker-Zusatzversicherung Vorerkrankung</h1>
          <em>Die besten Heilpraktikerversicherungen bei Vorerkrankung</em>
          <br /><br />
          Kann man eine Heilpraktiker-Zusatzversicherung mit Vorerkrankung
          abschließen? Ja. Es ist möglich, eine Heilpraktiker-Zusatzversicherung
          trotz Vorerkrankung abzuschließen. Ausschlaggebend ist daher, welche
          Vorerkrankung diagnostiziert wurde.
          <ul class="my-4 ml-4">
            <li>
              >DornsteinTabelle bietet 7 leistungsstarke
              Heilpraktiker-Zusatzversicherungen die mit einfachen
              Gesundheitsfragen abgeschlossen werden können
            </li>
            <li>
              Einfache Gesundheitsfragen bedeutet, dass zwar auch
              Gesundheitsfragen zu beantworten sind, jedoch diese
              Gesundheitsfragen nicht ganz so weitreichend gestellt werden
            </li>
            <li>
              Eine Heilpraktiker-Zusatzversicherung kann auch trotz
              Vorerkrankung abgeschlossen werden. Entscheidend ist die ärztliche
              Diagnose, welche Vorerkrankung besteht und ob ein Abschluss mit
              dieser Vorerkrankung noch möglich ist
            </li>
          </ul>
          <h2>
            Heilpraktiker-Zusatzversicherung bei Vorerkrankung abschließen - Das
            müssen Sie wissen:
          </h2>
          DornsteinTabelle hat 7 Heilpraktiker-Zusatzversicherungen
          zusammengestellt, die auch trotz Vorerkrankung abgeschlossen werden
          können. Manche Versicherer fragen rückwirkend “lediglich” max. 5 Jahre
          ab. Gesundheitsfragen sind sorgfältig zu lesen und am Besten mit dem
          Hausarzt bzw. weiteren Behandlern der vergangenen Jahre telefonisch
          abzuklären.
          <br /><br />
          Entscheidend ist, dass der Abschluss mit Ihrer Vorerkrankung im Rahmen
          der Gesundheitsfragen der Heilpraktikerversicherung noch möglich ist.
          Hier ist nach dem Ausschlussverfahren vorzugehen. Ist “meine Diagnose”
          nicht in den Gesundheitsfragen aufgeführt. Ist eine Annahme im Tarif
          wahrscheinlich.
          <br /><br />
          Die Vorerkrankung darf nicht zur Ablehnung des Antrags führen. Das
          wäre dann der Fall, wenn die Vorerkrankung eine schwere Erkrankung
          z.B. Krebs oder eine chronische Krankheit z.B. Asthma bronchiale wäre.
          In diesen Fällen wäre eine Annahme in einer Heilpraktikerversicherung
          nicht mehr möglich.<br /><br />
          <h2>
            DornsteinTabelle Tarif-Empfehlungen:
            Heilpraktiker-Zusatzversicherungen mit einfachen Gesundheitsfragen /
            teils ohne Wartezeit
          </h2>
          <b>Tarif-Empfehlungen:</b> Heilpraktiker-Zusatzversicherungen mit
          einer Gesundheitsfrage:
          <ul class="my-4 ml-4">
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/universa_uni_med_a_exklusiv"
                >Heilpraktiker-Zusatzversicherung Universa uni-med A Exklusiv</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus_pur"
                >Heilpraktiker-Zusatzversicherung Signal Iduna
                AmbulantPLUSpur</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus"
                >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUS</a
              >
            </li>
          </ul>
          <br /><br />
          <b>Tarif-Empfehlungen:</b> Heilpraktiker-Zusatzversicherungen mit
          einfachen Gesundheitsfragen:
          <ul class="my-4 ml-4">
            <li>
              <a href="heilpraktiker_zusatzversicherung/axa_med_komfort_u"
                >Heilpraktiker-Zusatzversicherung AXA MED Komfort-U</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat"
                >Heilpraktiker-Zusatzversicherung Union Krankenversicherung
                NaturPRIVAT</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat_vorsorgeprivat"
                >Heilpraktiker-Zusatzversicherung Union Krankenversicherung
                NaturPRIVAT + VorsorgePRIVAT</a
              >
            </li>
          </ul>

          <h2>
            Unser Tipp: Heilpraktikerversicherungen mit abschließend zu
            beantwortenden Gesundheitsfragen.
          </h2>

          <b
            ><a
              href="heilpraktiker_zusatzversicherung/universa_uni_med_a_premium"
              >Universa uni-med A Premium</a
            >
            und
            <a
              href="heilpraktiker_zusatzversicherung/universa_uni_med_a_exklusiv"
              >Universa uni-med A Exklusiv Heilpraktiker-Zusatzversicherungen</a
            ></b
          ><br />
          Die zu beantwortenden Gesundheitsfragen werden abschließend gestellt.
          Das bedeutet, ist eine Diagnose / Krankheit nicht bei den
          Gesundheitsfragen aufgeführt, ist diese für die Annahme bei der
          Versicherung nicht relevant.
          <br /><br />
          <b
            ><a
              href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat"
              >Union Krankenversicherung NaturPRIVAT
              Heilpraktiker-Zusatzversicherung</a
            ></b
          ><br />
          Die zu beantwortenden Gesundheitsfragen werden abschließend gestellt.
          Das bedeutet, ist eine Diagnose / Krankheit nicht bei den
          Gesundheitsfragen aufgeführt, ist diese für die Annahme bei der
          Versicherung nicht relevant.
          <b>Hinweis:</b> Nahrungsmittelunverträglichkeiten wie Laktose-,
          Fruktoseintoleranz sind keine Allergien und daher nicht relevant.
          <br /><br />
          <b
            ><a
              href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus_pur"
              >Signal Iduna AmbulantPLUSpur</a
            >
            und
            <a href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus"
              >Signal Iduna AmbulantPLUS Heilpraktiker-Zusatzversicherungen</a
            ></b
          ><br />
          Dieser Tarif stellt ebenfalls sehr konkrete Gesundheitsfragen, welche
          einen Abschluss erleichtern. Die Versicherung möchte eben nur die
          genannten Diagnosen / Krankheiten ausschließen.
          <br /><br />
          <b>Wichtig:</b> Gesundheitsfragen sind stets wahrheitsgemäß zu
          beantworten. Bitte unbedingt behandelnde Ärzte und Heilpraktiker vor
          Abschluss befragen, wenn Sie sich nicht sicher sind, was in der
          Patientenakte steht / wie diese Diagnosen ausgelegt werden. Für
          vorvertraglich eingetretene Krankheiten / Diagnosen wird nicht
          geleistet. <br /><br />

          <h2>
            Sind bei einer Heilpraktiker-Zusatzversicherung Gesundheitsfragen zu
            beantworten?
          </h2>
          Ja. Heilpraktikerversicherungen stellen Gesundheitsfragen, die direkt
          im Versicherungsantrag zu beantworten sind. So kann die Versicherung
          entscheiden, ob die Person im jeweiligen Heilpraktiker-Tarif
          angenommen wird oder eben nicht. Das nennt man Risikoprüfung. Dabei
          sind Heilpraktikerversicherungen streng.
          <br /><br />
          <b
            >Welchen Zeitraum können Heilpraktiker-Zusatzversicherungen bei
            Gesundheitsfragen abfragen?</b
          >
          <ul>
            <li>Drei Jahre rückwirkend zu diversen Krankheiten</li>
            <li>
              Fünf Jahre rückwirkend zu stationären Krankenhausaufenthalten
            </li>
            <li>
              Zehn Jahre rückwirkend zu psychotherapeutischen Behandlungen
            </li>
          </ul>

          <b
            >Haben Sie Fragen zum Thema Vorerkrankung bei der
            Heilpraktiker-Zusatzversicherung?</b
          ><br />
          Sie haben Fragen, was unter einer Vorerkrankung zu verstehen ist? Ob
          Sie trotz Vorerkrankung eine Heilpraktiker-Zusatzversicherung
          abschließen können? Sie möchten eine Vorerkrankung mitversichern?
          Haben Fragen zu einem möglichen Beitragszuschlag wegen Vorerkrankung?
          Wir helfen gerne. Email an info@dornsteintabelle.de genügt.
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const { proxy } = getCurrentInstance();
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 6);
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherung bei Vorerkrankung",
      "Heilpraktikerversicherungen können auch mit Vorerkrankung abgeschlossen werden. Wir zeigen, welche Heilpraktiker Zusatzversicherungen dies ermöglichen."
    );
  });
</script>
