export async function loadFonts() {
  const webFontLoader = await import(
    /* webpackChunkName: "webfontloader" */ "webfontloader"
  );

  webFontLoader.load({
    custom: {
      families: ["PT Serif:n4,i4,n7,i7"],
      urls: ["/css/style.css"],
    },
  });
}
