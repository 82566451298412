<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-12" style="max-width: 1000px">
      <!-- <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs> -->
      <v-row class="mb-8">
        <v-col cols="12" md="12">
          <h1>Heilpraktiker-Zusatzversicherung ohne Begrenzungen</h1>
          <em> Heilpraktikerversicherungen mit 100 % Erstattung </em>

          Heilpraktikerversicherungen können zu 100 % für Naturheilverfahren
          leisten. Die Gesamterstattung wird jedoch auf einen Höchstbetrag pro
          Jahr begrenzt, abhängig vom Budget der gewählten
          Heilpraktikerversicherung. Das Versicherungsbudget kann dann Jahr für
          Jahr erneut zu 100 % bis zum Höchstbetrag ausgeschöpft werden.
          Heilpraktiker-Zusatzversicherung ohne Begrenzungen gibt es also leider
          keine.
          <ul class="my-4 ml-4">
            <li>
              Heilpraktiker-Zusatzversicherungen können zu 100 Prozent für
              Naturheilkunde leisten
            </li>
            <li>
              Aktuell bieten nur wenige Heilpraktikerversicherungen einen
              Erstattungsprozentsatz von 100 %
            </li>
            <li>
              Wir bieten 3 leistungsstarke Heilpraktikerversicherungen mit 100 %
              Erstattung
            </li>
            <li>
              All unsere Heilpraktiker-Zusatzversicherungen bieten Leistung bis
              zum Höchstbetrag Hufelandverzeichnis / GebüH / GOÄ
            </li>
          </ul>
          <br /><br />

          <h2>
            DornsteinTabelle Tarif-Empfehlungen: Diese
            Heilpraktikerversicherungen leisten zu 100 % und ohne Wartezeit
          </h2>
          <ul class="my-4 ml-4">
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_2000"
                >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
                2.000</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_1000"
                >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
                1.000</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/nuernberger_budgetselect_privat_600"
                >Heilpraktiker-Zusatzversicherung Nürnberger BudgetSelect privat
                600</a
              >
            </li>
          </ul>

          Der Barmenia Mehr Gesundheit 2.000 und der Barmenia Mehr Gesundheit
          1.000 haben ein Versicherungsjahr, in dem Leistungen in Anspruch
          genommen werden können. Von Beginn 1.5. können 12 Monate alle
          Leistungen tarifgemäß in Anspruch genommen werden. Also vom 1.5. bis
          zum 30.04. des darauffolgenden Jahres.
          <br /><br />
          <b>TIPP:</b> Der Nürnberger BudgetSelect privat 600 bietet eine
          Besonderheit. Volles Budget auch bei einem unterjährigen
          Versicherungsbeginn, ohne Wartezeit. Es gibt also noch die volle
          Leistung des Budgets von 600,- €, selbst wenn ich die Versicherung mit
          Beginn 1.12. abschließe. Ab 1.1. des darauffolgenden Jahres steht mir
          wieder das volle Budget zur Verfügung. Zudem kann der Nürnberger
          BudgetSelect privat 600 mit nur einer Gesundheitsfrage abgeschlossen
          werden. <br /><br />

          <h2>
            Heilpraktiker-Zusatzversicherungen mit 100 % Erstattung erklärt
          </h2>

          Gibt es eine Heilpraktiker-Zusatzversicherung, die unbegrenzt leistet?
          Leider nein. Heilpraktiker-Zusatzversicherungen sind meist auf einen
          jährlichen Höchstbetrag begrenzt. Die versicherte Person kann also das
          tariflich festgelegte Budget von z.B. 1.000,- € oder 2.000,- € pro
          Jahr bis 100 % Erstattung voll nutzen.
          <br /><br />
          Es gibt eine Heilpraktiker-Zusatzversicherung, die “unbegrenzt”
          leistet. Die Heilpraktiker-Zusatzversicherung Continentale CEK-PLUS-U
          leistet für Heilpraktiker-Behandlungen ohne Begrenzung. Doch Vorsicht,
          der Erstattungsprozentsatz beträgt 80 % bezogen auf den Mindestsatzes
          der Gebührenordnung für Heilpraktiker GebüH, was die Leistungsstärke
          dieses Tarifes erheblich schwächt und dieser Tarif auch nicht auf der
          DornsteinTabelle gelistet ist.
          <br /><br />
          <h2>
            Die drei “Leistungs-Parameter” für
            Heilpraktiker-Zusatzversicherungen
          </h2>

          Wie hoch die Erstattung einer Heilpraktikerversicherung ist, hängt von
          folgenden drei Parametern ab:
          <ul class="my-4 ml-4">
            <li>
              Die prozentuale Erstattungshöhe der
              Heilpraktiker-Zusatzversicherung z.B. 100 % Erstattung für
              Naturheilverfahren
            </li>
            <li>
              Die maximale Höchstbetrag, also den tariflich festgelegten
              Höchstbetrag in Euro von z.B. 1.000,- € Budget pro Jahr
            </li>
            <li>
              Den Höchstbetrag nach Gebührenordnung für Heilpraktiker (GebüH)
              und Hufelandverzeichnis / GÖÄ
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  onMounted(() => {
    proxy.$updateDocumentHead(
      "Gibt es eine Heilpraktiker-Zusatzversicherung ohne Begrenzungen?",
      "Heilpraktikerversicherungen können zu 100 % für Naturheilverfahren leisten.  Wir bieten 3 Heilpraktiker Zusatzversicherungen, die zu 100 % leisten."
    );
  });
</script>
