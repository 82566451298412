<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <span v-if="tarif.empfehlung_type == 3">
          <b class="text-green"
            >Dornstein`s Empfehlung "Beste Leistung"<br /> </b
          >Aufgrund Ihrer Vorgaben bietet die {{ tarif.un_name }}
          {{ tarif.tarif_title }} die für Sie besten Leistungen.
        </span>
        <span v-if="tarif.empfehlung_type == 1 || tarif.empfehlung_type == 2">
          <b class="text-green">Dornstein`s "Alternativ"-Empfehlung </b><br />
          Die {{ tarif.un_name }} {{ tarif.tarif_title }} ist eine sehr gut und
          preislich attraktive Alternative.
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
