<template>
  <div>
    <v-card-text>
      <h2>Erstinformationen für Kunden | powered by WaizmannPro</h2>
      <br />
      <p>
        Dieser Vergleich wird Ihnen von der WaizmannPro GmbH (unabhängiger
        Versicherungsmakler) zur Verfügung gestellt. Die Antragsabwicklung wird
        von der WaizmannPro GmbH durchgeführt. Ihre Daten werden nicht
        weitergegeben oder zur Werbezwecken verwendet. <br />
        <br />

        <br />
        <b>Geschäftsführung WaizmannPro</b>
        <br />Ricarda Meier <br />
        <br />

        <br />
        <br />
        <b>Postanschrift</b>
        <br />Maria-Einsiedel-Str. 20 <br />81379 München
        <br />

        <br />Schreiben Sie uns eine E-Mail: <br />info@waizmannpro.de
        <br />
        <br />

        <br />
        <b>Beteiligung an/von Versicherungsunternehmen</b>
        <br />Das Unternehmen besitzt keine direkten oder indirekten
        Beteiligungen oder Stimmrechte am Kapital eines
        Versicherungsunternehmens. Es besitzt auch kein Versicherungsunternehmen
        direkte oder indirekte Stimmrechte oder Beteiligungen am Kapital der
        WaizmannPro GmbH.
        <br />
        <br />
        <br />
        <b>Vermittlerregister</b>
        <br />Die WaizmannPro GmbH ist Versicherungsmakler mit Erlaubnis gemäß
        §34 d Abs. 1 GewO registriert unter IHK-Nr. D-WEFL-B1QD0-82.
        <br />
        <br />
        <br />
        <b>Vermögensschadenhaftpflichtversicherung</b>
        <br />Eine den Bestimmungen der EU-Vermittlerrichtlinie entsprechende
        Berufshaftpflichtversicherung/Vermögensschadenhaftpflichtversicherung
        liegt vor (über Nassau Versicherungen).
        <br />
        <br />
        <b>Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:</b> Florian
        Meier
        <br />
        <br />
        <b>Zuständige Aufsichtsbehörde</b> <br />
        Industrie- und Handelskammer für München und Oberbayern <br />
        Max-Joseph-Str. 2 <br />
        80333 München <br />
        Telefonnummer: 089-5116-0 <br />
        Telefax: 089-5116-1306 <br />
        E-mail: info@muenchen.ihk.de <br />
        web: www.ihk-muenchen.de

        <br />
        <br />
        <br />
        <b>Anschrift der Schlichtungsstellen</b>
        Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin) <br />
        Graurheindorfer Straße 108, 53117 Bonn <br />
        Telefon: 0228-4108-0 <br />
        Telefax: 0228-4108-62299 <br />
        E-mail: schlichtungsstelle@bafin.de <br />
        web: www.bafin.de <br />

        <br />
        <br />
        <br />
        <b>Versicherungsombudsmann e.V.</b>
        Postfach 080632, 10006 Berlin <br />
        Telefon: 0800-3696000<br />
        Telefax: 0800-3699000<br />
        E-mail: beschwerde@versicherungsombudsmann.de <br />
        web: www.versicherungsombudsmann.de <br />

        <br />
        <br />
        <b>Ombudsmann für die private Kranken- und Pflegeversicherung</b>
        Postfach 060222, 10052 Berlin<br />
        Telefon: 0800-2550444 <br />
        Telefax: 030-20458931 <br />
        web: www.pkv-ombudsmann.de<br />
      </p>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    name: "erst-info-pop",
    components: {},
  };
</script>

<style scoped></style>
