<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-0" style="max-width: 1000px">
      <v-row class="mb-8">
        <v-col cols="12" md="8">
          <h1>Über mich, die DornsteinTabelle und wie es dazu kam</h1>
          Als Kilian geboren im April 1979, befand ich mich nach meiner Geburt
          nicht lange zu Hause. Grund dafür war eine sog. Saugschwäche. Nun,
          wenige Wochen nach meinem ersten Krankenhausaufenthalt, meiner Geburt,
          folgte auch schon der Zweite. Diesmal war es ein Darmverschluss.
          Dieser beschäftigte mich bzw. meine Ärzte und Ärztinnen, diverse
          Krankenschwestern, meine Eltern…intensiv! Drei Monate war ich dann
          also im Krankenhaus, um dann in das Leben zu starten, wie man so schön
          sagt.
          <br /><br />
          Jahre später, zur aufregendsten Zeit mit 18 Jahren war ich erneut im
          Krankenhaus. Da ging es dann um einen Verwachsungsbauch, Folge meiner
          frühkindlichen Operationen. Danach habe ich mich bis heute immer in
          einem “inneren Konflikt” befunden, eine Art Zwickmühle also. Zum einen
          hatte ich eine ziemlich einschneidende Vorgeschichte, die mich
          begleiten wird bis ich die Augen auf unendlich stelle, zum anderen war
          mir klar, das Leben voll genießen zu wollen. Erst recht.
          Wahrscheinlich kann ein Außenstehender, wie das ja oft so ist, meine
          Situation und die damit verbundenen Schmerzen und Befindlichkeiten als
          Folge eines Ileus nicht nachvollziehen. Der Schnitt von letztlich ca.
          21 cm haben eine Kraft über mich, mit der nicht immer gut Kirschen
          essen ist, aber genug davon. Aufgrund etwas schwerer zu resorbierender
          Nährstoffe bis hin zum Immunsystem, Verstoffwechselung etc., einem
          Bauch, der eben nicht mit einem “Normalbauch” entspricht. Man hört und
          liest ja immer, wie wichtig der Darm ist und ja, es ist wirklich so.
          Mein Weg führte mich im Laufe der Zeit zu Heilpraktikern und zu meinem
          Hausarzt, welcher auch Arzt für Naturheilverfahren war. So kam ich mit
          der Materie Naturheilkunde immer wieder in Berührung und habe viel
          über die Gesundheit, die Tücken des Bauches gelernt.
          <br /><br />
          <h2>Über die DornsteinTabelle</h2>
          Aber warum schreibe ich all das hier, was hat all das mit
          Heilpraktiker-Zusatzversicherungen und der Dornstein
          4-Jahres-Realwert-Methode zu tun? Nun gut, hier die Auflösung. Als
          Versicherungsmakler habe ich mich schon lange mit
          Krankenzusatzversicherungen für Gesetzlich Versicherte beschäftigt.
          Zeit genug, sich mit einer für mich wichtigen bzw. interessanten
          Zusatzversicherung, der Heilpraktiker-Zusatzversicherung zu
          beschäftigen. Die Krux dabei sind nämlich die Gesundheitsfragen,
          welche die Versicherung im Antrag stellt. Nicht jeder, der eine
          Naturheilkunde-Zusatzversicherung möchte, wird z.B. aufgrund von
          Vorerkrankungen angenommen. Man gilt als nicht versicherungsfähig. Das
          Risiko ist der Versicherung eben zu hoch. Bei mir ist dies ja gegeben.

          <br /><br />
          <h2>und wie es dazu kam</h2>
          Anders gesagt, ich möchte mit DornsteinTabelle bzw. der entwickelten
          Dornstein 4-Jahres-Realwert-Methode Menschen helfen, die im Sinne
          einer Versicherung quasi “gesund genug” sind, um als Gesetzlich
          Versicherter noch in einer Heilpraktiker-Zusatzversicherung angenommen
          zu werden. Als ein Mensch, der zwar der Schulmedizin sehr viel
          verdankt, bin ich dennoch mehr und mehr zu der Überzeugung gekommen,
          wie sinnvoll, wichtig und richtig Naturheilkunde ist.. Also wer mehr
          auf seinen Körper hört, kann präventiv mit natürlichen Mitteln seine
          Gesundheit stärken, dadurch viel mehr bewirken als man glaubt und hat
          dadurch mehr “Energie fürs Leben”.
          <br /><br />
          <h2>Die Dornstein 4-Jahres-Realwert-Methode</h2>
          DornsteinTabelle hat es sich zur Aufgabe gemacht, eine
          versicherungsunabhängige Tarifanalyse von
          Heilpraktiker-Zusatzversicherungen durchzuführen. Auf der Suche nach
          den real leistungsstärksten Heilpraktiker-Zusatzversicherungen wurde
          dazu eigens die Dornstein 4-Jahres-Realwert-Methode entwickelt. Die
          Tarifanalyse nach der 4-Jahres-Realwert-Methode basiert auf einem
          “Warenkorb von Naturheilkunde Behandlungen”, die ein Musterpatient im
          Laufe von 4 Jahren in Anspruch nimmt. Diese zeigt die
          durchschnittliche Leistungsstärke unterschiedlichster
          Naturheilkunde-Tarife und macht diese so miteinander vergleichbar.
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  onMounted(() => {
    proxy.$updateDocumentHead(
      "Über die DornsteinTabelle",
      "Über mich, die DornsteinTabelle und wie es dazu kam."
    );
  });
</script>
