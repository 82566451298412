<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <h1>Heilpraktiker-Zusatzversicherung Familienversicherung</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <h2>
          Es gibt keine Familienversicherung- oder Partnertarife bei der
          Heilpraktiker-Zusatzversicherung
        </h2>
        Für jede Person muss ein einzelner Vertrag abgeschlossen werden.
        <ul>
          <li>
            Warum gibt es keine Heilpraktiker-Zusatzversicherung als
            Familienversicherung- oder Partnertarif?
          </li>
          <li>
            Müssen Gesundheitsfragen der Familienmitglieder im Antrag einer
            Heilpraktiker-Zusatzversicherung eigens beantwortet werden?
          </li>
          <li>
            Gibt es Rabatt oder Skonto von der Versicherung, wenn mehrere
            Familienmitglieder im gleichen Heilpraktiker-Zusatztarif versichert
            sind?
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>
          Gesetzliche Krankenversicherung vs. private Krankenzusatzversicherung
        </h2>

        Im Vergleich zur gesetzlichen Krankenversicherung (GKV) gibt es bei der
        privaten Krankenzusatzversicherung keine Familienversicherung. Es muss
        für jede Person ein einzelner Vertrag abgeschlossen werden. Eine
        Heilpraktiker-Zusatzversicherung gilt grundsätzlich immer nur für eine
        Person. Somit gibt es keine Familien-, Partner- oder Ehepartner Tarife.
        Selbst wenn mehrere Familienmitglieder oder die beiden Ehepartner im
        gleichen Tarif derselben Versicherungsgesellschaft versichert sind, gibt
        es weder Skonti noch Rabatte auf die Beiträge.
        <br /><br />
        Eine Krankenzusatzversicherung ist eine private Zusatzversicherung
        (privatrechtlicher Vertrag), die zusätzlich, als Ergänzung der
        Basisleistungen der gesetzlichen Krankenkasse, abgeschlossen werden
        kann. Je nach persönlichen Bedürfnissen. Die gesetzliche
        Krankenversicherung (GKV) leistet nach dem Solidaritätsprinzip und
        gehört zu den Pflichtversicherungen in Deutschland, wobei hier eine
        Familie komplett versichert werden kann. Die z.B.
        Heilpraktiker-Zusatzversicherung ist eine private Zusatzversicherung zur
        GKV und leistet nach dem Individualitätsprinzip. Dabei trägt
        versicherungstechnisch jede zu versichernde Person ein eigenes Risiko,
        welches durch die Versicherungsgesellschaft einzeln geprüft, beurteilt
        und versichert werden muss. Dazu stellt die Versicherungsgesellschaft
        sogenannte Gesundheitsfragen, welche von der zu versichernden Person
        eigens im Antrag zu beantworten sind.
        <br /><br />
        Wenn die ganze Familie auf der Suche nach einer passenden
        Heilpraktiker-Zusatzversicherung ist, ist es wichtig, die gewünschten
        Leistungen vorab abzustecken, die eigene Patientenakte und deren
        Eintragungen zu kennen, um später alle Gesundheitsfragen des jeweiligen
        Heilpraktiker-Tarifes wahrheitsgemäß beantworten zu können. Zudem eignet
        sich nicht jede Heilpraktikerversicherung gleichermaßen für Erwachsene
        wie für Kinder. Manche Versicherungsgesellschaften “drosseln” das
        Versicherungsbudget für Naturheilkunde-Leistungen oder
        Sehhilfe-Leistungen bei Versicherten im Kindes- und Jugendalter.
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  import BaseCalls from "@/services/BaseCalls";
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 41);
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherung Familienversicherung",
      "Gibt es eine Heilpraktikerversicherung als Familienversicherung oder Partnertarif? Nein. Für jede Person muss ein einzelner Vertrag abgeschlossen werden. "
    );
  });
</script>
