<template>
  <div class="pa-0">
    <div v-if="showSegmentType == 'starten'" class="mb-4">
      <v-row>
        <v-col
          cols="12"
          class="headline pa-2"
          style="border-bottom: 4px solid #2196f3"
        >
          <b>Neue Berechnung starten</b>
          <span class="hidden-xs-only"
            >&nbsp;|&nbsp; inkl. AntragAnnahmecheck
          </span></v-col
        >
      </v-row>

      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          Sie erfahren
          <strong class="text-green">jetzt sofort</strong>
          (Zeitaufwand ca. 30 Sekunden):
          <ol class="body-1 pt-1 ml-4">
            <li>
              welche Tarife abschließbar sind (<b>AntragAnnahmecheck</b>). 100 %
              anonym.
            </li>
            <li>
              welche
              <strong>Zahnzusatzversicherung</strong> am besten für Sie geeinet
              ist
            </li>
            <li>
              <b>wieviel die besten Tarife kosten</b> und wie diese getestet
              wurden
            </li>
          </ol>
        </v-col>
      </v-row>
    </div>

    <div v-if="showSegmentType == 'erwachsene'" class="mb-4">
      <v-row>
        <v-col cols="12" class="blue text-white headline pa-2">
          Vergleich <strong>Erwachsene</strong>
          <span class="hidden-xs-only">
            &nbsp; | &nbsp;
            <strong>Zahnzusatzversicherung</strong>
          </span></v-col
        >
      </v-row>
      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          Sie erhalten
          <strong class="text-green">jetzt sofort</strong>
          eine detaillierte Auswertung der besten Zahnzusatzversicherungen.
        </v-col>
      </v-row>
    </div>

    <div v-if="showSegmentType == 'zahnkind'" class="mb-4">
      <v-row>
        <v-col
          cols="12"
          class="headline pa-2"
          style="border-bottom: 4px solid #fecf0c"
        >
          <b>Neue Berechnung "Kind" starten</b>
          <span class="hidden-xs-only"
            >&nbsp;|&nbsp; inkl. AntragAnnahmecheck
          </span></v-col
        >
      </v-row>
      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          Sie erhalten
          <strong class="text-green">jetzt sofort</strong>
          eine detaillierte Auswertung der besten
          Kinder-Zahnzusatzversicherungen mit Schwerpunkt Kieferorthopädie. Der
          <b>AntragAnnahmecheck</b> wird 100 % anonym durchgeführt.
        </v-col>
      </v-row>
    </div>

    <div v-if="showSegmentType == 'zahnplus'" class="mb-4">
      <v-row>
        <v-col
          cols="12"
          class="headline pa-2"
          style="border-bottom: 4px solid #513b83"
        >
          <b>Neue Berechnung "60Plus" starten</b>
          <span class="hidden-xs-only"
            >&nbsp;|&nbsp; inkl. AntragAnnahmecheck
          </span></v-col
        >
      </v-row>
      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          Sie erhalten
          <strong class="text-green">jetzt sofort</strong>
          eine detaillierte Auswertung der besten Zahnzusatzversicherungen.
          Schwerpunkt Zahnersatz, Prothetik und Zahnerhalt. Der
          <b>AntragAnnahmecheck</b> wird 100 % anonym durchgeführt.
        </v-col>
      </v-row>
    </div>

    <div v-if="showSegmentType == 'brille'" class="mb-4">
      <v-row>
        <v-col
          cols="12"
          style="border-bottom: 4px solid #ff8000"
          class="headline pa-2"
        >
          <strong>Neue Berechnung starten</strong>
          <span class="hidden-xs-only">
            &nbsp; | &nbsp;
            <strong>Brille &amp; Sehhilfe</strong>
          </span></v-col
        >
      </v-row>
      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          <strong>In 30 Sekunden erhalten Sie:</strong>
          <ol class="pl-8 pt-2">
            <li>den Brillen-Tarif der für Sie am besten geeignet ist</li>
            <li>eine detaillierte Tarifanalyse (Testbericht)</li>
            <li>einen Online-Vorab-Annahme-Check</li>
          </ol>
        </v-col>
      </v-row>
    </div>

    <div v-if="showSegmentType == 'heilpraktiker'" class="mb-4">
      <v-row>
        <v-col
          cols="12"
          style="border-bottom: 4px solid #088a85"
          class="headline pa-2"
        >
          <strong>Neue Berechnung starten</strong>
          <span class="hidden-xs-only">
            &nbsp; | &nbsp;
            <strong>Heilpraktiker</strong>
          </span></v-col
        >
      </v-row>
      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          <strong>In 30 Sekunden erhalten Sie:</strong>
          <ol class="pl-8 pt-2">
            <li>den Heilpraktiker-Tarif der für Sie am besten geeignet ist</li>
            <li>eine detaillierte Tarifanalyse (Testbericht)</li>
            <li>einen Online-Vorab-Annahme-Check</li>
          </ol>
        </v-col>
      </v-row>
    </div>

    <div v-if="showSegmentType == 'krankenhaus'" class="mb-4">
      <v-row>
        <v-col
          cols="12"
          style="border-bottom: 4px solid cyan"
          class="headline pa-2"
        >
          <strong>Neue Berechnung starten</strong>
          <span class="hidden-xs-only">
            &nbsp; | &nbsp;
            <strong>Krankenhaus-Tarife</strong>
          </span></v-col
        >
      </v-row>
      <v-row v-if="!sharedDataStore.isMobile()">
        <v-col cols="12">
          <strong>In 30 Sekunden erhalten Sie:</strong>
          <ol class="pl-8 pt-2">
            <li>
              die Krankenhaus-Zusatzversicherung die für Sie am besten geeignet
              ist
            </li>
            <li>eine detaillierte Tarifanalyse (Testbericht)</li>
            <li>einen Online-Vorab-Annahme-Check</li>
          </ol>
        </v-col>
      </v-row>
    </div>

    <!-- Form first row -->
    <v-form
      ref="berechnungFormRef"
      v-show="!berechnung_submited"
      @submit.prevent="submitBerechnung"
    >
      <!-- ber_jahr, 
                   ber_monat 
                            ber tag - Geburtstag-->

      <v-row>
        <v-col cols="12" md="3" class="subheading"
          >Ihr <b>Geburtstag</b>
          <span v-if="berechnung.ber_erst == 1">
            <br />
            <a @click="resetAll()">neue Person berechnen</a>
          </span>
        </v-col>
        <v-col cols="12" md="9">
          <input-geburtstag-select
            :key="componentKeyStatus"
            v-on:update:year="yearChanged"
            v-on:update:month="monthChanged"
            v-on:update:day="dayChanged"
            :year="berechnung.ber_jahr"
            :month="berechnung.ber_monat"
            :day="berechnung.ber_tag"
        /></v-col>
      </v-row>

      <v-divider class="blue my-2"></v-divider>

      <div
        v-if="showKfoInfo && berechnung.ber_segment < 2"
        class="pa-2 my-4 mit_ohne_KFO"
        style="border-right: 4px solid #fecf0c"
      >
        <h3>Tarife mit Leistung für Kieferorthopädie?</h3>
        <p>
          Soll die gesuchte Zahnzusatzversicherung noch für Kieferorthopädie
          &nbsp; Zahnspangen leisten, oder benötigen Sie das nicht mehr?
        </p>

        <wtb-check-box
          label-data="Kieferorthopädie soll mitversichert werden"
          class="headline"
          :input="berechnung.ber_kfoja"
          @update:input="updateValueBer('ber_kfoja', $event)"
        />

        <p>
          <b>Wichtig:</b> eine Versicherung mit Leistungen für Zahnspangen
          können Sie nur dann abschließen, wenn aktuell noch keine
          Zahnfehlstellung bei Ihrem Kind diagnostiziert worden ist!
        </p>
      </div>

      <!-- ber_geschlecht - Geschlecht -->

      <v-row>
        <v-col cols="12" md="7">Ihr <b>Geschlecht</b></v-col>
        <v-col cols="12" md="5">
          <v-radio-group class="pl-4 ma-0" v-model="berechnung.ber_geschlecht">
            <v-row>
              <v-col md="6" style="min-width: 80px">
                <v-radio
                  key="0"
                  label="Frau"
                  color="green"
                  :value="0"
                ></v-radio>
              </v-col>

              <v-col md="6" style="min-width: 80px">
                <v-radio
                  key="1"
                  label="Mann"
                  color="green"
                  :value="1"
                ></v-radio>
              </v-col>
            </v-row> </v-radio-group
        ></v-col>
      </v-row>

      <v-divider class="blue my-2"></v-divider>

      <v-row>
        <v-col cols="12" md="7" class="subheading"
          >Wie sind Sie <b>versichert</b>?</v-col
        >
        <v-col cols="12" md="5">
          <v-select
            background-color="white"
            v-model="berechnung.ber_gesetzl"
            :items="versicherungsArten"
            item-value="id"
            item-title="name"
            single-line
          ></v-select>
          <span v-if="berechnung.ber_segment < 2">
            <wtb-check-box
              label-data="möchte  Zahnversicherung wechseln"
              color="green"
              class="caption"
              @change="showTarifSuche()"
              :input="berechnung.ber_wechsel"
              @update:input="updateValueBer('ber_wechsel', $event)"
            />
          </span>
        </v-col>
      </v-row>

      <v-alert
        border="bottom"
        colored-border
        color="blue"
        dismissible
        v-show="berechnung.ber_gesetzl == '2' && berechnung.ber_segment < 2"
        >Wenn Sie in der PKV (privaten Krankenversicherung) versichert sind,
        können Sie keine "normale" Zahnzusatzversicherung beantragen.</v-alert
      >

      <v-divider class="mb-3"></v-divider>

      <!-- Start Brille -->
      <div
        v-if="showSegmentType == 'brille' || showSegmentType == 'heilpraktiker'"
      >
        <berechnung-form-row>
          <template v-slot:title>
            <div>
              Haben Sie bereits eine
              <strong>Sehhilfe</strong> oder ist eine solche angeraten?
            </div>
            <div class="caption"></div>
          </template>

          <template v-slot:input>
            <v-radio-group
              @change="resetSehhilfe()"
              class="pa-0 ma-0"
              v-model="berechnung.ber_brille_ja"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </template>
        </berechnung-form-row>

        <!-- <div class="ml-8" v-if="berechnung.ber_brille_ja == 1">
          <berechnung-form-row>
            <template v-slot:title>
              <div>
                Soll diese
                <strong>Sehhilfe</strong> mitversichert werden? (empfohlen, da
                i.d.R. kostenlos möglich)
              </div>
              <div class="caption"></div>
            </template>

            <template v-slot:input>
              <v-radio-group
                class="pa-0 ma-0"
                v-model="berechnung.ber_brille_vers_ja"
              >
                <v-row>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="1"
                      label="Ja"
                      color="green"
                      :value="1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="0"
                      label="Nein"
                      color="green"
                      :value="0"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </template>
          </berechnung-form-row>
        </div> -->
      </div>

      <div
        v-if="
          showSegmentType == 'heilpraktiker' || showSegmentType == 'krankenhaus'
        "
      >
        <berechnung-form-row>
          <template v-slot:title>
            Haben Sie
            <strong>chronischen Erkrankungen</strong>
            oder
            <strong>schwere Vorerkrankungen</strong>?

            <div class="caption">
              <a v-on:click="showBerChronisch = !showBerChronisch">
                Welche Art von Krankheiten zählen darunter...</a
              >
            </div>
          </template>

          <template v-slot:input>
            <v-radio-group
              class="pa-0 ma-0"
              v-model="berechnung.ber_top_gesund"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </template>
        </berechnung-form-row>
        <v-alert
          dismissible
          border="bottom"
          colored-border
          color="blue"
          v-if="showBerChronisch"
          type="info"
        >
          <ul>
            <li>
              Erkrankungen des Nervensystems, des Gehirns, der Psyche, des
              Herzens, der Gefäße, der Lunge, der Leber, der Bauchspeicheldrüse,
              des Magens, des Darms, des Blutes, des Kreislaufs, der Harn- und
              Geschlechtsorgane, des Stoffwechsels, der Haut, der Muskeln,
              Knochen oder Gelenke
            </li>
            <li>eine Krebserkrankung</li>
            <li>eine Suchterkrankung</li>
            <li>Eine HIV-Infektion</li>
          </ul>
        </v-alert>
      </div>
      <div v-if="showSegmentType == 'brille'">
        <berechnung-form-row v-if="berechnung.ber_brille_ja == 1">
          <template v-slot:title>
            <div class="white pa-4">
              Haben Sie neben einer eventuellen Sehschwäche
              <strong>weitere Augenkrankheiten?</strong>
            </div>
          </template>

          <template v-slot:input>
            <div class="white pa-4">
              <v-radio-group
                class="pa-0 ma-0"
                v-model="berechnung.ber_brille_krankheit"
              >
                <v-row>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="1"
                      label="Ja"
                      color="green"
                      :value="1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="0"
                      label="Nein"
                      color="green"
                      :value="0"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </template>
        </berechnung-form-row>

        <berechnung-form-row>
          <template v-slot:title>
            <div>
              Möchten Sie eine
              <strong>Sonnenbrille mit Sehstärke</strong>
              mitversichern?
            </div>
          </template>

          <template v-slot:input>
            <v-radio-group
              class="pa-0 ma-0"
              v-model="berechnung.ber_brille_sonnenbrille"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </template>
        </berechnung-form-row>

        <berechnung-form-row>
          <template v-slot:title>
            <div>
              Haben Sie bereits eine
              <b>Gleitsichtbrille</b> oder planen eine Gleitsichtbrille zu
              benutzen?
            </div>
          </template>

          <template v-slot:input>
            <v-radio-group
              class="pa-0 ma-0"
              v-model="berechnung.ber_brille_gleitsicht"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </template>
        </berechnung-form-row>

        <berechnung-form-row>
          <template v-slot:title>
            <div>
              Möchten Sie
              <strong>Augenlaser-Operationen (Lasik)</strong>
              mitversichern?
            </div>
          </template>

          <template v-slot:input>
            <v-radio-group
              class="pa-0 ma-0"
              v-model="berechnung.ber_brille_lasik"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </template>
        </berechnung-form-row>

        <berechnung-form-row>
          <template v-slot:title>
            <div>
              Möchten Sie eine
              <strong>Hörgerät</strong> mitversichern?
            </div>
          </template>

          <template v-slot:input>
            <v-radio-group
              @change="resetSehhilfe()"
              class="pa-0 ma-0"
              v-model="berechnung.ber_hoergeraet_versicherung"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </template>
        </berechnung-form-row>

        <berechnung-form-row v-if="berechnung.ber_hoergeraet_versicherung == 1">
          <template v-slot:title>
            <div class="white pa-4">
              Tragen Sie eine Hörhilfe, ist eine Hörgeräteversorgung angeraten?
            </div>
          </template>

          <template v-slot:input>
            <div class="white pa-4">
              <v-radio-group
                class="pa-0 ma-0"
                v-model="berechnung.ber_hoergeraet_massnahme"
              >
                <v-row>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="1"
                      label="Ja"
                      color="green"
                      :value="1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="0"
                      label="Nein"
                      color="green"
                      :value="0"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </template>
        </berechnung-form-row>
      </div>

      <!-- Ende Brille -->

      <!-- Start Zähne -->
      <div v-if="berechnung.ber_segment < 2">
        <!-- #bayplz -->
        <v-row>
          <v-col cols="12" md="8">
            <b>Ihre Postleitzahl?</b> Bitte geben Sie die Postleitzahl Ihres
            Wohnortes <br /><b>5-stellig</b>. Einige Tarife gewähren je nach
            Wohnort einen günstigeren Beitrag.
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              maxlength="5"
              minlength="5"
              v-model="berechnung.ber_plz"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            Läuft gerade eine
            <strong>zahnärztliche Behandlung</strong>
            oder wurde empfohlen?

            <div class="caption">
              außer Aufbiss-Schienen und prof. Zahnreinigung (PZR)
              <a v-on:click="showBerZahnmassInfo = !showBerZahnmassInfo"
                >Erklärung anzeigen</a
              >
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-radio-group
              class="pl-4 ma-0"
              v-model="berechnung.ber_zahnmass"
              @change="berMassnahmeChanged($event)"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-alert
          dismissible
          border="bottom"
          colored-border
          color="blue"
          v-if="showBerZahnmassInfo"
          type="info"
        >
          <zahnmass-info class="body-2" />
        </v-alert>

        <div
          class="pl-4"
          v-show="berechnung.ber_zahnmass == 1 && berechnung.ber_alter > 17"
        >
          <!-- <v-row >
                  <v-col cols="12" md="9">
                    Die
                    <strong>zahnärztliche Behandlung</strong>
                    wurde bereits vor mehr als 2 Jahre angeraten?
                    <div class="caption"></div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-radio-group
                      class="pl-4 ma-0"
                      v-model="berechnung.ber_zahnmass_2"
                    >
                     <v-row>
                        <v-col cols="12" md="6" style="min-width: 80px">
                          <v-radio
                            key="1"
                            label="Ja"
                            color="green"
                            :value="1"
                          ></v-radio>
                        </v-col>
                        <v-col cols="12" md="6" style="min-width: 80px">
                          <v-radio
                            key="0"
                            label="Nein"
                            color="green"
                            :value="0"
                          ></v-radio>
                        </v-col>
                     </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row> -->

          <v-row>
            <v-col cols="12" md="8">
              Soll diese Behandlung von Ihrer Versicherung
              <b>bereits bezahlt werden</b>? (ist gegen Aufschlag möglich)
              <div class="caption"></div>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group class="pl-4 ma-0" v-model="berechnung.ber_sofort">
                <v-row>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="1"
                      label="Ja"
                      color="green"
                      :value="1"
                    ></v-radio>
                  </v-col>
                  <v-col cols="6" style="min-width: 80px">
                    <v-radio
                      key="0"
                      label="Nein"
                      color="green"
                      :value="0"
                    ></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
        </div>

        <v-divider class="blue my-2"></v-divider>

        <!-- ber_aufbiss - Aufbiss, Zahnschiene -->
        <v-row>
          <v-col cols="12" md="8">
            Haben Sie eine
            <strong>Aufbiss</strong>-bzw. <strong>Zahnschiene</strong>?
            <br />
            <a
              class="caption"
              v-on:click="showBerAufbissInfo = !showBerAufbissInfo"
              >Detaillierte Erklärung...</a
            >
          </v-col>
          <v-col cols="12" md="4">
            <v-radio-group class="pl-4 ma-0" v-model="berechnung.ber_aufbiss">
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-alert
          border="bottom"
          colored-border
          color="blue"
          dismissible
          v-if="showBerAufbissInfo"
          type="info"
        >
          <aufbiss-info class="body-2" />
        </v-alert>

        <v-divider class="blue my-2"></v-divider>

        <!-- ber_zahnfehl - Zahnfehlstellung -->
        <v-row>
          <v-col cols="12" md="8">
            Besteht eine
            <strong>ärztlich festgestellte Zahnfehlstellung</strong>?

            <div class="caption">
              Wurde bereits eine kieferorthopädische Behandlung empfohlen?
              <a v-on:click="showBerZahnfehlInfo = !showBerZahnfehlInfo"
                >Ausführliche Erklärung...</a
              >
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-radio-group class="pl-4 ma-0" v-model="berechnung.ber_zahnfehl">
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-alert
          border="bottom"
          colored-border
          color="blue"
          dismissible
          v-if="showBerZahnfehlInfo"
          type="info"
        >
          <zahnfehl-info class="body-2" />
        </v-alert>

        <v-divider class="blue my-2"></v-divider>

        <!-- ber_zb_3 - Parodontosebehandlung -->

        <v-row v-if="berechnung.ber_alter > 6">
          <v-col cols="12" md="8">
            Wurde bei Ihnen bereits eine
            <strong>Parodontosebehandlung</strong>
            durchgeführt?
            <a class="caption" v-on:click="showBerZb3Info = !showBerZb3Info"
              >Mehr Infos anzeigen...</a
            >
          </v-col>
          <v-col cols="12" md="4">
            <v-radio-group class="pl-4 ma-0" v-model="berechnung.ber_zb_3">
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-alert
          border="bottom"
          colored-border
          color="blue"
          dismissible
          v-if="showBerZb3Info"
          type="info"
        >
          <zb3-info class="body-2" />
        </v-alert>

        <v-divider
          v-if="berechnung.ber_alter > 6"
          class="blue my-2"
        ></v-divider>

        <!-- ber_ze_ja - Fehlzahn -->
        <v-row v-if="berechnung.ber_alter > 6">
          <v-col cols="12" md="8">
            Haben Sie
            <b>fehlende</b> oder <b>ersetzte</b> Zähne?

            <div class="caption">
              <b class="text-green">Ersetzte Zähne sind z.B:</b>
              Kronen, Implantate, Veneers, Prothesen und Brücken - bitte Frage
              mit "
              <b>ja</b>" beantworten
              <br />
              <b>Fehlende Zähne sind nicht:</b>
              Weisheitszähne, Milchzähne, sog ."Lückenschlüsse" - bitte Frage
              mit "
              <b>nein</b>" beantworten
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-radio-group
              class="pl-4 ma-0"
              v-model="berechnung.ber_ze_ja"
              @change="berZeChanged($event)"
            >
              <v-row>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="1"
                    label="Ja"
                    color="green"
                    :value="1"
                  ></v-radio>
                </v-col>
                <v-col cols="6" style="min-width: 80px">
                  <v-radio
                    key="0"
                    label="Nein"
                    color="green"
                    :value="0"
                  ></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- Fehlende zähne - zusätzliche Fragen -->
        <div
          v-show="
            (berechnung.ber_ze_ja == 1 || berechnung.ber_luecke > 0) &&
            berechnung.ber_alter > 6
          "
          style="padding-left: 30px"
        >
          <!-- ber_luecke: Wie viele fehlzähne? -->
          <berechnung-form-row>
            <template v-slot:title>
              <div>
                Wie viele <strong>Zähne fehlen</strong> Ihnen, die nicht ersetzt
                wurden oder sind bereits zum Ziehen angeraten?
              </div>
              <div class="caption">
                Der Rechner sucht Tarife, die
                <strong>fehlende Zähne mitversichern.</strong>
                <br />
                <a v-on:click="showBerLueckeInfo = !showBerLueckeInfo"
                  >Mehr Infos..</a
                >
              </div>
            </template>
            <template v-slot:input>
              <v-select
                background-color="white"
                v-model="berechnung.ber_luecke"
                :items="berLueckeSelect"
                item-title="name"
                item-value="id"
                single-line
                @update:modelValue="berLueckeChanged"
              ></v-select>
            </template>
          </berechnung-form-row>

          <v-alert
            border="bottom"
            colored-border
            color="blue"
            dismissible
            v-if="showBerLueckeInfo"
            type="info"
          >
            <luecke-info class="body-2" />
          </v-alert>

          <berechnung-form-row v-if="berechnung.ber_luecke != 0">
            <template v-slot:title>
              <div>
                Wollen Sie bereits bestehende Lücken
                <strong class="text-green">mitversichern</strong>?
                <span
                  class="caption"
                  v-if="berechnung.ber_luecke > 0 && berechnung.ber_luecke < 3"
                >
                  <br />Bei einem fehlenden Zahn, empfehlen wir Ihnen
                  <strong class="text-green">diese zu versichern</strong>. Dies
                  ist oft kostenlos möglich. Bei mehreren fehlenden Zähnen ist
                  dies teilweise mit Aufschlag möglich.
                </span>
              </div>
            </template>
            <template v-slot:input>
              <wtb-check-box
                label-data="Ja"
                color="green"
                class="headline"
                :input="berechnung.ber_luecke_vers"
                @update:input="updateValueBer('ber_luecke_vers', $event)"
              />
            </template>
          </berechnung-form-row>

          <v-divider class="blue my-2"></v-divider>

          <berechnung-form-row>
            <template v-slot:title>
              <div>
                Anzahl der durch herausnehmbare
                <strong>Prothesen</strong>
                ersetzten Zähne?
                <br />
                <a
                  class="caption"
                  v-on:click="showBerZahnersetztInfo = !showBerZahnersetztInfo"
                  >Mehr Infos dazu...</a
                >
              </div>
            </template>
            <template v-slot:input>
              <v-select
                background-color="white"
                v-model="berechnung.ber_zahnersetzt"
                :items="berLueckeSelect"
                item-title="name"
                item-value="id"
                single-line
              ></v-select>
            </template>
          </berechnung-form-row>

          <berechnung-form-row v-if="berechnung.ber_zahnersetzt != 0">
            <template v-slot:title>
              <div>
                Haben Sie ersetzte Zähne/Prothesen die älter als 10 Jahre sind?
              </div>
            </template>
            <template v-slot:input>
              <wtb-check-box
                label-data="Ja"
                color="green"
                class="headline"
                :input="berechnung.ber_zahnersatz_10"
                @update:input="updateValueBer('ber_zahnersatz_10', $event)"
              />
            </template>
          </berechnung-form-row>

          <v-alert
            border="bottom"
            colored-border
            color="blue"
            dismissible
            v-if="showBerZahnersetztInfo"
            type="info"
          >
            <zahnersetzt-info class="body-2" />
          </v-alert>

          <v-divider class="blue my-2"></v-divider>

          <berechnung-form-row>
            <template v-slot:title>
              <div>
                Wie viele
                <strong>Brücken</strong> haben Sie?
                <br />
                <a
                  class="caption"
                  v-on:click="showBerBrueckeInfo = !showBerBrueckeInfo"
                  >Erklärung anzeigen</a
                >
              </div>
            </template>
            <template v-slot:input>
              <v-select
                background-color="white"
                v-model="berechnung.ber_bruecke"
                :items="berLueckeSelect"
                item-title="name"
                item-value="id"
                single-line
              ></v-select>
            </template>
          </berechnung-form-row>

          <v-alert
            border="bottom"
            colored-border
            color="blue"
            dismissible
            v-if="showBerBrueckeInfo"
            type="info"
          >
            <bruecke-info class="body-2" />
          </v-alert>

          <v-divider class="blue my-2"></v-divider>

          <berechnung-form-row>
            <template v-slot:title>
              <div>
                Wie viele (Teil-)
                <strong>Kronen</strong> und <strong>Implantate</strong> haben
                Sie?
                <br />
                <a
                  class="caption"
                  v-on:click="showBerKronenInfo = !showBerKronenInfo"
                  >Detail-Infos anzeigen</a
                >
              </div>
            </template>
            <template v-slot:input>
              <v-select
                background-color="white"
                v-model="berechnung.ber_kronen"
                :items="berLueckeSelect"
                item-title="name"
                item-value="id"
                single-line
              ></v-select>
            </template>
          </berechnung-form-row>

          <v-alert
            border="bottom"
            colored-border
            color="blue"
            dismissible
            v-if="showBerKronenInfo"
            type="info"
          >
            <kronen-info class="body-2" />
          </v-alert>

          <v-divider class="blue my-2"></v-divider>
        </div>
        <!-- ENDE FZ -->
      </div>
      <!-- Ende Zahnfragen -->
      <!-- ErstInformationen -->

      <v-row class="py-4 pl-2" v-if="berechnung.ber_erst == 0">
        <v-col cols="12" md="1">
          <v-checkbox
            color="green"
            @click="erstInfoChecked()"
            :rules="validateCheckbox()"
            class="py-0 my-0"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="11">
          <h3>
            <span class="text-green">Ja,</span> ich habe die Erstinformation für
            Kunden erhalten
          </h3>

          <a @click="erstInfoChecked()">
            Erstinformationen für Versicherungsmakler
          </a>
          gemäß § 15 VersVermV
          <a @click="showErstInfoPop = !showErstInfoPop"
            >jetzt anzeigen oder herunterladen.</a
          >
          <p v-if="errorMessage" class="text-orange">{{ errorMessage }}</p>
        </v-col>
      </v-row>

      <v-row class="py-4 pl-2" v-if="berechnung.ber_erst == 0 && berErstError">
        <v-col cols="12"
          ><h3 class="text-orange">
            Bitte setzen Sie den Haken auf "Ja" um eine Berechnung zu starten
          </h3></v-col
        >
      </v-row>
      <v-row
        pa-4
        v-if="
          sucheTarife &&
          berechnung.ber_segment <= 1 &&
          berechnung.ber_wechsel == 1
        "
      >
        <v-col cols="12">
          <h3>
            <span class="text-green">Optional:</span>
            welche Zahnzusatzversicherungen haben Sie bis jetzt?
          </h3>
          <v-text-field
            v-model="search"
            background-color="white"
            type="text"
            label="Name Ihrer Zahnzusatzversicherung (die Sie wechseln wollen)"
          ></v-text-field>

          <br />
          <span v-if="filteredTarife && search">
            <b
              >Wählen Sie jetzt Ihre Zahnzusatzversicherung aus (wenn
              möglich)</b
            >
            <br />

            <v-radio-group v-model="berechnung.ber_wechsel_tarif_id">
              <v-radio
                @click="setWechselTarif(tarif.tarif_id, tarif.tarif_name)"
                v-for="tarif in filteredTarife"
                :key="`tarifsuche${tarif.tarif_id}`"
                :value="tarif.tarif_id"
                :label="tarif.tarif_name"
              ></v-radio>
            </v-radio-group>
          </span>
        </v-col>
      </v-row>

      <!-- Submit Berechnung -->
      <v-row class="py-4 pl-2" v-if="showGeburtstagFehler">
        <v-col cols="12"
          ><h3 class="text-orange">
            Bitte geben Sie ein Geburtsdatum an
          </h3></v-col
        >
      </v-row>

      <v-row class="white">
        <v-col cols="12">
          <wtb-check-box
            v-if="
              berechnung.ber_erst == 1 &&
              berechnung.ber_segment <= 1 &&
              berechnung.ber_alter > 17
            "
            label-data="wollen Sie Bleaching mitversichern?"
            color="green"
            class="caption"
            :input="berechnung.ber_bleaching"
            @update:input="updateValueBer('ber_bleaching', $event)"
          />
          <v-btn
            :color="showBtnColor"
            style="height: 48px"
            elevated
            tile
            rounded="0"
            type="submit"
            class="button_caps"
            tonal
            value="jetzt Berechnung starten &nbsp; Ergebnis anzeigen"
          >
            <span class="text-white" v-if="!sharedDataStore.isMobile()">
              <b style="font-size: 24px">Vergleich starten | </b
              ><span style="font-size: 24px">jetzt hier klicken</span>
            </span>
            <span v-if="sharedDataStore.isMobile()" style="font-size: 24px">
              Vergleich starten
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>Der Rechner zeigt Ihnen sofort:</h3>
          <ul v-if="berechnung.ber_segment == 2">
            <li>die leistungstärksten Zusatzversicherungen für Sehhilfen</li>
          </ul>

          <ul v-if="berechnung.ber_segment == 3">
            <li>die leistungstärksten Heilpraktiker-Zusatzversicherungen</li>
          </ul>

          <ul v-if="berechnung.ber_segment == 4">
            <li>die leistungstärksten Krankenhaus-Zusatzversicherungen</li>
          </ul>

          <!-- ZahnOnly -->
          <ul v-if="berechnung.ber_segment < 2" class="ml-4">
            <li v-if="wechselTarifName">
              Sie wollen diesen Tarif wechseln:
              <strong class="text-green">{{ wechselTarifName }}</strong>
            </li>
            <li v-show="berechnung.ber_alter < 9">
              die leistungstärksten Zahnzusatzversicherungen für Kinder |
              <strong class="text-green"
                >{{ berechnung.ber_alter }} Jahre</strong
              >
            </li>
            <li v-show="berechnung.ber_alter >= 9 && berechnung.ber_alter < 18">
              die leistungstärksten Zahnzusatzversicherungen für Jugendlichen |
              <strong class="text-green"
                >{{ berechnung.ber_alter }} Jahre</strong
              >
            </li>
            <li
              v-show="
                berechnung.ber_luecke_vers == 1 &&
                berechnung.ber_luecke > 0 &&
                berechnung.ber_alter > 18
              "
            >
              Tarife die bereits fehlende Zähne mitversichern
            </li>

            <li v-show="berechnung.ber_kfoja == '1'">
              Tarife mit möglichst hohen Kieferorthopädie-Leistungen
            </li>
            <li v-show="berechnung.ber_alter >= 18">
              die leistungstärksten Zahnzusatzversicherungen | Eintrittsalter:
              <strong class="text-green"
                >{{ berechnung.ber_alter }} Jahre</strong
              >
            </li>
            <li v-show="berechnung.ber_alter >= 60">
              die besten Zahnzusatzversicherungen mit möglichst hohen Leistungen
              für Prothetik, Implantate und Zahnersatz.
            </li>
            <li>
              Zahnzusatzversicherungen mit dem besten Preis-Leistungsverhältnis
            </li>
            <li v-show="berechnung.ber_zahnmass == 1">
              Tarife die Sie
              <strong>trotz laufender Behandlung</strong>
              beantragen können
            </li>
            <li v-show="berechnung.ber_zahnfehl == 1">
              Tarife die Sie
              <strong>auch mit einer Zahnfehlstellung</strong>
              beantragen können
            </li>
            <li v-show="berechnung.ber_zb_3 == 1">
              Tarife die Sie
              <strong>trotz laufender Paradontose</strong>
              beantragen können
            </li>
            <li v-show="berechnung.ber_aufbiss == 1">
              Tarife die
              <strong class="text-green">Aufbiss-Schienen</strong>
              mitversichern
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-dialog width="777" v-model="showErstInfoPop">
        <v-card>
          <erst-info-pop />
        </v-card>
      </v-dialog>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import {
    ref,
    reactive,
    getCurrentInstance,
    watchEffect,
    computed,
    onMounted,
    defineEmits,
    watch,
  } from "vue";
  import WtbCheckBox from "@/components/berechnung/form/WtbCheckBox";
  import BerechnungFormRow from "@/components/berechnung/form/BerechnungFormRow";
  import InputGeburtstagSelect from "@/components/berechnung/form/InputFormGeburtstagSelect";
  import ZahnmassInfo from "@/components/berechnung/info/ZahnmassInfo";
  import AufbissInfo from "@/components/berechnung/info/AufbissInfo";
  import ZahnfehlInfo from "@/components/berechnung/info/ZahnfehlInfo";
  import Zb3Info from "@/components/berechnung/info/Zb3Info";
  import LueckeInfo from "@/components/berechnung/info/LueckeInfo";
  import ZahnersetztInfo from "@/components/berechnung/info/ZahnersetztInfo";
  import BrueckeInfo from "@/components/berechnung/info/BrueckeInfo";
  import KronenInfo from "@/components/berechnung/info/KronenInfo";
  import ErstInfoPop from "@/components/berechnung//info/ErstInfoPop";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useGuestStore } from "@/stores/guest";
  import { useRoute } from "vue-router";
  import BaseCalls from "@/services/BaseCalls";

  const guestStore = useGuestStore();
  const sharedDataStore = useSharedDataStore();
  const emit = defineEmits(["updateBerechnung"]);
  const route = useRoute();
  const berechnungStore = useBerechnungStore();
  const { proxy } = getCurrentInstance();
  const showBerChronisch = ref(false);
  const showBerZahnmassInfo = ref(false);
  const showBerAufbissInfo = ref(false);
  const showBerZahnfehlInfo = ref(false);
  const showBerZb3Info = ref(false);
  const showBerLueckeInfo = ref(false);
  const showBerZahnersetztInfo = ref(false);
  const showBerBrueckeInfo = ref(false);
  const showBerKronenInfo = ref(false);
  const showKfoInfo = ref(false);
  const showErstInfoPop = ref(false);
  const componentKeyStatus = ref(0);
  const routeSegment = ref(1);
  const appLoaded = ref(true);
  const showGeburtstagFehler = ref(false);
  const berechnung = reactive({
    ber_guest_id: 0,
    ber_rechner_type: 1,
    ber_wechsel_tarif_id: 0,
    ber_annahme_tarif_id: 0,
    ber_kunden_id: "0",
    ber_segment: 1,
    ber_wechsel: 0,
    ber_alter: 0,
    ber_plz: "",
    ber_aufbiss: 0,
    ber_bruecke: 0,
    ber_bleaching: 0,
    ber_kronen: 0,
    ber_geschlecht: 0,
    ber_gesetzl: 1,
    ber_zahnmass: 0,
    ber_zahnmass_2: 1,
    ber_sofort: 0,
    ber_tag: new Date().getDate(),
    ber_monat: new Date().getMonth() + 1,
    ber_jahr: new Date().getFullYear(), // Änderung: Verwende getFullYear statt getYear + 1900
    ber_zb_3: 0,
    ber_zahnfehl: 0,
    ber_ze_ja: 0,
    ber_luecke: 0,
    ber_luecke_vers: 0,
    ber_zahnersetzt: 0,
    ber_zahnersatz_10: 0,
    ber_kfoja: 0,
    ber_screen_size: window.innerWidth, // Beachte: Die Verwendung von `screen.width` ist statisch; `window.innerWidth` reflektiert die tatsächliche Fensterbreite
    ber_erst: 0,
    ber_brille_ja: 0,
    ber_brille_dioptrien: 0,
    ber_brille_krankheit: 0,
    ber_brille_sonnenbrille: 0,
    ber_brille_gleitsicht: 0,
    ber_hoergeraet_versicherung: 0,
    ber_hoergeraet_massnahme: 0,
    ber_brille_lasik: 0,
    ber_top_gesund: 0,
  });

  const defaultValues = reactive({
    ...berechnung,
  });

  const versicherungsArten = ref([
    { id: 1, name: "gesetzliche Krankenkasse" },
    { id: 2, name: "private Krankenversicherung" },
    { id: 3, name: "Heilfürsorge" },
    { id: 4, name: "gesetzliche Krankenkasse & Beihilfe" },
    { id: 5, name: "freie Heilfürsorge & Beihilfe" },
  ]);

  const berLueckeSelect = ref([
    { id: "0", name: "0" },
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
    { id: "7", name: "7" },
    { id: "8", name: "8" },
    { id: "9", name: "9" },
    { id: "10", name: "10" },
    { id: "11", name: "11" },
    { id: "12", name: "12" },
    { id: "13", name: "13" },
    { id: "14", name: "14" },
    { id: "15", name: "mehr als 14" },
  ]);
  const berechnung_submited = ref(false);
  const wechselTarifName = ref(false);
  const berechnungFormRef = ref(null);
  const sucheTarife = ref([]);
  const search = ref("");
  const isChecked = ref(false);
  const errorMessage = ref("");
  const berErstError = ref(false);

  const resetAll = () => {
    console.log("reset berechnung");
    Object.assign(berechnung, defaultValues);
  };
  // Validierungsfunktion
  const validateCheckbox = () => {
    if (!isChecked.value) {
      errorMessage.value =
        "Sie müssen den Bedingungen zustimmen, um fortzufahren.";
    } else {
      errorMessage.value = "";
    }
  };
  function showTarifSuche() {
    if (berechnung.ber_wechsel == 1) {
      getAlleZahnTarife();
    }
  }
  function checkCurrentDate() {
    const heute = new Date();
    const aktuellesJahr = heute.getFullYear();
    const aktuellerMonat = heute.getMonth() + 1; // Monate sind 0-basiert, daher +1
    const aktuellerTag = heute.getDate();

    if (
      berechnung.ber_jahr === aktuellesJahr &&
      berechnung.ber_monat === aktuellerMonat &&
      berechnung.ber_tag === aktuellerTag
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkBerechnung() {
    let checkPassed = true;
    showGeburtstagFehler.value = false;
    if (checkCurrentDate()) {
      showGeburtstagFehler.value = true;
    }
    if (berechnung.ber_erst == 0) {
      checkPassed = false;
      berErstError.value = true;
      console.log("ber_erst check");
    }
    if (berechnungFormRef.value && !berechnungFormRef.value.validate()) {
      checkPassed = false;
      console.log("checkPassfalse");
    }
    console.log("checkPassed" + checkPassed);
    return checkPassed;
  }

  async function getAlleZahnTarife() {
    let select = {};
    select.action = "getAlleZahnTarife";
    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        sucheTarife.value = resp.data.tarife;
      }
    } catch (err) {
      console.log("error tarife");
    }
  }
  async function submitBerechnung() {
    if (!checkBerechnung()) {
      return false;
    }

    // berechnung.token =
    //   "92418073005bd16ead01dcfcb713a5e580cf171c66982171ef08ff2169f94f93";
    berechnung.action = "insertBerechnung";
    berechnung.berGestartet = true;
    console.log("berechnung gestartet");
    try {
      const resp = await BaseCalls.postBerechnung(berechnung); // Korrigiert, um .value zu verwenden

      if (resp.data && resp.data.berechnung) {
        console.log("insert erfolgreich" + resp.data.berechnung);
        berechnungStore.setBerechnung(resp.data.berechnung);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  const updateValueBer = (key, newValue) => {
    console.log("New: " + newValue);
    berechnung[key] = newValue;
  };
  function resetSehhilfe() {
    if (berechnung.ber_brille_ja == 0) {
      berechnung.ber_brille_krankheit = "0";
    }
    if (berechnung.ber_hoergeraet_versicherung == 0) {
      berechnung.ber_hoergeraet_massnahme = "0";
    }
    // if (berechnung.ber_brille_ja == 0) {
    //   berechnung.ber_brille_ja = 0;
    //   berechnung.ber_brille_vers_ja = 0;
    // }
    // if (berechnung.ber_brille_ja == 1) {
    //   berechnung.ber_brille_vers_ja = 1;
    // }
  }

  const filteredTarife = computed(() => {
    if (sucheTarife.value.length > 1) {
      return sucheTarife.value.filter((tarif) =>
        tarif.tarif_name.toLowerCase().includes(search.value.toLowerCase())
      );
    }
    return []; // Sicherer Fallback, falls sucheTarife.value kein Array ist
  });
  function dayChanged(day) {
    // $log.debug(`day changed: ${day}`)
    berechnung.ber_tag = day;
    handleBirthdayChanged();
  }
  function monthChanged(month) {
    berechnung.ber_monat = month;
    handleBirthdayChanged();
  }
  function yearChanged(year) {
    berechnung.ber_jahr = year;
    handleBirthdayChanged();
  }

  function handleBirthdayChanged() {
    showGeburtstagFehler.value = false;
    calcAge();

    if (berechnung.ber_alter <= 6) {
      berechnung.ber_luecke = "0";
      berechnung.ber_luecke_vers = "0";
      berechnung.ber_kronen = "0";
      berechnung.ber_ze_ja = "0";
      berechnung.ber_bruecke = "0";
      berechnung.ber_zahnersetzt = "0";
      berechnung.ber_zb_3 = "0";
    }

    if (berechnung.ber_alter <= 9) {
      showKfoInfo.value = false;
      berechnung.ber_kfoja = "1";
    }

    if (berechnung.ber_alter >= 10 && berechnung.ber_alter <= 17) {
      showKfoInfo.value = true;
      berechnung.ber_kfoja = "1";
    }

    if (berechnung.ber_alter >= 18) {
      berechnung.ber_kfoja = "0";
      showKfoInfo.value = false;
    }
  }

  function calcAge() {
    // let age = DateHelper.calcAge(
    //   berechnung.ber_tag,
    //   berechnung.ber_monat,
    //   berechnung.ber_jahr
    // );
    let age = proxy.$calcAge(
      berechnung.ber_tag,
      berechnung.ber_monat,
      berechnung.ber_jahr
    );
    berechnung.ber_alter = age;
  }
  function setWechselTarif(tarif_id, tarif_name) {
    berechnung.ber_wechsel_tarif_id = tarif_id;
    wechselTarifName.value = tarif_name;
  }
  function erstInfoChecked() {
    berechnung.ber_erst = 1;
  }

  function berMassnahmeChanged() {
    if (berechnung.ber_zahnmass == "0") {
      berechnung.ber_sofort = "0";
    }
  }
  function berZeChanged() {
    console.log("berZeChanged");
    if (berechnung.ber_ze_ja == "0") {
      berechnung.ber_luecke = "0";
      berechnung.ber_luecke_vers = "0";
    }
  }

  function berLueckeChanged(value) {
    console.log("berLueckeChanged");
    if (value == 0) {
      berechnung.ber_luecke_vers = 0;
    } else if (value > 0 && value <= 1) {
      berechnung.ber_luecke_vers = 1;
    } else {
      berechnung.ber_luecke_vers = 0;
    }
  }

  const showBtnColor = computed(() => {
    if (berechnung.ber_segment == 1 && !showSegmentType.value) {
      return "#2196F3";
    }
    if (berechnung.ber_segment == 1 && showSegmentType.value == "erwachsene") {
      return "#2196F3";
    }
    if (berechnung.ber_segment == 1 && showSegmentType.value == "zahnkind") {
      return "#fecf0c";
    }
    if (berechnung.ber_segment == 1 && showSegmentType.value == "zahnplus") {
      return "#513b83";
    }
    if (berechnung.ber_segment == 2) {
      return "#FF8000";
    }
    if (berechnung.ber_segment == 3) {
      return "#088A85";
    }
    if (berechnung.ber_segment == 4) {
      return "#00BCD4";
    } else {
      return "#2196F3";
    }
  });

  const showSegmentType = computed(() => {
    if (route.params.bertype === "heilpraktiker") {
      return "heilpraktiker";
    }
    if (route.params.bertype === "brille") {
      return "brille";
    }
    if (route.params.bertype === "krankenhaus") {
      return "krankenhaus";
    }
    if (route.params.bertype === "kind") {
      return "zahnkind";
    }
    if (route.params.bertype === "60plus") {
      return "zahnplus";
    }

    if (!route.params.bertype) {
      if (berechnung.ber_segment === 2) {
        return "brille";
      } else if (berechnung.ber_segment === 3) {
        return "heilpraktiker";
      } else if (berechnung.ber_segment === 4) {
        return "krankenhaus";
      } else {
        return "starten";
      }
    } else {
      return "starten";
    }
    // } else {
    //   if (!isNaN(route.params.bertype)) {
    //     return "annahmecheck";
    //   } else {
    //     return route.params.bertype;
    //   }
  });

  watchEffect(() => {
    if (berechnungStore.berechnung) {
      componentKeyStatus.value = componentKeyStatus.value + 1;

      // Iteriere durch die Schlüssel des neuen Objekts und aktualisiere das reaktive Objekt

      Object.keys(berechnungStore.berechnung).forEach((key) => {
        if (key !== "ber_guest_id") {
          berechnung[key] = berechnungStore.berechnung[key];
        }
        if (
          guestStore.guest.guest_id !== 0 &&
          berechnungStore.berechnung["ber_guest_id"] !==
            guestStore.guest.guest_id
        ) {
          berechnung["ber_guest_id"] = guestStore.guest.guest_id;
        } else {
          berechnung["ber_guest_id"] =
            berechnungStore.berechnung["ber_guest_id"];
        }
        console.log(guestStore.guest.guest_id);
      });
      if (berechnung.ber_segment != routeSegment.value) {
        // console.log("berechnung.ber_segment != routeSegment.value");
        berechnung.ber_segment = routeSegment.value;
      }
    }
  });
  onMounted(() => {
    routeSegment.value = 1;
    if (route.params.bertype == "60plus") {
      routeSegment.value = 1;
    }
    if (route.params.bertype == "erwachsene") {
      routeSegment.value = 1;
    }
    if (route.params.bertype == "kind") {
      routeSegment.value = 1;
    }
    if (route.params.bertype == "brille") {
      routeSegment.value = 2;
    }
    if (route.params.bertype == "heilpraktiker") {
      routeSegment.value = 3;
    }
    if (route.params.bertype == "krankenhaus") {
      routeSegment.value = 4;
    }
    berechnung.ber_segment = routeSegment.value;
    // if (routeSegment.value == 1 && sucheTarife.value.length == 0) {
    //   getAlleZahnTarife();
    // }
  });
  watch(berechnung, (newValue) => {
    emit("updateBerechnung", newValue); // Emit das Update Event mit dem neuen Wert
  });
</script>
