<template>
  <v-layout class="greytext ggfont mt-4">
    <v-container class="mt-0" style="max-width: 1000px">
      <!-- <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs> -->
      <v-row class="mb-8">
        <v-col cols="12" md="8">
          <h1>
            Naturheilkunde-Tarife nach 4-Jahres-Realwert-Methode analysiert
          </h1>
          <h3>Dornstein Realwert-Methode - Kriterien unserer Tarifanalyse</h3>
          DornsteinTabelle hat es sich zur Aufgabe gemacht, eine
          versicherungsunabhängige Tarifanalyse von
          Naturheilkunde-Zusatzversicherungen durchzuführen. Diese zeigt die
          durchschnittliche Leistungsstärke verschiedenster
          Naturheilkunde-Tarife in einem Prozentwert auf und macht
          Naturheilkunde Versicherungen so miteinander vergleichbar.
          <br /><br />
          Die Basis der Tarifanalyse stellt einen “Warenkorb von Naturheilkunde
          Behandlungen” dar, welche ein Musterpatient im Laufe von 4 Jahren
          exemplarisch in Anspruch nimmt. Darüberhinaus enthält die
          4-Jahres-Realwert-Methode weitere Qualitätskriterien, um
          leistungsstarke Heilpraktikerversicherungen zu identifizieren.
          <br /><br />
          <h3>
            Fokus auf handverlesene, leistungsstarke
            Heilpraktiker-Zusatzversicherungen
          </h3>
          Der Warenkorb für die 4-Jahres-Realwert-Methode referenziert auf
          erstattungsfähige Naturheilverfahren mit Bezug auf das
          Hufeland-Leistungsverzeichnis. Zudem sind naturheilkundliche Verfahren
          des Gebührenverzeichnis für Heilpraktiker (GebüH) miteinbezogen.
          <br /><br />
          Exemplarisch für die Tarifanalyse wurden Behandlungen aus
          nachfolgenden Leistungsbereichen abgebildet und anhand von
          Beispiel-Behandlungen dargestellt: Die Behandlungsbereiche umfassen
          folgende Therapiegebiete: Homöopathie, Ayurveda, Bioresonanzdiagnostik
          und -therapie, Chiropraktik (Chirotherapie), Massagen, Akupunktur,
          Osteopathie, Colon-Hydro-Therapie, Traditionelle Chinesische Medizin
          (TCM).
          <br /><br />
          <h3>
            Welche Leistungen sollte eine gute Zusatzversicherung Naturheilkunde
            bieten?
          </h3>
          Naturheilkundliche Behandlungen sind höchst individuell auf den
          Patienten abgestimmt. Unserer Meinung nach, sollte gerade deswegen
          eine Zusatzversicherung Naturheilkunde sowohl beim Heilpraktiker als
          auch beim Arzt für Naturheilverfahren leisten. Wir empfehlen, dass
          Leistungen für Naturheilkundeverfahren in Höhe von ca. 800,- bis
          1.000,- € pro Jahr abgedeckt sind. Tarifleistungen sollten alle
          Positionen des Gebührenverzeichnisses für Heilpraktiker (GebüH)
          abdecken. Zudem sollte auch für sämtliche Naturheilverfahren des
          Hufelandverzeichnis bzw. einer ähnlich umfangreichen Aufstellung des
          Versicherers an Naturheilverfahren geleistet werden. Eine Erstattung
          bis zum Höchstbetrag nach Gebührenordnung für Heilpraktiker (GebüH)
          und Hufelandverzeichnis / GÖÄ soll ebenfalls gegeben sein.
          DornsteinTabelle listet demnach vornehmlich Naturheilkunde-Tarife,
          welche oben genannte Qualitätskriterien erfüllen.
          <br /><br />
          <v-table dense>
            <tr>
              <td><b>Jahr</b></td>
              <td><b>Leistung</b></td>
              <td><b>Kosten</b></td>
              <td><b>Terminart</b></td>
            </tr>
            <tr>
              <td><b>1. Jahr</b></td>
              <td>Erstgespräch</td>
              <td>65 €</td>
              <td>Ersttermin</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>140 €</td>
              <td>Anamnese / Erste Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ayurveda kennenlernen</td>
              <td>60 €</td>
              <td>Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Ayurveda Marma Massage</td>
              <td>100 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ayurveda Yoga Beratung</td>
              <td>75 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Bioresonanztherapie</td>
              <td>80 €</td>
              <td>Erste Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Bioresonanztherapie</td>
              <td>70 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Bioresonanztherapie</td>
              <td>70 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Bioresonanztherapie</td>
              <td>70 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Chiropraktik</td>
              <td>90 €</td>
              <td>Anamnese / Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Chiropraktik</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Chiropraktik</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Entgiftungstherapie</td>
              <td>68 €</td>
              <td>Infusions-Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><b>1168 €</b></td>
              <td></td>
            </tr>
            <tr>
              <td><b>2. Jahr</b></td>
              <td>Osteopathie</td>
              <td>125 €</td>
              <td>Erstanamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Osteopathie</td>
              <td>90 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Osteopathie</td>
              <td>90 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Entgiftungstherapie</td>
              <td>68 €</td>
              <td>Infusions-Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>75 €</td>
              <td>Erstanamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstagebuch</td>
              <td>65 €</td>
              <td>Festsatz</td>
            </tr>
            <tr>
              <td></td>
              <td>Massage Schmerztherapie</td>
              <td>65 €</td>
              <td>Erste Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Massage Schmerztherapie</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Massage Schmerztherapie</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Homöopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><b>1168 €</b></td>
              <td></td>
            </tr>
            <tr>
              <td><b>3. Jahr</b></td>
              <td>Erstgespräch</td>
              <td>65 €</td>
              <td>Ersttermin weiterer Heilpraktiker</td>
            </tr>
            <tr>
              <td></td>
              <td>Kraniosakrale Osteopathie</td>
              <td>140 €</td>
              <td>Erstanamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Kraniosakrale Osteopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Kraniosakrale Osteopathie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>TCM Erstanamnese</td>
              <td>135 €</td>
              <td>Anamnese / Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Akupunktursitzung</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Akupunktursitzung</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Entgiftungstherapie</td>
              <td>68 €</td>
              <td>Infusions-Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Chinesische Kräutertherapie</td>
              <td>80 €</td>
              <td>Anamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Chinesische Kräutertherapie</td>
              <td>70 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Chinesische Kräutertherapie</td>
              <td>70 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Chinesische Kräutertherapie</td>
              <td>70 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Chiropraktik</td>
              <td>90 €</td>
              <td>Anamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Chiropraktik</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Chiropraktik</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><b>1168 €</b></td>
              <td></td>
            </tr>
            <tr>
              <td><b>4. Jahr</b></td>
              <td>Colon-Hydro-Therapie</td>
              <td>125 €</td>
              <td>Erstanamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Colon-Hydro-Therapie</td>
              <td>90 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Colon-Hydro-Therapie</td>
              <td>90 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Entgiftungstherapie</td>
              <td>68 €</td>
              <td>Infusions-Behandlung</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>75 €</td>
              <td>Anamnese</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstherapie</td>
              <td>50 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Ernährungstagebuch</td>
              <td>65 €</td>
              <td>Festsatz</td>
            </tr>
            <tr>
              <td></td>
              <td>Massage Schmerztherapie</td>
              <td>65 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Massage Schmerztherapie</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Massage Schmerztherapie</td>
              <td>60 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Fußreflexzonentherapie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Fußreflexzonentherapie</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Breuss-Massage</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td>Breuss-Massage</td>
              <td>80 €</td>
              <td>Folgekonsultation</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><b>1168 €</b></td>
              <td></td>
            </tr>
          </v-table>
        </v-col>
        <v-col cols="12" md="2" xs="12">
          <div style="border-left: 4px solid; border-left-color: #088a85">
            <v-avatar class="profile" tile color="grey" size="150">
              <v-img :src="require('@/assets/site/kilianlink.jpg')"></v-img>
            </v-avatar>
          </div>

          <br /><b>Kilian Link</b>, Entwickler der <b>Realwert-Methode</b> für
          Heilpraktiker-Zusatzversicherungen <br /><br /><br />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const { proxy } = getCurrentInstance();
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 13);
    proxy.$updateDocumentHead(
      "Naturheilkunde-Tarife nach DornsteinTabelle Realwert-Methode analysiert",
      "Naturheilkunde-Tarife nach DornsteinTabelle Realwert-Methode analysiert"
    );
  });
</script>
