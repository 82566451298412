<template>
  <div>
    <v-row v-if="userStore.userData.user_id > 0">
      <v-col cols="12" md="9"
        ><h2>Ihr Dornstein-Account</h2>
        <p>
          Hier können Sie Ihre Account-Daten ändern. Bitte beachten Sie, dass
          Sie Ihre Account-Daten nicht ändern sollten , wenn Sie eine aktive
          Bestellung haben.
        </p>

        <br />
        <v-form ref="form" @submit.prevent="submitForm">
          <v-text-field
            v-model="userdata.user_name"
            label="Benutzername"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="userdata.user_ansprechpartner"
            label="Ansprechpartner"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="userdata.user_firma"
            label="Firma"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="userdata.user_strasse"
            label="Straße"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="userdata.user_hsnr"
            label="Hausnummer"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="userdata.user_plz"
            label="Postleitzahl"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="userdata.user_ort"
            label="Ort"
            :rules="[rules.required]"
            outlined
          ></v-text-field>

          <v-btn type="submit" class="button_caps" color="turk"
            >Account Daten speichern</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted, ref, watch } from "vue";
  import { useUserStore } from "@/stores/user";
  import { useErrorStore } from "@/stores/errorStore";
  import { useLoaderStore } from "@/stores/loaderStore";
  import BaseCalls from "@/services/BaseCalls";

  const loaderStore = useLoaderStore();
  const errorStore = useErrorStore();
  const userStore = useUserStore();

  const { proxy } = getCurrentInstance();

  const userdata = ref({
    action: "updateUser",
    user_name: "",
    user_ansprechpartner: "",
    user_firma: "",
    user_strasse: "",
    user_hsnr: "",
    user_plz: "",
    user_ort: "",
  });

  const rules = {
    required: (value) => !!value || "Dieses Feld ist erforderlich",
  };

  async function submitForm() {
    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(userdata.value); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.user) {
        userStore.setUser(resp.data.user);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      errorStore.setError(true, "Fehler beim Speichern.");
    }
  }
  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  watch(
    () => userStore.userData,
    (newUserData) => {
      userdata.value = {
        action: "updateUser",
        user_name: newUserData.user_name,
        user_ansprechpartner: newUserData.user_ansprechpartner,
        user_firma: newUserData.user_firma,
        user_strasse: newUserData.user_strasse,
        user_hsnr: newUserData.user_hsnr,
        user_plz: newUserData.user_plz,
        user_ort: newUserData.user_ort,
      };
    },
    {
      deep: true,
      immediate: true,
    }
  );
  onMounted(() => {
    console.log("MountUsr" + userStore.userData.user_name);

    proxy.$updateDocumentHead("Ihr Dornstein Account", "Ihr Dornstein Accoun");
  });
</script>
