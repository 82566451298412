<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-12" style="max-width: 1000px">
      <!-- <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs> -->
      <v-row class="mb-8">
        <v-col cols="12" md="12">
          <h1>
            Gibt es eine Heilpraktiker-Zusatzversicherung ohne
            Gesundheitsfragen?
          </h1>
          Gibt es Heilpraktiker-Zusatzversicherungen ohne Gesundheitsfragen?
          Leider nein. Bei Abschluss einer privaten
          Heilpraktiker-Zusatzversicherung sind immer Gesundheitsfragen zu
          beantworten. Dabei sind diese Gesundheitsfragen meist umfassend.
          <b>Tipp:</b> Wir bieten leistungsstarke Heilpraktikerversicherungen,
          die mit einfachen Gesundheitsfragen abgeschlossen werden können.
          <ul class="my-4 ml-4">
            <li>
              DornsteinTabelle listet 7 leistungsstarke
              Heilpraktiker-Zusatzversicherungen mit einfachen Gesundheitsfragen
            </li>
            <li>
              DornsteinTabelle bietet 4 leistungsstarke
              Heilpraktiker-Zusatzversicherungen mit nur einer Gesundheitsfrage
            </li>
            <li>
              Einfache Gesundheitsfragen bedeutet, dass Gesundheitsfragen nicht
              allzu weitreichend gestellt werden
            </li>
          </ul>

          <div class="divider"></div>
          <h2>
            Gibt es Heilpraktiker-Zusatzversicherungen mit einfachen
            Gesundheitsfragen?
          </h2>
          Ja. Unsere Experten haben 7 leistungsstarke Heilpraktiker-Tarife
          zusammengestellt, die mit einfacher Gesundheitsprüfung abgeschlossen
          werden können. Darunter auch 4 leistungsstarke
          Heilpraktiker-Zusatzversicherung, die nur eine Gesundheitsfrage
          stellen.
          <br /><br />
          <b style="color: #088a85">DornsteinTabelle Tarif-Empfehlungen:</b>
          Heilpraktikerversicherungen mit einfachen Gesundheitsfragen / teils
          ohne Wartezeit <br /><br />
          <h4>
            Tarif-Empfehlungen: Heilpraktiker-Zusatzversicherungen mit einer
            Gesundheitsfrage:
          </h4>
          Ja. Diese sind unsere Tarife-Empfehlungen mit Versicherungen die nur
          eine Gesundheitsfrage haben:
          <ul class="my-4 ml-4">
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/universa_uni_med_a_exklusiv"
                >Heilpraktiker-Zusatzversicherung Universa uni-med A Exklusiv</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus_pur"
                >Heilpraktiker-Zusatzversicherung Signal Iduna
                AmbulantPLUSpur</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus"
                >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUS</a
              >
            </li>
            <li>
              <a
                href="/heilpraktiker_zusatzversicherung/nuernberger_budgetselect_privat_600"
                >Heilpraktiker-Zusatzversicherung Nürnberger BudgetSelect privat
                600</a
              >
            </li>
          </ul>

          <h4>
            Gibt es eine Heilpraktikerversicherung mit vereinfachten
            Gesundheitsfragen?
          </h4>
          Ja. Diese sind unsere Tarife-Empfehlungen mit vereinfachten
          Gesundheitsfragen:

          <ul class="my-4 ml-4">
            <li>
              <a href="heilpraktiker_zusatzversicherung/axa_med_komfort_u"
                >Heilpraktiker-Zusatzversicherung AXA MED Komfort-U</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat"
                >Heilpraktiker-Zusatzversicherung Union Krankenversicherung
                NaturPRIVAT</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat_vorsorgeprivat"
                >Heilpraktiker-Zusatzversicherung Union Krankenversicherung
                NaturPRIVAT + VorsorgePRIVAT</a
              >
            </li>
          </ul>

          <v-card elevation="4" class="pb-2 my-12">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="9">
                  <h4>
                    Immer wieder hören wir in Kundengesprächen die Frage: “<em
                      >Gibt es Heilpraktiker-Zusatzversicherungen ohne
                      Gesundheitsfragen?</em
                    >"
                  </h4>
                  Leider nein. Leistungsstarke Heilpraktiker-Tarife stellen
                  immer Gesundheitsfragen, da nicht alle Risiken, z.B.
                  Vorerkrankungen wie Asthma, versichert werden können.

                  <v-divider class="my-4"></v-divider>
                  <h4>
                    Leistungsstarke Heilpraktikerversicherungen stellen immer
                    Gesundheitsfragen. Warum ist das so?
                  </h4>
                  Bei Abschluss einer privaten Heilpraktikerversicherung sind
                  immer Gesundheitsfragen zu beantworten, die den gesamten
                  Gesundheitszustand betreffen. Je nach Risikopolitik der
                  Versicherung werden unterschiedliche Gesundheitsfragen im
                  Antrag gestellt, welche Risiken angenommen werden können und
                  welche nicht. Demnach können Personen mit Vorerkrankungen wie
                  z.B. Asthma, aktuell leider nicht in einer
                  Heilpraktiker-Zusatzversicherung angenommen werden.
                  Versicherungen überprüfen im Rahmen der Gesundheitsprüfung, ob
                  bestimmte chronische Erkrankungen oder Vorerkrankungen später
                  zu überdurchschnittlichen Kosten für die Gemeinschaften der
                  Versicherten führen könnten, haben also das Recht, bestimmte
                  Risiken nicht anzunehmen.
                  <v-divider class="my-4"></v-divider>
                  <h4>
                    Naturheilkunde, immer beliebter bei chronisch Erkrankten
                  </h4>
                  Oft setzen Personen mit chronischen Erkrankungen auf beliebte
                  alternative Heilmethoden / Naturheilkunde, um durch
                  Alternativmedizin Linderung zu erfahren. Deshalb müssen
                  Versicherungen Gesundheitsfragen stellen, um nur gewisse
                  Risiken anzunehmen. Schließlich gilt der Grundsatz, dass für
                  bereits diagnostizierte Erkrankungen nicht mehr geleistet
                  wird. Bei Heilpraktikerversicherungen kann eine Vorerkrankung
                  also auch zur Ablehnung des Antrags führen, da auf Grundlage
                  der Gesundheitsfragen nur gewisse Risiken versichert werden
                  können. DornsteinTabelle hat alle Heilpraktiker-Tarife samt
                  Gesundheitsfragen, die im Antrag gestellt werden, in jeder
                  Tarifbeschreibung auf www.dornsteintabelle.de aufgeführt. So
                  können Gesundheitsfragen in Ruhe gelesen werden, ob eine
                  Antragstellung sinnvoll ist oder ob eine
                  Heilpraktiker-Zusatzversicherung zu wählen ist, welche mit
                  einfachen Gesundheitsfragen abgeschlossen werden kann.
                </v-col>
                <v-col cols="12" md="3">
                  <div
                    style="border-left: 4px solid; border-left-color: #088a85"
                  >
                    <v-avatar class="profile" tile color="grey" size="150">
                      <v-img
                        :src="require('@/assets/site/kilianlink.jpg')"
                      ></v-img>
                    </v-avatar>
                  </div>

                  <br /><b>Kilian Link</b>, Entwickler der
                  <b>Realwert-Methode</b> für Heilpraktiker-Zusatzversicherungen
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <h2>
            Tipps und Hintergrundinfos zu Gesundheitsfragen bei Heilpraktiker
            Zusatzversicherungen
          </h2>
          <br />

          <h4>
            Muss man bei einer privaten Heilpraktiker-Zusatzversicherung
            Gesundheitsfragen beantworten?
          </h4>

          Ja. Wer eine leistungsstarke Heilpraktikerversicherung abschließen
          möchte, muss Gesundheitsfragen beantworten. Diese sog.
          Gesundheitsprüfung wird bei Antragstellung durchgeführt und fragt den
          Gesundheitszustand meist über mehrere Jahre rückwirkend ab.
          <br /><br />
          Die Versicherung stellt eine Reihe von Fragen direkt im
          Versicherungsantrag, welche wahrheitsgemäß beantwortet werden müssen.
          Meist sind diese Gesundheitsfragen ganz konkret formuliert und fragen
          den Gesundheitszustand, also z.B. Erkrankungen, Vorerkrankungen,
          chronische Erkrankungen, Einschränkungen sowie den BMI oder
          Medikamente ab. Wichtig: Alle gestellten Fragen müssen von der zu
          versichernden Person wahrheitsgemäß beantwortet werden.
          <br /><br />
          <h4>
            Tipp für Eltern: So früh wie möglich versichern:
            Heilpraktikerversicherungen für Kinder und Jugendliche
          </h4>
          Kinder und Jugendliche sind meistens gesund und haben weder schwere
          noch chronische Erkrankungen. Wenn sich Eltern für Naturheilkunde
          Behandlungen / alternative Heilmethoden beim Heilpraktiker für ihre
          Kinder entscheiden, ist dies der ideale Zeitpunkt für eine
          <a href="/heilpraktikerversicherung_kind_vergleich"
            >Kinder Heilpraktikerversicherung</a
          >. So sollten Eltern vor dem ersten Besuch beim Heilpraktiker eine
          Heilpraktikerversicherung für das Kind abschließen. <br /><br />
          <h4>
            Tipps zur Gesundheitsprüfung: Gesundheitsfragen mit dem Arzt nur
            telefonisch klären.
          </h4>

          Nicht jeder Patient weiß, was in seiner Patientenakte bei seinem
          Hausarzt vermerkt ist. Das geht sicher den meisten Menschen so.
          Demnach raten wir, exakt die gestellten Fragen im Antrag der
          Heilpraktiker-Zusatzversicherung mit dem Hausarzt / mit weiteren
          Behandlern (Ärzten / Heilpraktikern) telefonisch abzuklären.
          Allerdings nur telefonisch, da man eben vor Annahme durch die
          Versicherung keine z.B. Untersuchungen, Check-ups oder
          Routineuntersuchungen durchführen lassen sollte. Falls möglich
          natürlich.
          <br /><br />
          Nichts wäre ärgerlicher, als noch in letzter Minute vor Abschluss
          einer Heilpraktiker Zusatzversicherung eine "kritische" Diagnose
          gestellt zu bekommen. Würde der Antragsteller noch kurz vor Abschluss
          einer Heilpraktikerversicherung eine "kritische" Diagnose gestellt
          bekommen, könnte die Annahme bei einer Heilpraktiker
          Zusatzversicherung möglicherweise nicht mehr erfolgen bzw. ein
          Leistungsausschluss bzw. ein Risikozuschlag die Folge sein.
          <br /><br />
          <b
            >Über welchen Zeitraum können Gesundheitsfragen bei einer
            Heilpraktiker-Zusatzversicherung gestellt werden?</b
          >
          <ul class="my-4 ml-2">
            <li>Drei Jahre rückwirkend zu Krankheiten</li>
            <li>
              Fünf Jahre rückwirkend zu stationären Krankenhausaufenthalten
            </li>
            <li>
              Zehn Jahre rückwirkend zu psychotherapeutischen Behandlungen
            </li>
          </ul>
          Darüber hinaus können Fragen zu ambulanten / stationären Operationen,
          Behinderungen sowie Einschränkungen, dem BMI oder verschriebenen
          Medikamenten im Antrag zu beantworten sein.
          <br /><br />
          <h4>
            Werden meine Angaben der Gesundheitsfragen von der Versicherung
            geprüft?
          </h4>

          Wenn die Heilpraktiker-Zusatzversicherung abgeschlossen wurde, prüft
          die Versicherung erst später die Richtigkeit der Angaben im Antrag. Im
          Rahmen eines Leistungsfalles, wenn der Kunde z.B. eine Rechnung vom
          Heilpraktiker einreicht, kontaktiert die Versicherung meist den
          Hausarzt / den aktuellen Behandler. Falls sich bei dieser Rückfrage
          herausstellt, dass die Angaben im Antrag nicht der Wahrheit
          entsprechen, kann die Versicherung den Versicherungsnehmer im
          schlimmsten Fall kündigen.
          <br /><br />
          <h4>Tipp: Heilpraktikerversicherung trotz Vorerkrankung</h4>
          Sie haben eine Vorerkrankung und interessieren sich für eine
          Heilpraktiker-Zusatzversicherung? Wir bieten Tarife mit einfachen
          Gesundheitsfragen und drei Heilpraktiker-Tarife mit sog. abschließend
          gestellten Gesundheitsfragen. Weitere Informationen der
          DornsteinTabelle Experten finden Sie unter:
          <a href="/heilpraktiker_zusatzversicherung_bei_vorerkrankung"
            >Heilpraktiker-Zusatzversicherung bei Vorerkrankung</a
          >
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const { proxy } = getCurrentInstance();

  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 8);
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherung ohne Gesundheitsfragen?",
      "Leider gibt es keine Heilpraktikerversicherungen ohne Gesundheitsfragen. Wir bieten jedoch die 7 besten Heilpraktiker-Tarife mit einfachen Gesundheitsfragen."
    );
  });
</script>
