<template>
  <v-layout class="greytext ggfont">
    <v-card width="888" style="height: 888px">
      <v-card-text>
        <v-row v-if="!berechnung && tarif.tarif_abschluss_link_dst == ''">
          <v-col cols="12" md="12">
            <h2 class="my-2">Starten Sie zuerst eine Berechnung</h2>
            Um die {{ tarif.un_name }} {{ tarif.tarif_title }} zu beantragen,
            starten Sie bitte zuerst eine Berechnung.
            <br />
            <br />
            <v-btn
              href="/vergleich/starten/heilpraktiker"
              depressed
              tile
              color="#088A85"
              class="nocaps"
              style="color: white"
            >
              Jetzt eigene Berechnung starten <sup>kostenlos</sup>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="berechnung">
          <v-col cols="12" md="12">
            <h2 class="my-2">
              <span v-if="numberAbschluss > 1">1.</span> Antrag & Infopaket
              anfordern <sup>kostenlos</sup>
            </h2>
            Fordern Sie jetzt Ihr Antrag & Infopaket über den spezialisierten
            Spezialmaker <b>WaizmannPro</b> an. Sie erhalten innerhalb 1-2 Tagen
            ein vorbereiteten Antrag mit zusätzlichen Tarifinformationen per
            Post und E-Mail. <br />Sie können bei Fragen zu Erstattung die Hilfe
            das WaizmannPro Service-Team <b>kostenlos</b> in Anspruch nehmen.
            <br />
            <br />
            <v-btn
              :href="`/angebot/kostenlos/${tarif.tarif_id}/${berechnung.ber_identity_token}`"
              depressed
              tile
              target="_blank"
              color="#088A85"
              class="nocaps"
              style="color: white"
            >
              WaizmannPro Antrag & Infopaket anfordern
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="tarif.tarif_abschluss_link_dst != ''">
          <v-col cols="12" md="12">
            <h2 class="my-2">
              <span v-if="numberAbschluss > 1">2.</span> Jetzt
              {{ tarif.tarif_title }} sofort online beantragen

              <sup>kostenlos</sup>
            </h2>
            <a :href="tarif.tarif_abschluss_link_dst">Hier </a> können Sie die
            {{ tarif.un_name }} {{ tarif.tarif_title }} sofort über die
            Versicherungswebsite beantragen.
            <br />
            <br />
            <v-btn
              :href="tarif.tarif_abschluss_link_dst"
              depressed
              tile
              target="_blank"
              color="#088A85"
              class="nocaps"
              style="color: white"
            >
              Sofort-Abschluss über Versicherungswebseite
            </v-btn>
            <br /><br />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script setup>
  import { ref, defineProps, onMounted } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: Object,
  });
  const berechnung = ref(false);

  const numberAbschluss = ref(1);
  console.log(props.tarif);
  onMounted(() => {
    berechnung.value = berechnungStore.berechnung;
  });
</script>
