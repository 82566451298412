import http from "./http.js";

let apiUrl = "https://www.dornsteintabelle.de";

if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "loc.dst24.de"
) {
  apiUrl = "https://loc.dst24.de";
}

if (window.location.hostname == "www.dornsteintabelle.de") {
  apiUrl = "https://www.dornsteintabelle.de";
}

export default {
  postChat(payload) {
    return http.post(apiUrl + `/postchat`, { payload });
  },
  postGuest(payload) {
    return http.post(apiUrl + `/postguest`, { payload });
  },
  postUser(payload) {
    return http.post(apiUrl + `/postuser`, { payload });
  },
  postEmail(payload) {
    return http.post(apiUrl + `/postemail`, { payload });
  },
  postBerechnung(payload) {
    return http.post(apiUrl + `/postberechnung`, { payload });
  },
  postVergleich(payload) {
    return http.post(apiUrl + `/postvergleich`, { payload });
  },
  postTarif(payload) {
    return http.post(apiUrl + `/posttarif`, { payload });
  },
  postMixed(payload) {
    return http.post(apiUrl + `/postmixed`, { payload });
  },
};
