<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <h1>Heilpraktiker-Zusatzversicherung kündigen</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <h2>
          Was gibt es bei der Kündigung einer Heilpraktikerversicherung zu
          beachten?
        </h2>
        <p>
          Eine Heilpraktiker-Zusatzversicherung sollte man nicht einfach
          kündigen, ohne kurz zu überlegen. Wir erklären warum.
        </p>
        <ul>
          <li>Gründe, die Heilpraktiker-Zusatzversicherung zu kündigen</li>
          <li>
            Kündigung der Heilpraktikerversicherung - Was ist zu beachten?
          </li>
          <li>
            Kündigung der Heilpraktiker-Zusatzversicherung nach Beitragserhöhung
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Naturheilkunde wird immer beliebter</h2>
        <p>
          Mehr und mehr Menschen nutzen die Heilkräfte der Natur. Das Interesse
          an alternativen Heilmethoden / Naturheilkunde steigt immer mehr. Da
          Behandlungskosten bei Heilpraktiker durch die gesetzlichen
          Krankenkassen nicht erstattet werden, ist es naheliegend, eine private
          Krankenzusatzversicherung abzuschließen, um diese Kosten erstattet zu
          bekommen. Eine ambulante Zusatzversicherung hilft gesetzlich
          Versicherten, Lücken der Kassenversorgung zu schließen.
          <br />
          Jeder, der sich mit einer Heilpraktiker-Zusatzversicherung
          beschäftigt, stellt schnell fest, dass hier teils umfangreiche
          Gesundheitsfragen zu beantworten sind. Ambulante Zusatzversicherungen
          mit Fokus Naturheilkunde sind zwar mittlerweile ziemlich
          leistungsstark, jedoch muss man zuvor Gesundheitsfragen beantworten,
          um von der Versicherungsgesellschaft in einem Heilpraktiker-Tarif
          angenommen zu werden.
          <br />
          Es ist im Vergleich zu anderen Krankenzusatzversicherungen nicht so
          einfach, bei einer Heilpraktiker-Zusatzversicherung angenommen zu
          werden. Die Gesundheitsfragen sind meist drei bis fünf Jahre, in
          manchen Bereichen wie der “Psyche” bis zu zehn Jahren rückwirkend zu
          beantworten.
        </p>
        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <h2>Gründe, die Heilpraktiker-Zusatzversicherung zu kündigen</h2>
        Natürlich kann es gute Gründe geben, seine
        Heilpraktiker-Zusatzversicherung zu kündigen. Diese können von negativen
        Erfahrungen mit der Versicherungsgesellschaft im Rahmen einer Erstattung
        her rühren oder mit dem fehlenden Service der Versicherung. Ein Grund
        kann aber auch der Wechsel in die Private Krankenversicherung (PKV)
        sein, wobei ein Sonderkündigungsrecht besteht, da durch den Wechsel von
        GKV zu PKV die Versicherungsfähigkeit nicht mehr gegeben ist.
        <br /><br />
        Es kann auch sein, dass die Kündigung zugunsten des Wechsel zu einer
        anderen Heilpraktiker-Zusatzversicherung vollzogen wird, wodurch der
        aktuelle Tarif gekündigt wird. Doch Vorsicht, bei jedem Abschluss, einer
        Hochstufung des Tarifes und beim Wechsel der
        Heilpraktiker-Zusatzversicherung sind die Gesundheitsfragen des neuen
        Tarifes zu beantworten.
        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <h2>
          Warum man seine Heilpraktiker-Zusatzversicherung nicht einfach
          kündigen sollte
        </h2>

        Bevor Sie Ihre bestehende Heilpraktiker-Zusatzversicherung kündigen
        wollen, halten Sie bitte kurz inne. Sollten Sie später einmal wieder
        eine Heilpraktiker-Zusatzversicherung abschließen wollen, müssen Sie
        erneut Gesundheitsfragen beantworten. Und das kann unter Umständen zum
        Problem werden. Die Gesundheitsfragen einer
        Heilpraktiker-Zusatzversicherung beziehen sich auf den gesamten Körper
        und die Psyche. Die Gesundheitsfragen sind meist umfangreich und daraus
        ergibt sich, dass nicht jeder versichert werden kann, der eine
        Heilpraktiker-Zusatzversicherung abschließen möchte. Wer schwer(er)
        und/oder chronisch erkrankt ist, kann nicht in einer ambulanten
        Zusatzversicherung, sprich einer Heilpraktiker-Zusatzversicherung,
        versichert werden.
        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <div
          style="border-left: 4px solid; border-left-color: #088a85"
          class="pl-2"
        >
          <h2>Experten-Tipp:</h2>

          Der Wechsel in eine neue Heilpraktiker-Zusatzversicherung ist immer
          mit der Beantwortung von Gesundheitsfragen verbunden. Stellen Sie
          fest, was in Ihrer Patientenakte beim Arzt eingetragen ist, bevor Sie
          Ihre bestehende Heilpraktikerversicherung kündigen. Wenn möglich,
          sollten Sie zuerst die neue Heilpraktiker-Zusatzversicherung
          abgeschlossen haben, bevor Sie die “alte”
          Heilpraktiker-Zusatzversicherung kündigen.
        </div>
        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <h2>Kündigungsfristen einer Heilpraktiker-Zusatzversicherung</h2>

        Jede Heilpraktiker-Zusatzversicherung ist an eine Laufzeit und eine
        Kündigungsfrist gebunden. Die Mindestvertragslaufzeit beträgt zwei
        Jahre. Es gibt jedoch auch Heilpraktiker-Tarife, bei denen die
        Mindestvertragslaufzeit nur ein Jahr beträgt.
        <br /><br />
        Bei der Laufzeit der Zusatzversicherung muss zwischen dem Kalenderjahr
        und dem Versicherungsjahr unterschieden werden. Manche Tarife laufen bis
        zum Kalenderjahr, sodass in manchen Fällen (Abschluss zum 1. März) das
        Jahr gar nicht mehr 12 Monate hat, das erste Jahr nach 10 Monaten endet.
        Ein Vorteil für den Kunden, wenn man die Versicherung unterjährig
        abschließt. Beim Versicherungsjahr verhält es sich so, dass das Jahr
        eben 12 fortlaufende Monate umfasst.<br /><br />
        Hinsichtlich der Kündigungsfrist lohnt ein genauer Blick in die
        Versicherungsunterlagen. Bei den meisten Zusatzversicherungen beträgt
        die Kündigungsfrist 3 Monate zum Ende eines Versicherungs- oder
        Kalenderjahres. Die Kündigung ist in Schriftform der
        Versicherungsgesellschaft zu übermitteln. Zudem ist es wichtig, sich die
        fristgerechte Kündigung schriftlich bestätigen zu lassen.

        <v-divider :thickness="4" class="my-4" color="turk"></v-divider>
        <h2>
          Kündigung der Heilpraktiker-Zusatzversicherung nach Beitragserhöhung
        </h2>
        Werden Versicherungsbeiträge aufgrund des Erreichens einer bestimmten
        Altersstufe erhöht, ist das vorab in der Prognose der
        Versicherungsbeiträge so kalkuliert und den meisten Versicherten
        bewusst.
        <br /><br />
        Anders verhält es sich bei einer außerordentlichen Beitragsanpassung.
        <br /><br />
        Versicherungsvertragsgesetz - VVG § 205 Kündigung des
        Versicherungsnehmers (4) Erhöht der Versicherer auf Grund einer
        Anpassungsklausel die Prämie oder vermindert er die Leistung, kann der
        Versicherungsnehmer hinsichtlich der betroffenen versicherten Person
        innerhalb von zwei Monaten nach Zugang der Änderungsmitteilung mit
        Wirkung für den Zeitpunkt kündigen, zu dem die Prämienerhöhung oder die
        Leistungsminderung wirksam werden soll.
        <br /><br />
        Eine außerordentliche Beitragserhöhung ist dann der Fall, wenn
        Versicherungsbeiträge z.B. aufgrund der Schadenquote angehoben werden
        müssen. Zwar besteht nach einer Beitragserhöhung ein
        Sonderkündigungsrecht, jedoch sollte man zuvor noch einmal überlegen, ob
        die Beitragserhöhung nicht doch zu verschmerzen ist bzw. ob man die
        Heilpraktiker-Zusatzversicherung wirklich nicht mehr braucht. Denken wir
        hierzu nochmal an die obens erwähnte Thematik, Tarifwechsel bzw.
        Neuabschluss - welcher immer mit der erneuten Beantwortung von
        Gesundheitsfragen (Gesundheitsprüfung) einhergeht.
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  const { proxy } = getCurrentInstance();
  import BaseCalls from "@/services/BaseCalls";
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 39);
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherung kündigen",
      "Was ist bei der Kündigung einer Heilpraktikerversicherung zu beachten? Unser Experten-Team gibt Tipps und Infos zur Kündigung der Heilpraktiker-Zusatzversicherung. "
    );
  });
</script>
