<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-12" style="max-width: 1000px">
      <!-- <v-breadcrumbs
          v-if="breads"
          class="ml-0 pl-0"
          :items="breads"
          divider=" / "
        ></v-breadcrumbs> -->
      <v-row class="mb-8">
        <v-col cols="12" md="12">
          <h1>Heilpraktiker-Zusatzversicherung ohne Wartezeit</h1>
          <em> 7 starke Heilpraktikerversicherungen ohne Wartezeit </em>

          Heilpraktikerversicherungen gibt es mit und ohne Wartezeit. Bei
          Heilpraktiker Zusatzversicherungen ohne Wartezeit verzichtet die
          Versicherung auf die Wartezeit. Die allgemeine Wartezeit für eine
          Heilpraktikerversicherung beträgt 3 Monate. Die besondere Wartezeit
          beträgt 8 Monate und gilt bei Entbindung und Psychotherapie.
          <ul class="my-4 ml-4">
            <li>
              Wir bieten 7 leistungsstarke Heilpraktikerversicherungen ohne
              Wartezeit
            </li>
            <li>
              Es gibt einige starte Heilpraktiker-Zusatzversicherungen welche
              auf die Wartezeit verzichten
            </li>
            <li>
              All unsere Heilpraktiker-Zusatzversicherungen leisten bis zum
              Höchstbetrag Hufelandverzeichnis / GebüH / GOÄ
            </li>
          </ul>
          <br /><br />
          <h2>
            DornsteinTabelle Tarif-Empfehlungen: Diese 7
            Heilpraktikerversicherungen leisten ohne Wartezeit
          </h2>

          <ul class="my-4 ml-4">
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_2000"
                >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
                2.000</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_1000"
                >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
                1.000</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat"
                >Heilpraktiker-Zusatzversicherung Union Krankenversicherung
                NaturPRIVAT</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/versicherungskammer_bayern_naturprivat_vorsorgeprivat"
                >Heilpraktiker-Zusatzversicherung Union Krankenversicherung
                NaturPRIVAT + VorsorgePRIVAT</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus"
                >Heilpraktiker-Zusatzversicherung Signal Iduna AmbulantPLUS</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/signal_iduna_ambulantplus_pur"
                >Heilpraktiker-Zusatzversicherung Signal Iduna
                AmbulantPLUSpur</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/nuernberger_budgetselect_privat_600"
                >Heilpraktiker-Zusatzversicherung Nürnberger BudgetSelect privat
                600</a
              >
            </li>
          </ul>

          <h3>
            Gibt es eine Heilpraktiker-Zusatzversicherung ohne Wartezeit? Ja.
          </h3>
          Eine gibt Heilpraktiker Zusatzversicherungen mit und ohne Wartezeit.
          <br /><br />
          <h3>Was bedeutet Wartezeit?</h3>
          Die Wartezeit stellt einen Zeitraum dar, bei dem die versicherte
          Person bereits Versicherungsschutz genießt, aber noch keine Ansprüche
          geltend machen kann. Während der Wartezeit erfolgt keine
          Leistungsübernahme durch die Versicherung.
          <br />
          Wichtig: Bei Rechnungsstellung ist das tatsächliche Behandlungsdatum
          entscheidend und nicht, wann die Rechnung vom Behandler ausgestellt
          wurde.
          <br /><br />
          <h3>Wann beginnt die Wartezeit?</h3>
          Die Wartezeit beginnt mit dem Versicherungsbeginn, der im
          Versicherungsschein (auch Police genannt) dokumentiert ist. Meist wird
          der Versicherungsschein mit Beginn - Erster eines Monats -
          ausgestellt.
          <br /><br />
          <h3>Welche Wartezeiten gibt es?</h3>
          Die allgemeine Wartezeit einer Heilpraktiker-Zusatzversicherung
          beträgt 3 Monate. Die besondere Wartezeit beträgt 8 Monate und gilt
          für Entbindung und Psychotherapie.
          <br /><br />

          <h3>Solange die Wartezeit läuft</h3>
          Solange die Wartezeit läuft, besteht Versicherungsschutz, jedoch kein
          Leistungsanspruch. Es können nach Versicherungsbeginn zwar Diagnosen
          gestellt, Maßnahmen angeraten werden, jedoch können diese Behandlungen
          erst nach Ablauf der Wartezeit begonnen und erstattet werden.
          <br />
          Wichtig: Entscheidend für die Leistung / Erstattung ist das
          Behandlungsdatum, nicht das Rechnungsdatum.
          <br /><br />
          <h3>
            Kann ich angeratene / geplante Behandlungen noch versichern? Nein.
          </h3>
          Heilpraktiker-Zusatzversicherungen ohne Wartezeit leisten nicht für
          bereits vor Versicherungsbeginn angeratene / geplante oder laufende
          Behandlungen.
          <br /><br />
          <h3>Ohne Wartezeit?</h3>
          Ohne Wartezeit bedeutet nicht, dass Versicherungsschutz für angeratene
          / laufende Behandlungen besteht. Alle bereits vor Versicherungsbeginn
          angeratenen / geplanten oder laufenden Behandlungen sind vom
          Versicherungsschutz ausgeschlossen.

          <!-- alt -->
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const { proxy } = getCurrentInstance();
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 2);
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherungen ohne Wartezeit",
      "Heilpraktikerversicherungen können auch ohne Wartezeit abgeschlossen werden. Wir bieten 7 starke Heilpraktiker-Zusatzversicherungen ohne Wartezeit."
    );
  });
</script>
