<template>
  <v-layout class="greytext">
    <v-container class="mt-0">
      <!-- <v-breadcrumbs
          v-if="breads"
          class="ml-0 pl-0"
          :items="breads"
          divider=" / "
        ></v-breadcrumbs> -->
      <v-row class="ggfont greytext">
        <v-col cols="12" md="10" xs="12">
          <v-row>
            <v-col cols="12" md="10" xs="12">
              <!-- neu -->
              <h1 style="padding-bottom: 0px; font-size: 38px">
                Heilpraktiker-Zusatzversicherungen Test 2024
              </h1>
              <h2 style="color: #088a85">
                <span
                  >Heilpraktikerversicherungen Testsieger 2024 auf einen Blick
                </span>
              </h2>
              DornsteinTabelle analysiert den Markt an Heilpraktiker
              Zusatzversicherungen regelmäßig. Wir testen
              Heilpraktikerversicherungen mit der 4-Jahres-Realwert-Methode und
              listen die leistungsstärksten Tarife auf DornsteinTabelle. Zudem
              werden Heilpraktikerversicherungen für Kinder eigens getestet und
              die besten Kindertarife auf der DornsteinTabelle Kinder
              veröffentlicht. Bei uns finden Sie die besten ambulanten
              Zusatzversicherungen für Naturheilkunde / alternative Heilmethoden
              auf einen Blick.

              <ul class="ma-2">
                <li>
                  Beste Heilpraktiker-Zusatzversicherungen von Marktführern &
                  Testsieger-Tarife 2024
                </li>
                <li>
                  Die besten Kinder Heilpraktikerversicherungen getestet von
                  Experten der DornsteinTabelle
                </li>
                <li>
                  Tarife mit Erstattung bis zum Höchstbetrag nach
                  Gebührenordnung für Heilpraktiker (GebüH) und
                  Hufelandverzeichnis / GÖÄ
                </li>
                <li>
                  Leistungsstarke Heilpraktiker-Zusatztarife mit 100% Erstattung
                  für sämtliche Naturheilverfahren bis 2.000 € pro Jahr
                </li>
              </ul>
            </v-col>
            <v-col cols="12" md="2" class="pr-8"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" xs="12">
              <v-card elevation="4" class="pb-2 mb-8">
                <v-table dense>
                  <br />
                  <thead>
                    <tr class="redground">
                      <th colspan="5">
                        <h2 class="text-white">
                          Testsieger Heilpraktiker-Zusatzversicherungen 2024 -
                          DornsteinTabelle
                        </h2>
                      </th>
                    </tr>
                    <tr style="background-color: #f2f2f2">
                      <th style="max-width: 10px">#</th>
                      <th>Tarif</th>

                      <th>Ø-Erstattung</th>
                      <th>Begrenzung</th>
                      <th>Preis 19 Jahre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style="vertical-align: top"
                      v-for="tarif in tarife"
                      :key="tarif.id"
                    >
                      <td>{{ tarif.rank }}.</td>
                      <td>
                        <a
                          :href="`/heilpraktiker_zusatzversicherung/${tarif.tarif_key}`"
                        >
                          <strong>{{ tarif.versicherung }}</strong>
                        </a>

                        <br />
                        {{ tarif.tarif }}
                      </td>
                      <td>
                        <strong>{{ tarif.realwert }}</strong>
                      </td>
                      <td>{{ tarif.begrenzung }}</td>
                      <td>
                        <a
                          href="/rechner"
                          title="Vergleich Heilpraktikerversicherung starten"
                          ><span v-html="tarif.preis"></span
                        ></a>

                        <span class="green--text" v-if="tarif.hp_annahme == 1"
                          ><br /><b>Einfache</b> Annahme-bedingungen</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td colspan="2">
                        <v-btn
                          depressed
                          tile
                          color="#088A85"
                          class="nocaps"
                          style="color: white"
                          href="/rechner"
                        >
                          Jetzt Online-Vergleich starten - Ergebnis in nur 1
                          Minute</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card>

              <v-card elevation="4" class="pb-2">
                <v-table dense>
                  <br />
                  <thead>
                    <tr class="redground">
                      <th colspan="5">
                        <h2 class="text-white">
                          Testsieger Kinder Heilpraktikerversicherungen 2024 -
                          DornsteinTabelle
                        </h2>
                      </th>
                    </tr>
                    <tr style="background-color: #f2f2f2">
                      <th style="max-width: 10px">#</th>
                      <th>Tarif</th>

                      <th>Ø-Erstattung</th>
                      <th>Begrenzung</th>
                      <th>Preis 12 Jahre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style="vertical-align: top"
                      v-for="tarif in tarifekinder"
                      :key="tarif.id"
                    >
                      <td>{{ tarif.rank }}.</td>
                      <td>
                        <a
                          :href="`/heilpraktiker_zusatzversicherung/${tarif.tarif_key}`"
                        >
                          <strong>{{ tarif.versicherung }}</strong>
                        </a>

                        <br />
                        {{ tarif.tarif }}
                      </td>
                      <td>
                        <strong>{{ tarif.realwert }}</strong>
                      </td>
                      <td>{{ tarif.begrenzung }}</td>
                      <td>
                        <a
                          href="/rechner"
                          title="Vergleich Heilpraktikerversicherung starten"
                          ><span v-html="tarif.preis"></span
                        ></a>

                        <span class="green--text" v-if="tarif.hp_annahme == 1"
                          ><br /><b>Einfache</b> Annahme-bedingungen</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td colspan="2">
                        <v-btn
                          depressed
                          tile
                          color="#088A85"
                          class="nocaps"
                          style="color: white"
                          href="/rechner"
                        >
                          Jetzt die besten Kinder Heilpraktikerversicherungen
                          vergleichen</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card>
            </v-col>
          </v-row>
          <!-- Ende Tabelle -->
          <v-row class="mt-12">
            <v-col cols="12" md="12">
              <!-- neu -->
              <h2>
                Heilpraktiker Zusatzversicherungen im Test von Stiftung
                Warentest / Finanztest
              </h2>
              Stiftung Warentest / Finanztest hat
              Heilpraktiker-Zusatzversicherungen zuletzt in der Ausgabe 5/2017
              getestet. Insgesamt wurden dabei 59 Heilpraktikerversicherungen
              analysiert. Über die Hälfte der getesteten Tarife wurden mit “gut”
              bewertet. Seit dem letzten Test ist schon einige Zeit vergangen,
              neue starke Zusatzversicherungen für Heilpraktiker &
              Naturheilkunde kamen auf den Markt, welche nicht im Stiftung
              Warentest der Heilpraktiker-Zusatzversicherungen berücksichtigt
              werden konnten.

              <div class="divider"></div>

              <iframe
                v-if="isDektopBig"
                src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FNaturheilpaxis.Klaus.John%2Fposts%2Fpfbid02VQYrcGVskj4Sz53iKW6zHcBFk1Qi7LyJYVwphYd5nqnMZohS1yeCZ7GJQL5xcYfrl&show_text=true&width=500"
                height="327"
                width="490"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
              <div class="divider"></div>
              <!-- neu -->
              <h2>
                Wie hat Stiftung Warentest / Finanztest
                Heilpraktikerversicherungen getestet?
              </h2>
              <br />
              <b>Unser Fazit zum Test:</b> In der Ausgabe 5/2017 hat Finanztest
              59 private Ergänzungstarife getestet, die mindestens
              Heilpraktikerleistungen / Naturheilverfahren durch Ärzte
              bezuschussen. Dazu wurden Leistungen für Heilpraktiker, Brillen
              und Zahnersatz bewertet. Der Test konzentrierte sich vornehmlich
              auf anerkannte Naturheilverfahren. Es wurden Homöopathie,
              Akupunktur als Schmerztherapie sowie Heilpraktiker-Behandlungen
              bei einer Allergie als Testszenario angesetzt. Somit ist dieses
              Testszenario sehr stark begrenzt worden. Als Gewichtung gingen
              diese 3 genannten Leistungen mit je 30 % in das Gesamtergebnis des
              Heilpraktikerversicherung Tests ein und wurden noch mit 10 % an
              weiteren Naturheilverfahren ergänzt. Das Ergebnis wurde seitens
              Stiftung Warentest / Finanztest als erfreulich bezeichnet, da mehr
              als die Hälfte der Angebote gute Heilpraktikerleistungen bieten.
              Zudem wurde auf das gute Preis-Leistungsverhältnis hingewiesen:
              Der 43-Jährige Modellkunde erhielt gute Heilpraktikerleistungen
              schon für unter 20,- € im Monat. <br /><br />
              Größtes Manko des Stiftung Warentest / Finanztest
              Heilpraktiker-Zusatzversicherungen Die getesteten Heilpraktiker
              Zusatzversicherungen wurden im gewählten Testszenario viel zu
              eingeschränkt bewertet. Das weite Feld an Naturheilkunde
              Behandlungen und alternativen Heilmethoden wurde bei weitem nicht
              ausgeschöpft. Es wurde ein Minimal-Testszenario in lediglich drei
              Anwendungsbereichen durchgeführt. Zudem wurde weder auf das Budget
              der Versicherung für Naturheilkunde Leistungen noch auf die
              Leistungsstärke anhand der übernommenen Behandlungen durch die
              Gebührenordnung für Heilpraktiker (GebüH) und das
              Hufelandverzeichnis eingegangen. Was nützen also selektiv
              ausgewählte Behandlungen für einen Modellkunden, wenn die
              Leistungsstärke des Tarifes als Ganzes außen vor bleibt. Auch
              wurden Kinder Heilpraktikerversicherungen nicht eigens getestet.
              <br /><br />
              <b
                >Testsieger Tarife der Heilpraktikerversicherungen im Stiftung
                Warentest / Finanztest 5/2017 Testnoten von Stiftung Warentest /
                Finanztest:</b
              >
              Tabelle 1: Kalkulation der Beiträge nach dem Alter bei Eintritt
              <ul>
                <li>Debeka EAplus, Testnote 1,6</li>
                <li>ARAG 483, Testnote 1,9</li>
                <li>SDK NH, Testnote 2,0</li>
                <li>AXA Med Komfort-U, Testnote 2,1</li>
              </ul>
              <br />
              <b
                >Testsieger Tarife der Heilpraktikerversicherungen im Stiftung
                Warentest / Finanztest 5/2017 Testnoten von Stiftung Warentest /
                Finanztest:</b
              >
              Tabelle 2: Kalkulation der Beiträge, welche abhängig vom Alter
              steigen
              <ul>
                <li>uniVersa Uni-medA-Exklusiv, Testnote 1,8</li>
                <li>Inter APP, Testnote 1,9</li>
                <li>AXA − Med Komfort Start-U, Testnote 2,1</li>
                <li>UKV - NaturPRIVAT, Testnote 2,2</li>
              </ul>

              <div class="divider"></div>
              <!-- neu -->
              <h2>
                Heilpraktiker Zusatzversicherungen im Test von DornsteinTabelle
              </h2>

              Stiftung Warentest / Finanztest hat Heilpraktiker
              Zusatzversicherungen letztmals 5/2017 getestet. DornsteinTabelle
              testet Heilpraktikerversicherungen regelmäßig.
              <br /><br />
              <b
                >Wie testet DorrnsteinTabelle
                Heilpraktiker-Zusatzversicherungen?</b
              >
              <br />
              DornsteinTabelle testet Heilpraktiker-Zusatzversicherungen mit der
              Dornstein Realwert-Methode. Diese versicherungsunabhängige
              Tarifanalyse testet ambulante Zusatzversicherungen mit Fokus
              Naturheilkunde-Leistungen. Das Ergebnis zeigt die
              durchschnittliche Leistungsstärke verschiedener
              Naturheilkunde-Zusatztarife in einem Prozentwert und macht
              Naturheilkunde Zusatzversicherungen miteinander vergleichbar.
              <br /><br />
              Die Basis der Tarifanalyse stellt ein “Warenkorb von
              Naturheilkunde Behandlungen" dar, welche ein Musterpatient im
              Laufe von 4 Jahren exemplarisch in Anspruch nimmt. Darüber hinaus
              enthält die 4-Jahres-Realwert-Methode weitere Qualitätskriterien,
              um leistungsstarke Heilpraktikerversicherungen zu identifizieren.
              Nähere Infos zu unserem Testverfahren von
              Heilpraktiker-Zusatzversicherungen unter
              <a href="/realwert_heilpraktikerversicherung"
                >4-Jahres-Realwert-Methode</a
              >. <br /><br />
              <b
                >Testsieger Heilpraktikerversicherungen 2024 von
                DornsteinnTabelle</b
              >
              <br /><br />
              Bewertung für Heilpraktiker-Zusatzversicherungen, Fokus
              Naturheilkunde Behandlungen, nach durchschnittlicher
              Leistungsstärke
              <ul>
                <li>
                  Barmenia Mehr Gesundheit 2.000 - 100 % Dornstein Realwert
                </li>
                <li>ARAG 483 - 96,32 % Dornstein Realwert</li>
                <li>AXA MED Komfort U - 85,62 % Dornstein Realwert</li>
                <li>
                  Barmenia Mehr Gesundheit 1.000 - 85,62 % Dornstein Realwert
                </li>
                <li>Gothaer Medi Ambulant - 85,62 % Dornstein Realwert</li>
                <li>
                  Universa uni-med A Exklusiv - 77,05 % Dornstein Realwert
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";

  const tarife = ref([]);
  const tarifekinder = ref([]);
  const { proxy } = getCurrentInstance();

  async function getTarife(what) {
    let select = {};
    select.action = "getStaticHeilTarife";
    if (what == "erwachsen") {
      select.select = "heilAlleStart";
      select.addPreis = 19;
    }
    if (what == "kinder") {
      select.select = "heilAlleStartKind";
      select.addPreis = 12;
    }

    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        if (what == "erwachsen") {
          tarife.value = resp.data.tarife;
        }
        if (what == "kinder") {
          tarifekinder.value = resp.data.tarife;
        }
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 4);
    getTarife("erwachsen");
    getTarife("kinder");
    proxy.$updateDocumentHead(
      "Heilpraktiker-Zusatzversicherungen im Test 2024",
      "Alle Heilpraktikerversicherungen Testsieger 2024 auf einen Blick. Testergebnisse von Stiftung Warentest / Finanztest und DornsteinTabelle zum Nachlesen."
    );
  });
</script>
