<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-12" style="max-width: 1000px">
      <!-- <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs> -->
      <v-row class="mb-8">
        <v-col cols="12" md="12">
          <h1>Heilpraktiker-Zusatzversicherung Psychotherapie</h1>
          <em> Die besten Heilpraktikerversicherungen für Psychotherapie </em>
          Heilpraktikerversicherungen können auch für Psychotherapie leisten.
          Jedoch wird diese Leistung nur von wenigen Heilpraktiker-Tarifen
          angeboten. Wir zeigen, welche leistungsstarken
          Heilpraktiker-Zusatzversicherungen dies sind und was es dabei zu
          beachten gibt.
          <ul class="my-4 ml-4">
            <li>
              Heilpraktiker-Zusatzversicherungen leisten auch für eine
              Psychotherapie
            </li>
            <li>
              Aktuell bieten jedoch nur wenige Heilpraktikerversicherungen gute
              Leistungen für Psychotherapie
            </li>
            <li>
              Eine Psychotherapie Behandlung kann bei einem speziell
              ausgebildeten Heilpraktiker Psychotherapie gemacht werden
            </li>
          </ul>

          <br /><br />
          <h2>
            DornsteinTabelle Tarif-Empfehlungen: Diese
            Heilpraktikerversicherungen leisten für Psychotherapie
          </h2>

          <ul class="my-4 ml-4">
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_2000"
                >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
                2.000</a
              >
            </li>
            <li>
              <a
                href="heilpraktiker_zusatzversicherung/barmenia_mehr_gesundheit_1000"
                >Heilpraktiker-Zusatzversicherung Barmenia Mehr Gesundheit
                1.000</a
              >
            </li>
            <li>
              <a href="heilpraktiker_zusatzversicherung/gothaer_medi_ambulant"
                >Heilpraktiker-Zusatzversicherung Gothaer Medi Ambulant</a
              >
            </li>
          </ul>
          In den letzten Jahren ist die Nachfrage nach psychotherapeutischen
          Behandlungen stark gestiegen. Für gesetzlich Versicherte besteht zwar
          ein Anspruch auf einen Therapieplatz, nur ist dieser meist mit einer
          mehrmonatigen Wartezeit verbunden. Zu groß sind die Anfragen an
          kassenärztlich zugelassene Psychotherapeuten. Eine
          Heilpraktiker-Zusatzversicherung für Psychotherapie bietet eine echte
          Lösung, um die Psychotherapie so früh wie möglich zu beginnen.
          <br /><br />
          <b>Wichtig:</b> Wenn eine Psychotherapie ärztlich angeraten oder
          bereits begonnen wurde, gilt das als eingetretener Versicherungsfall.
          Es kann also keine Heilpraktiker-Zusatzversicherung für eine
          psychotherapeutische Behandlung mehr abgeschlossen werden.
          <br /><br />
          Was bleibt also? Man kann eine leistungsstarke
          Heilpraktiker-Zusatzversicherung wählen, die auch für Psychotherapie
          leistet, sollte diese in der Zukunft gebraucht werden.
          <br /><br />
          <h2>
            Gesundheitsfragen bei Heilpraktikerversicherungen für Psychotherapie
          </h2>
          Bei Heilpraktiker-Zusatzversicherungen müssen Gesundheitsfragen
          beantwortet werden. Wer in den letzten 3 - 10 Jahren eine
          Psychotherapie gemacht hat bzw. wenn bereits eine psychotherapeutische
          Behandlung angeraten wurde, kann keine
          Heilpraktiker-Zusatzversicherung mehr abschließen.

          <div class="divider"></div>
          <h2>
            Bieten Heilpraktiker eine gute Anlaufstelle für eine Psychotherapie?
          </h2>
          Ja. Zwar hat jeder gesetzlich Versicherte das Anrecht auf eine
          psychotherapeutische Behandlung, doch oft sind Therapeuten der
          gesetzlichen Krankenkasse über mehrere Monate hinweg ausgebucht.
          <br /><br />
          Eine Heilpraktiker-Zusatzversicherung inkl. Psychotherapie-Leistung
          bietet eine gute Möglichkeit für Behandlungen gegen z.B. Stress,
          Belastungsstörungen oder Burnout. Speziell ausgebildete Heilpraktiker
          für Psychotherapie bieten eine gute Anlaufstelle.

          <br /><br />
          <h2>
            Wie lange werden Gesundheitsfragen zur Psyche / Psychotherapie bei
            Heilpraktiker-Zusatzversicherungen gestellt?
          </h2>
          In Heilpraktikerversicherungen sind Fragen zur Psyche bis zu 10 Jahren
          rückwirkend zu beantworten. Dies hängt letztlich von den gestellten
          Gesundheitsfragen im Antrag der jeweiligen
          Heilpraktiker-Zusatzversicherung ab. Manche Versicherungen fragen
          Zeiträume von 3 - 5 Jahren rückwirkend ab, andere fragen Zeiträume von
          bis zu 10 Jahren rückwirkend ab.
          <br /><br />
          <h2>
            Wie lange ist die Wartezeit Psychotherapie bei der
            Heilpraktiker-Zusatzversicherung?
          </h2>
          Für die Leistung Psychotherapie gilt eine besondere Wartezeit von 8
          Monaten.
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  const { proxy } = getCurrentInstance();
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 15);
    proxy.$updateDocumentHead(
      "Welche Heilpraktiker-Zusatzversicherung leistet für Psychotherapie?",
      "Heilpraktikerversicherungen können für Psychotherapie leisten. Wir zeigen 3 Heilpraktiker Zusatzversicherungen die dafür leisten."
    );
  });
</script>
