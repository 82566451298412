<template>
  <div>
    <span v-if="!sharedDataStore.isMobile()">
      <h2 v-if="tarif.empfehlung_type == 3">
        Beste Leistung <sup class="text-green">Tipp</sup>
      </h2>
      <h2 v-if="tarif.empfehlung_type == 2 || tarif.empfehlung_type == 1">
        Sehr gute Alternative <sup class="text-green">Tipp</sup>
      </h2>

      <v-divider
        v-if="tarif.empfehlung_type > 0"
        :thickness="4"
        :color="sharedDataStore.showBtnColoBer(berechnung.ber_segment)"
      ></v-divider>
    </span>

    <ul>
      <span v-if="sharedDataStore.isMobile()">
        <li v-if="tarif.empfehlung_type == 3" class="text-green">
          <strong style="font-size: 16px">Empfehlung beste Leistung</strong>
        </li>
        <li
          v-if="tarif.empfehlung_type == 2 || tarif.empfehlung_type == 1"
          class="text-green"
        >
          <strong style="font-size: 16px">Sehr gute Alternative</strong>
        </li>
      </span>
      <li
        v-for="(item, index) in truncateLog(tarif.log, tarif.empfehlung_type)"
        v-bind:key="index"
      >
        <span v-html="nl2br(item.value)"></span>

        <span v-if="item.info_pop == 'altersKhPop'"
          ><a @click="showAltersPop = !showAltersPop"
            >.&nbsp;Wieso ist das wichtig?</a
          ></span
        >
        <span v-if="tarif.sofortversichert && item.info_pop == 'sofort'"
          ><a @click="showSofortPop = !showSofortPop">mehr Infos dazu</a>
        </span>
      </li>
    </ul>

    <!-- <v-dialog max-width="888" v-model="showSofortPop">
      <sofort-pop
        @closeWindow="showSofortPop = false"
        :tarif="tarif"
        :berechnung="berechnung"
      />
    </v-dialog> -->

    <v-dialog max-width="700" v-model="showAltersPop">
      <v-card class="pa-4">
        <v-card-text>
          <h2>Wir empfehlen Tarife mit Altersrückstellungen.</h2>
          Wir empfehlen Krankenhaus-Zusatzversicherungen mit
          Altersrückstellungen. Stationäre Zusatz-Tarife mit
          Altersrückstellungen sind im Alter beitragsstabil. Das bedeutet, dass
          es zu keinen altersbedingten Beitragsanpassungen oder Beitragssprüngen
          kommt. Krankenhausversicherungen ohne Altersrückstellungen sind zwar
          gerade in jüngeren Jahren günstiger, sie steigen aber in bestimmten
          Abständen an. Wenn Versicherer sowohl Krankenhaus-Zusatztarife mit als
          auch ohne Altersrückstellungen anbieten, werden die Tarife ohne
          Altersrückstellungen bei Vollendung des 60. Lebensjahres in die Tarife
          mit Altersrückstellungen übertragen, was zu diesem Zeitpunkt einen
          extremen Beitragssprung bedeutet. Bei den anderen Anbietern steigen
          die Beiträge bis ins hohe Alter immer weiter an und sind irgendwann -
          meist zwischen 60-70 Jahren - höher, als bei den
          Krankenhaus-Zusatzversicherungen mit Altersrückstellungen.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
  import { ref, defineProps } from "vue";

  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();

  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    showMobile: {
      type: Boolean,
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  const showAltersPop = ref(false);

  const truncateLog = (log, empfehlungType) => {
    const filteredLog = log.filter((entry) => entry.emp_only === 0);
    if (empfehlungType === 0 && log.length > 3) {
      // Von dem gefilterten Array die ersten 3 Elemente zurückgeben
      return filteredLog.slice(0, 3);
    }
    if (empfehlungType > 0) {
      return log.filter((entry) => entry.noShowEmpf === 0);
    }

    return log;
  };

  console.log(props.tarif);
</script>

<style scoped>
  .noUnderline {
    text-decoration: none !important;
  }
</style>
