<template>
  <div>
    <div>
      <v-row class="mb-2">
        <v-col>
          <strong>Durchschnitts-Erstattung</strong>
        </v-col>
        <v-col cols="12" md="8">
          <tool-tip-helper toolkey="hp_gesamterstattung_2_4">
            <template v-slot:toolTipText>
              <span
                class="blue--text"
                style="
                  font-size: 18px;
                  color: white;
                  font-weight: bold;
                  cursor: pointer;
                "
              >
                {{ tarif.hp_gesamterstattung_2_4 }} % | RealWert Benchmark
              </span>
            </template>
          </tool-tip-helper>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>

      <v-row class="mb-2">
        <v-col>
          <strong class="blue--text">Erstattung beim Heilpraktiker</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_naturheilkunde_heilpraktiker }} %</strong>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>

      <v-row class="mb-2">
        <v-col>
          <div><b>Leistungen für Naturheilkunde</b></div>
        </v-col>
        <v-col cols="12" md="8">
          <ul>
            <li v-for="item in tarif.hp_leistung_array" :key="item">
              {{ item }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>

      <v-row class="mb-2">
        <v-col>
          <strong class="blue--text"
            >Erstattung beim Naturheilkunde-Arzt</strong
          >
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_naturheilkunde_arzt }} %</strong>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>

      <v-row class="mb-2">
        <v-col>
          <strong class="blue--text">Begrenzungen</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_begrenzungen_hp }}</strong>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>

      <v-row class="mb-2">
        <v-col>
          <strong class="blue--text">Wartezeit</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_wartezeit }}</strong>
        </v-col>
      </v-row>
      <v-divider :thickness="1" class="my-2"></v-divider>
      <v-row class="mt-6" v-if="tarif.hp_wert_2">
        <v-col cols="12" md="4">
          <strong>Im 1.-2. Jahr</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_wert_2 }} %</strong>
          <br />
          <ul>
            <li>
              <a href="/heilpraktikerversicherung_vergleich"
                >alle Heilpraktiker-Zusatzversicherungen im Vergleich</a
              >
            </li>
            <li>
              <a href="/kinder_heilpraktikerversicherung_vergleich"
                >Kinder-Heilpraktikerversicherungen im Vergleich</a
              >
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Heilpraktikerleistungen im 3.-4. Jahr -->
      <v-row class="mt-6" v-if="tarif.hp_wert_4">
        <v-col cols="12" md="4">
          <strong>Im 3.-4. Jahr</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_wert_4 }} %</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Erstattung für Naturheilkunde Leistungen -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Erstattung für Naturheilkunde Leistungen</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_naturheilkunde_arzt }} %</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Erstattung pro Jahr -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Erstattung für Naturheilkunde Leistungen pro Jahr</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_max_erstattung_1jahr }} €</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Begrenzungen -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Begrenzungen für Naturheilkunde Leistungen</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_begrenzungen_hp }}</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Wartezeit -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Wartezeit</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_wartezeit }}</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Erstattung beim Heilpraktiker -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Erstattung beim Heilpraktiker</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_naturheilkunde_heilpraktiker }} %</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Erstattung beim Naturheilkunde-Arzt -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Erstattung beim Naturheilkunde-Arzt</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_naturheilkunde_arzt }} %</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-row class="mt-6" v-if="tarif.hp_leistung_hufeland">
        <v-col cols="12" md="4">
          <strong>Erstattung Hufelandverzeichnis</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_leistung_hufeland }}</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Erstattung GOÄ / GebüH -->
      <v-row class="mt-6" v-if="tarif.hp_hoechstsatz">
        <v-col cols="12" md="4">
          <strong>Erstattung GOÄ / GebüH</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_hoechstsatz }}</strong>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Leistungen für Naturheilkunde -->
      <v-row>
        <v-col cols="12" md="4">
          <strong>Leistungen für Naturheilkunde</strong>
        </v-col>
        <v-col cols="12" md="8">
          <ul>
            <li v-for="item in tarif.hp_leistung_array" :key="item">
              {{ item }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Mindestvertragslaufzeit -->
      <v-row class="mt-6" v-if="tarif.hp_hoechstsatz">
        <v-col cols="12" md="4">
          <strong>Mindestvertragslaufzeit</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_mindestlaufzeit }}</strong>
          <br />
          <a :href="`/sofort_kuendigen/${tarif.un_url}`">
            {{ tarif.un_name }} Heilpraktikerversicherungen kündigen - Hilfe bei
            der Kündigung
          </a>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Gesundheitsprüfung -->
      <v-row v-if="tarif.hp_gesundheitspruefung">
        <v-col cols="12" md="4">
          <strong>Gesundheitsprüfung</strong>
        </v-col>
        <v-col cols="12" md="8">
          <strong>{{ tarif.hp_gesundheitspruefung }}</strong>
          <br />
          <span class="green--text">Wichtig:</span> Heilpraktikerversicherungen
          können nicht ohne eine (umfangreiche) Gesundheitsprüfung abgeschlossen
          werden. Ersparen Sie sich unnötige Anfragen. Nur die Dornsteintabelle
          bietet einen Annahmecheck online | anonym | kostenlos für die
          Heilpraktiker-Zusatzversicherung.
          <a href="/vergleich/starten/heilpraktiker">
            Jetzt Onlinevergleich Heilpraktiker Zusatzversicherung
            {{ tarif.un_name }} {{ tarif.tarif_title }} starten...
          </a>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="tarif.hp_hoechstsatz" class="mt-6">
        <v-col cols="12" md="4">
          <strong>Beitragsrückstellung</strong>
        </v-col>
        <v-col cols="12" md="8">
          <span v-if="tarif.tarif_altersrueckstellung == 1">
            Der Heilpraktiker-Tarif {{ tarif.un_name }}
            {{ tarif.tarif_title }} wurde
            <b>ohne Altersrückstellungen</b> kalkuliert.
          </span>
          <span v-if="tarif.tarif_altersrueckstellung == 0">
            Der Heilpraktiker-Tarif {{ tarif.un_name }}
            {{ tarif.tarif_title }} wurde
            <b>mit Altersrückstellungen</b> kalkuliert.
          </span>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Erweiterte Leistungen Titel -->
      <v-row>
        <v-col cols="12">
          <strong class="mt-4 mb-2 blue--text" style="font-size: 18px"
            >Erweiterte Leistungen</strong
          >
        </v-col>
      </v-row>

      <!-- Leistungen für Sehhilfen -->
      <v-row v-if="tarif.hp_leistung_sehhilfe">
        <v-col cols="12" md="4">
          <strong>Leistungen für Sehhilfen</strong>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif.hp_leistung_sehhilfe }}<br />{{
            tarif.hp_beschreibung_sehhilfe
          }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Leistungen für Lasik -->
      <v-row v-if="tarif.hp_leistung_lasik">
        <v-col cols="12" md="4">
          <strong>Leistungen für Lasik</strong>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif.hp_leistung_lasik }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Vorsorgeleistungen -->
      <v-row v-if="tarif.hp_leistung_vorsorge">
        <v-col cols="12" md="4">
          <strong>Vorsorgeleistungen</strong>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif.hp_leistung_vorsorge }}<br />{{
            tarif.hp_beschreibung_vorsorge
          }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Schutzimpfungen -->
      <v-row v-if="tarif.hp_leistung_impfung">
        <v-col cols="12" md="4">
          <strong>Schutzimpfungen</strong>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif.hp_leistung_impfung }}<br />{{
            tarif.hp_beschreibung_impfung
          }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="tarif.hp_leistung_arzneimittel" class="mt-6">
        <v-col cols="12" md="4">
          <strong>Leistung für Arzneimittel</strong>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif.hp_leistung_arzneimittel }}
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Leistung für Heilmittel -->
      <v-row v-if="tarif.hp_heilmittel == 1">
        <v-col cols="12" md="4">
          <strong>Leistung für Heilmittel</strong>
        </v-col>
        <v-col cols="12" md="8"> Ja. </v-col>
      </v-row>
      <v-divider></v-divider>

      <!-- Sonstige Leistungen -->
      <v-row v-if="tarif.hp_leistung_weitere">
        <v-col cols="12" md="4">
          <strong>Sonstige Leistungen</strong>
        </v-col>
        <v-col cols="12" md="8">
          {{ tarif.hp_leistung_weitere }}<br />{{
            tarif.hp_beschreibung_weitere
          }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
