<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <strong>Ø-Erstattung</strong>
      </v-col>
      <v-col cols="12" md="9">
        <b v-if="berechnung.ber_segment <= 1 && berechnung.ber_algo_view != 2"
          >{{ tarif.tarif_waizmannwert }} %</b
        >
        <b v-if="berechnung.ber_segment <= 1 && berechnung.ber_algo_view == 2"
          >{{ tarif.tarif_kwert_gesamt }} %</b
        >
        <b v-if="berechnung.ber_segment == 2">{{ tarif.bri_ww }} %</b>
        <b v-if="berechnung.ber_segment == 3"
          >{{ tarif.hp_gesamterstattung_2_4 }} %</b
        >
        <b v-if="berechnung.ber_segment == 4">{{ tarif.kh_ww }} %</b>
        <span v-if="!sharedDataStore.isMobile()">
          |
          <a @click="berechnungStore.setShowPop(tarif, 'waizmannwert')"
            >Realwert Berechnungsweg</a
          ></span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { defineProps } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();

  const props = defineProps({
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props.tarif);
</script>
