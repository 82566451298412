<template>
  <v-container class="mt-0">
    <div class="ggfont greytext" v-if="!user.user_name" style="height: 1000px">
      <v-alert type="info">
        <h2 class="text-white">Sie sind nicht eingeloggt</h2>
        <span class="text-white"
          >Bitte loggen Sie sich ein, um Ihre Bestellungen zu sehen und neue
          DornsteinTabellen zu bestellen.</span
        >
      </v-alert>
    </div>
    <div class="ggfont greytext" v-if="user.user_name">
      <v-row>
        <v-col cols="12" md="9">
          <h2>Mein Account: {{ user.user_name }}</h2>
          <v-row class="mt-2" v-if="user.user_ansprechpartner != ''">
            <v-col cols="12" md="2">Ansprechpartner</v-col>
            <v-col cols="12" md="6">{{ user.user_ansprechpartner }}</v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" md="2">Strasse</v-col>
            <v-col cols="12" md="6"
              >{{ user.user_strasse }} {{ user.user_hsnr }}</v-col
            >
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" md="2">Ort</v-col>
            <v-col cols="12" md="6"
              >{{ user.user_plz }} {{ user.user_ort }}</v-col
            >
          </v-row>
          <v-alert type="success" v-if="showSuccess">
            <h2 class="text-white">Wir haben Ihre Anforderung erhalten</h2>
            <span class="text-white">
              Wir schicken Ihnen Ihre DornsteinTabellen innerhalb von 2-3
              Werktagen kostenlos zu.</span
            >
          </v-alert>
          <v-row class="mt-12" v-if="!showSuccess">
            <v-col cols="12" md="9">
              <h2>
                Kostenlos neue DornsteinTabellen anfordern |
                <span class="redtext">Druckversion</span>
              </h2>
              <p>
                Bestellen Sie jetzt kostenlos neue DornsteinTabellen der besten
                Heilpraktiker-Zusatzversicherungen für Ihre Praxisräume. Wir
                schicken Ihnen diese innerhalb von 2-3 Werktagen kostenlos zu.
              </p>
              <v-btn
                type="submit"
                @click="insertBestellung()"
                tile
                outlined
                color="#088A85"
                class="nocaps my-4"
                >Jetzt kostenlos neue DornsteinTabellen für Ihre Praxis
                bestellen</v-btn
              >
            </v-col>
          </v-row>

          <v-row class="mt-4" v-if="bestellungen.length > 0">
            <v-col cols="12" md="9">
              <h2>Meine Bestellungen</h2>
              <ul>
                <li v-for="item in bestellungen" :key="item.bestell_id">
                  {{ item.bestell_created_date }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <menue-download />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script setup>
  import { ref, getCurrentInstance, computed, onMounted } from "vue";
  import { useUserStore } from "@/stores/user";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import BaseCalls from "@/services/BaseCalls";
  // import { useRouter } from "vue-router";
  // const router = useRouter();
  const errorStore = useErrorStore();
  const loaderStore = useLoaderStore();
  const userStore = useUserStore();
  const user = computed(() => userStore.userData);
  // user.value.user_id = 58;
  const showSuccess = ref(false);

  const { proxy } = getCurrentInstance();
  const bestellungen = ref([]);

  async function insertBestellung() {
    if (!user.value.user_id) {
      return;
    }
    let select = {};
    select.action = "insertBestellung";
    select.bestell_produkt_text = "DST Togo ";
    select.user_id = user.value.user_id;
    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data) {
        setLoading(false);
        getBestellungen();
        showSuccess.value = true;
      }
    } catch (err) {
      setLoading(false);
      errorStore.setError(true, "Fehler beim einloggen.");
    }
  }

  async function getBestellungen() {
    if (!user.value.user_id) {
      // router.push("/heilpraktiker");
      return;
    }
    let select = {};
    select.action = "getBestellungen";
    select.user_id = user.value.user_id;
    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.bestellungen) {
        bestellungen.value = resp.data.bestellungen;
        userStore.setUser(resp.data.user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      errorStore.setError(true, "Fehler beim einloggen.");
    }
  }

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  onMounted(() => {
    getBestellungen();
    proxy.$updateDocumentHead(
      "Kostenlos neue Dornsteintabellen anfordern - für Heilpraktiker",
      "Kostenlos neue Dornsteintabellen anfordern - für Heilpraktiker"
    );
  });
</script>
