import { defineStore } from "pinia";
import { ref } from "vue"; // Import von ref aus Vue

export const useMarketingDataStore = defineStore("marketingData", () => {
  const marketingData = ref({
    companyName: "DornsteinTabelle",
    buttonStep1Claim: "Von Heilpratikern empfohlen, von Patienten geliebt.",
  });

  return {
    marketingData,
  };
});
