<template>
  <div style="height: 1000px">
    <h2>Sie sind jetzt erfolgreich ausgeloggt</h2>
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted } from "vue";

  const { proxy } = getCurrentInstance();
  onMounted(() => {
    proxy.$updateDocumentHead(
      "DornsteinTabelle Logout",
      "DornsteinTabelle Logou"
    );
  });
</script>
