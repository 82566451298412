<template>
  <div>
    <v-card style="min-height: 800px">
      <v-card-title v-show="!sharedDataStore.isMobile()" class="headline">
        Vergleichen Sie 3 Tarife miteinander
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="sharedDataStore.isMobile()"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Drehen Sie bitte Ihr Handy um diesen Vergleich vollständig nutzen zu
          können.
        </v-alert>

        <v-table class="my-table">
          <thead>
            <tr>
              <th style="font-size: 14px">Tarif</th>
              <th style="width: 300px; font-size: 14px" class="blue--text">
                {{ tarif1.un_name }} {{ tarif1.tarif_title }}
              </th>
              <th style="width: 300px">
                <select
                  class="blue--text"
                  style="padding-left: 4px; font-size: 14px"
                  @change="pushTarifToVergleich($event, 2)"
                >
                  <option value selected>Tarif 2 wählen</option>
                  <option
                    style="padding-left: 10px"
                    v-for="tarif2 in alletarifeFiltered"
                    :value="tarif2.tarif_id"
                    :key="tarif2.tarif_id"
                  >
                    {{ tarif2.un_name }} {{ tarif2.tarif_title }}
                  </option>
                </select>
              </th>
              <th style="width: 300px">
                <select
                  class="blue--text"
                  style="padding-left: 4px; font-size: 14px"
                  @change="pushTarifToVergleich($event, 3)"
                >
                  <option value selected>Tarif 3 wählen</option>
                  <option
                    style="padding-left: 10px"
                    v-for="tarif3 in alletarifeFiltered"
                    :value="tarif3.tarif_id"
                    :key="tarif3.tarif_id"
                  >
                    {{ tarif3.un_name }} {{ tarif3.tarif_title }}
                  </option>
                </select>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Monatlicher Beitrag</td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <strong>{{ tarif.preis_value }} €</strong>

                  <span v-if="tarif.empfehlung_type == 3"
                    >| Empfehlung "beste Leistung"</span
                  >
                  <span
                    v-if="
                      tarif.empfehlung_type == 1 || tarif.empfehlung_type == 2
                    "
                    >| Alternativ-Empfehlung
                  </span>

                  <span v-if="tarif.zuschlag_zahn > 0"
                    ><br />inkl.
                    {{ tarif.zuschlag_zahn }}
                    Zuschlag für Lücken</span
                  >
                </template>
              </td>
            </tr>
            <tr>
              <td>
                <strong class="pb-0 mb-0">Infopaket</strong>
                <br />
                <span class="caption m">kostenlos</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <v-btn
                    v-if="tarif.antraglink"
                    :href="tarif.antraglink"
                    color="blue"
                    class="text-white button_caps mt-1"
                  >
                    Antrag & Infopaket
                    <sup v-if="tarif.empfehlung_type > 0" class="text-white"
                      >Tipp</sup
                    >
                  </v-btn>
                </template>
              </td>
            </tr>
            <tr v-if="berechnung.ber_algo_view != 2">
              <td>
                <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                <br />
                <span class="caption">WaizmannWert</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif"
                  ><b>{{ tarif.tarif_waizmannwert }} %</b>
                </template>
              </td>
            </tr>
            <tr v-if="berechnung.ber_algo_view == 2">
              <td>
                <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                <br />
                <span class="caption m">Kieferorthopädie</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif"
                  ><b>{{ tarif.tarif_kwert_gesamt }} %</b>
                </template>
              </td>
            </tr>

            <tr v-if="berechnung.ber_sofort == 1">
              <td>
                <strong class="pb-0 mb-0">Angeratene Behandlungen:</strong>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <span v-if="!tarif.sofortversichert">nicht versichert</span>
                  <strong v-if="tarif.sofortversichert" class="text-green">{{
                    tarif.sofort_text_short
                  }}</strong>
                </template>
              </td>
            </tr>
            <tr v-if="berechnung.ber_wechsel == 1">
              <td>
                <strong class="pb-0 mb-0">Wechsel-Angebot</strong>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <span v-if="tarif.tarif_wechsel_angebot == 0"
                    >keine Wechseloption</span
                  >
                  <strong
                    v-if="tarif.tarif_wechsel_angebot == 1"
                    class="text-green"
                    >Sehr gute Wechseloption mit Wartezeiterlass</strong
                  >
                  <strong
                    v-if="tarif.tarif_wechsel_angebot == 2"
                    class="text--green"
                    >Gute Wechseloption</strong
                  >
                </template>
              </td>
            </tr>
            <tr
              v-if="
                berechnung.ber_algo_view != 2 &&
                berechnung.ber_alter > 17 &&
                berechnung.ber_luecke > 0
              "
            >
              <td>
                <strong class="pb-0 mb-0">Fehlende Zähne</strong>
                <br />
                <span class="caption m">mitversichert</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <span v-if="berechnung.ber_luecke == 1">
                    Ihr
                    <strong>fehlender Zahn</strong> ist
                  </span>
                  <span v-if="berechnung.ber_luecke > 1">
                    Ihre
                    <strong>{{ berechnung.ber_luecke }} fehlenden Zähne</strong>
                    sind
                  </span>
                  <span v-if="tarif.LueckeVersicherung == 1">
                    <strong class="text-green">kostenlos mitversichert</strong>.
                  </span>
                  <span
                    v-if="
                      tarif.LueckeVersicherung == 2 ||
                      tarif.LueckeVersicherung == 3
                    "
                  >
                    gegen einen Zuschlag von
                    {{ tarif.zuschlag_zahn }} € mitversichert. Der Zuschlag ist
                    im Monatsbeitrag von {{ tarif.preis_value }} € bereits
                    enthalten.
                  </span>

                  <span v-if="tarif.LueckeVersicherung == 4"
                    >ohne Aufschlag mitversichert. Allerdings verlängern sich
                    die anfänglichen Begrenzungen.</span
                  >
                  <span v-if="tarif.LueckeVersicherung == 0">
                    <strong class="red--text">nicht mitversichert.</strong>
                  </span>
                </template>
              </td>
            </tr>

            <tr>
              <td :colspan="showColspan">
                <h2>Clearment Vertragsschutz | Spezialleistungen</h2>
              </td>
            </tr>

            <tr>
              <td>
                <strong class="pb-0 mb-0"> Clearment</strong>
                <br />
                <span class="caption">Zertifizierung</span>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <span
                    v-if="tarif1.tarif_show_lsk_style"
                    :class="tarif1.tarif_show_lsk_style"
                  >
                    <b>{{ tarif1.tarif_show_lsk_word }}</b>
                  </span>
                </template>
              </td>
            </tr>

            <tr>
              <td>
                <strong class="pb-0 mb-0">
                  <strong>Clearment</strong>
                  <br />
                  <span class="caption">Vertragsschutz</span></strong
                >
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <span
                    v-if="
                      tarif.tarif_clearment_vs && tarif.tarif_clearment_vs == 1
                    "
                    ><b class="text-green">ja</b></span
                  >
                  <span
                    v-if="
                      tarif.tarif_clearment_vs && tarif.tarif_clearment_vs == 0
                    "
                    >nein</span
                  >
                </template>
              </td>
            </tr>
            <tr>
              <td>
                <strong>AntragAnnahmeCheck</strong>
              </td>
              <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                <template v-if="tarif">
                  <span
                    v-if="tarif.tarif_int_antrag && tarif.tarif_int_antrag == 1"
                    ><b class="text-green">ja</b></span
                  >
                  <span
                    v-if="tarif.tarif_int_antrag && tarif.tarif_int_antrag == 0"
                    >nein</span
                  >
                </template>
              </td>
            </tr>

            <template
              v-if="berechnung.ber_algo_view != 2 || tarif1.tarif_id == 276"
            >
              <tr>
                <td :colspan="showColspan">
                  <h2>Kieferorthopädie</h2>
                </td>
              </tr>

              <tr>
                <td>
                  <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                  <br />
                  <span class="caption">KFO KIG 1-2</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_kwert_1 }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                  <br />
                  <span class="caption">KFO KIG 3-5</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_kwert_3 }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">KFO Begrenzung</strong>
                  <br />
                  <span class="caption">KIG 1-2</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_leistung_kfo_beg_ogkv }} </b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">KFO Begrenzung</strong>
                  <br />
                  <span class="caption">KIG 3-5</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_leistung_kfo_beg_mgkv }} </b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Leistungen</strong>
                  <br />
                  <span class="caption">Kinder-Zahnarzt</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <ul class="body-2">
                    <li v-for="item in tarif.kindspezial" :key="item.id">
                      <span v-if="item.ja">{{ item.value }}</span>
                      <span
                        v-if="!item.ja"
                        class="text-red"
                        style="text-decoration: line-through"
                        >{{ item.value }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
            </template>

            <template v-if="berechnung.ber_algo_view != 2">
              <tr>
                <td :colspan="showColspan">
                  <h2>Leistungsübersicht der ersten Versicherungsjahren</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Ø-Erstattung</strong>
                  <br />
                  <span class="caption">im 1.-4. Jahr</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_wwert_4 }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Max. Erstattung</strong>
                  <br />
                  <span class="caption">im 1.-2. Jahr</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_int_begrenzung_2 }} €</b>
                </td>
              </tr>
              <tr>
                <td :colspan="showColspan">
                  <h2>Leistungsübersicht der ersten Versicherungsjahren</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Zahnersatz</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_leistung_ze }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Zahnerhalt</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_leistung_zb }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Prophylaxe</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_leistung_pzr }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Hightech</strong>
                  <br />
                  <span class="caption">Zahnarzt-Leistungen</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <ul class="body-2">
                    <li v-for="item in tarif.highspezial" :key="item.id">
                      <span v-if="item.ja">{{ item.value }}</span>
                      <span
                        v-if="!item.ja"
                        class="text-red"
                        style="text-decoration: line-through"
                        >{{ item.value }}</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td :colspan="showColspan">
                  <h2>Leistungen für Zahnersatz</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Zahnersatz</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_leistung_ze }} %</b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Wartezeit</strong>
                  <br />
                  <span class="caption">für Zahnersatz</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <b>{{ tarif.tarif_beschreibung_wz_ze }} </b>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Implantate</strong>
                  <br />
                  <span class="caption">Begr. Anzahl</span>
                  <br />
                  <span class="caption">Begr. Betrag</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_implantat }}</strong>
                  <br />
                  {{ tarif.tarif_beschreibung_implantat_beg_anzahl }}
                  <br />
                  {{ tarif.tarif_beschreibung_implantat_beg_betrag }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Inlays</strong>
                  <br />
                  <span class="caption">Begrenzungen</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_inlay }}</strong>
                  <br />
                  {{ tarif.tarif_beschreibung_inlay_beg }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Verblendungen</strong>
                  <br />
                  <span class="caption">Begrenzungen</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_verblendung }}</strong>
                  <br />
                  {{ tarif.tarif_beschreibung_verblendung_beg }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Knochenaufbau</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_knochenaufbau }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Funktionsanalyse</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_funktion_ze }}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Funktionsanalyse</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_funktion_ze }}</strong>
                </td>
              </tr>
              <tr>
                <td :colspan="showColspan">
                  <h2>Leistungen für zahnerhaltende Maßnahmen</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Zahnerhalt</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_leistung_zb }} %</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Wartezeit</strong>
                  <br />
                  <span class="caption">für Zahnerhalt</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_wz_zb }} </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Kunststofffüllungen</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_ksf }} </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Wurzelbehandlung</strong>
                  <br />
                  <span class="caption">wenn GKV leistet</span>
                  <br />
                  <span class="caption red--text">wenn GKV nicht leistet</span>
                  <br />
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>
                    {{ tarif.tarif_beschreibung_wurzel_mgkv }}
                    <br />
                    {{ tarif.tarif_beschreibung_wurzel_ogkv }}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Parodontose-Leistungen</strong>
                  <br />
                  <span class="caption">wenn GKV leistet</span>
                  <br />
                  <span class="caption red--text">wenn GKV nicht leistet</span>
                  <br />
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>
                    {{ tarif.tarif_beschreibung_paro_mgkv }}
                    <br />
                    {{ tarif.tarif_beschreibung_paro_ogkv }}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Funktionsanalyse</strong>
                  <br />
                  <span class="caption">für Zahnerhalt</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_funktion_zb }}</strong>
                </td>
              </tr>
              <tr>
                <td :colspan="showColspan">
                  <h2>Leistungen für Prophylaxe</h2>
                </td>
              </tr>

              <tr>
                <td>
                  <strong class="pb-0 mb-0">Prophylaxe</strong>
                  <br />
                  <span class="caption">für Zahnerhalt</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_leistung_pzr }} %</strong>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Wartezeit</strong>
                  <br />
                  <span class="caption">für Prophylaxe</span>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_beschreibung_wz_pzr }} </strong>
                </td>
              </tr>

              <tr>
                <td>
                  <strong class="pb-0 mb-0">Details</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <strong>{{ tarif.tarif_leistung_pzr_details }} </strong>
                </td>
              </tr>

              <tr>
                <td>
                  <strong class="pb-0 mb-0">Bleaching</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <span v-if="!tarif.tarif_beschreibung_bleaching"
                    >keine Leistung</span
                  >
                  <span v-if="tarif.tarif_beschreibung_bleaching">{{
                    tarif.tarif_beschreibung_bleaching
                  }}</span>
                </td>
              </tr>
              <tr>
                <td :colspan="showColspan">
                  <h2>Begrenzungen, Wartezeiten & Kündigungsfristen</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Begrenzungen</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <span
                    v-html="
                      tarif.tarif_beschreibung_beg.replace(/\n/g, '<br />')
                    "
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Wartezeiten</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  <span
                    v-html="
                      tarif1.tarif_modal_wartezeit.replace(/\n/g, '<br />')
                    "
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="pb-0 mb-0">Kündigungsfrist</strong>
                </td>
                <td v-for="tarif in tarifs" :key="tarif.tarif_id">
                  {{ tarif.tarif_int_kuendigung_details }}
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, reactive, computed, defineProps } from "vue";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  // Props declaration with defineProps if needed in <script setup>
  const props = defineProps(["tarif1", "alletarife", "berechnung"]);
  console.log(props.tarif1.tarif_id);
  // State and reactivity
  //   const showSofortPop1 = ref(false);
  //   const showSofortPop2 = ref(false);
  //   const showSofortPop3 = ref(false);
  //   const valueSelected = ref(0);
  //   const tarifNrSelected = ref(0);
  const alletarifeFiltered = ref([]);
  // const showTable = ref(false);
  const showColspan = ref(2);

  const state = reactive({
    select: {
      tarif_id: props.tarif1.tarif_id,
      action: "beitragsentwicklung",
      berechnung: props.berechnung,
      tarif: props.tarif1,
    },
    tarif2: null, // Corrected to 'null'
    tarif3: null, // Corrected to 'null'
  });

  const tarifs = computed(() => {
    let tarifsArray = [props.tarif1]; // Start with tarif1 always present
    if (state.tarif2 !== null) {
      tarifsArray.push(state.tarif2); // Add tarif3 only if tarif2 is present and tarif3 itself is not null
    }
    if (state.tarif3 !== null) {
      tarifsArray.push(state.tarif3); // Add tarif3 only if tarif2 is present and tarif3 itself is not null
    }

    return tarifsArray;
  });

  // Equivalent of the created lifecycle hook
  onMounted(() => {
    filterAlle();
  });

  // Methods
  function filterAlle() {
    alletarifeFiltered.value = props.alletarife.filter(
      (x) => x.tarif_id !== parseInt(props.tarif1.tarif_id)
    );
  }

  function pushTarifToVergleich(event, tarif_nr) {
    if (tarif_nr === 2) {
      const treffer2 = props.alletarife.filter(
        (x) => parseInt(x.tarif_id) === parseInt(event.target.value)
      );
      state.tarif2 = treffer2[0];
    } else if (tarif_nr === 3) {
      const treffer3 = props.alletarife.filter(
        (x) => parseInt(x.tarif_id) === parseInt(event.target.value)
      );
      state.tarif3 = treffer3[0];
    }
  }
  watch(
    () => tarifs.value.length, // This is the source reactive property to watch
    (newLength) => {
      showColspan.value = newLength + 1;
    },
    {
      immediate: true, // This option will trigger the handler immediately with the current value
    }
  );
</script>

<style>
  .my-table td {
    vertical-align: top;
  }
</style>
