import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    userData: {
      user_name: "",
      user_email: "",
      user_id: "",
      user_strasse: "",
      user_plz: "",
      user_ort: "",
      user_firma: "",
      user_hsnr: "",
    },
    showLogin: false,
    isLogged: false,
  }),
  actions: {
    setUser(data) {
      this.userData = data;
    },
  },
});
