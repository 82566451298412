<template>
  <v-layout class="greytext ggfont mt-4">
    <v-container class="mt-0" style="max-width: 1000px">
      <!-- <v-breadcrumbs
        v-if="breads"
        class="ml-0 pl-0"
        :items="breads"
        divider=" / "
      ></v-breadcrumbs> -->
      <v-row class="mb-8">
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="9">
              <h1 style="padding-bottom: 0px; font-size: 38px">
                Heilpraktikerversicherung für Kinder | Die TOP 6 Tarife 2024
              </h1>

              Jetzt die beste Heilpraktikerversicherung für Ihr Kind finden. Mit
              DornsteinTabelle, von Heilpraktikern empfohlen. Das Team der
              DornsteinTabelle hat Kinder Heilpraktikerversicherungen getestet
              und 6 leistungsstarke Kindertarife für Eltern zusammengestellt.
              Wir zeigen, was diese Kinder-Tarife leisten, wie viel sie kosten
              und worauf beim Abschluss zu achten ist. Die
              <b>DornsteinTabelle</b> listet:

              <ul class="mt-8 ml-4 mb-8">
                <li>
                  Die 6 besten Kinder Heilpraktikerversicherungen 2024 für z.B.
                  Akupunktur, Homöopathie und Osteopathie, Chiropraktik
                </li>
                <li>
                  Leistungsstarke Kinder Heilpraktikerversicherungen mit 100%
                  Erstattung für sämtliche Naturheilverfahren bis 1.000 € pro
                  Jahr
                </li>
                <li>
                  Tarife mit Erstattung bis zum Höchstbetrag nach
                  Gebührenordnung für Heilpraktiker (GebüH) und
                  Hufelandverzeichnis / GÖÄ
                </li>
                <li>
                  Ambulante Zusatzversicherungen für Kinder mit Fokus
                  Naturheilkunde / Alternativmedizin
                </li>
                <li>
                  Top Kinder-Tarife, die beim Heilpraktiker und Arzt für
                  Naturheilverfahren leisten
                </li>
                <li>
                  Kinder Heilpraktiker Zusatzversicherungen mit Leistungen für
                  Sehhilfen z.B. Brille
                </li>
                <li>
                  Kinder Heilpraktikerversicherungen, die man trotz
                  Vorerkrankung abschließen kann
                </li>
                <li>Kinder Heilpraktiker-Versicherungen ohne Wartezeit</li>

                <li>
                  <a href="/vergleich/starten/heilpraktiker"
                    >Jetzt "Kinder"-Onlinevergleich starten</a
                  >
                </li>
              </ul>
              <v-btn
                depressed
                tile
                color="#088A85"
                class="nocaps"
                style="color: white"
                href="/vergleich/starten/heilpraktiker"
              >
                Jetzt die besten Kinder-Heilpraktikerversicherungen
                vergleichen</v-btn
              ><br /><br />
            </v-col>
            <v-col cols="12" md="3">
              <div style="border-left: 4px solid; border-left-color: #088a85">
                <v-avatar class="profile" tile color="grey" size="150">
                  <v-img :src="require('@/assets/site/kilianlink.jpg')"></v-img>
                </v-avatar>
              </div>

              <br /><b>Kilian Link</b>, Entwickler der
              <b>Realwert-Methode</b> für Heilpraktiker-Zusatzversicherungen
              <br /><br /><br />
            </v-col>
          </v-row>

          <v-card elevation="4" class="pb-2">
            <v-table dense>
              <br />
              <thead>
                <tr class="redground">
                  <th colspan="5">
                    <h2 class="text-white">
                      TOP 6 Kinder Heilpraktikerversicherungen 2024
                    </h2>
                  </th>
                </tr>
                <tr style="background-color: #f2f2f2">
                  <th style="max-width: 10px">#</th>
                  <th>Tarif</th>

                  <th>Ø-Erstattung</th>
                  <th>Begrenzung</th>
                  <th>Preis 12 Jahre</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="vertical-align: top"
                  v-for="tarif in tarife"
                  :key="tarif.id"
                >
                  <td>{{ tarif.rank }}.</td>
                  <td>
                    <a
                      :href="`/heilpraktiker_zusatzversicherung/${tarif.tarif_key}`"
                    >
                      <strong>{{ tarif.versicherung }}</strong>
                    </a>

                    <br />
                    {{ tarif.tarif_title }}
                  </td>
                  <td>
                    <strong>{{ tarif.waizmannwert }}</strong>
                  </td>
                  <td>{{ tarif.begrenzung }}</td>
                  <td>
                    <span v-html="tarif.preis_value"></span>

                    <span class="green--text" v-if="tarif.hp_annahme == 1"
                      ><br /><b>Einfache</b> Annahme-bedingungen</span
                    >
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card>
          <br /><br />
          <h2>
            Wir helfen Eltern, die richtige Heilpraktikerversicherung für Kinder
            zu finden
          </h2>
          Behandlungen beim Heilpraktiker sind zu einer Selbstverständlichkeit
          geworden. Dies gilt auch bei Kindern und Jugendlichen.
          <br /><br />
          Denken Eltern an Behandlungen beim Heilpraktiker stellt sich die Frage
          nach der Kostenerstattung. Nimmt man regelmäßig Behandlungen beim
          Heilpraktiker in Anspruch, können sich diese Behandlungskosten gut und
          gerne auf einen höheren dreistelligen Betrag pro Jahr belaufen.
          Gesetzlich versicherte Kinder bzw. Jugendliche haben mit einer
          leistungsstarken Kinder-Heilpraktikerversicherung jedoch die
          Möglichkeit, Behandlungskosten vollständig erstattet zu bekommen,
          welche im Leistungskatalog der gesetzlichen Krankenkassen (GKV) so
          nicht vorgesehen sind.

          <br /><br />
          <v-card elevation="4" class="pb-2 my-12">
            <v-card-text>
              <h3>EINE BEWUSSTE ENTSCHEIDUNG FÜR MEINE GESUNDHEIT</h3>

              <span style="font-size: 11px"
                >Katja, 36 Jahre aus NRW: Barmenia Mehr Gesundheit 2.000</span
              >
              <br /><em>
                Ich freue mich darauf, mit der Versicherung einen für mich
                wesentliche Investition in meine eigene Gesundheit zu machen.
                Viele Grüße und herzlichen Dank für Ihre Unterstützung.</em
              >
            </v-card-text>
          </v-card>
          <h2>
            Wie finden Eltern die richtige Zusatzversicherung Naturheilkunde für
            ihr Kind?
          </h2>
          DornsteinTabelle hat ambulante Zusatzversicherungen mit Fokus
          Naturheilkunde-Leistungen getestet und diese in der DornsteinTabelle
          Kinder gelistet. Unser ExpertenTeam bietet Eltern starke
          Naturheilkunde-Zusatzversicherungen für Kinder, die auch extra
          Vorsorgeleistungen für Kinder bieten. Zudem erstatten manche dieser
          ambulanten Zusatzversicherungen auch für Sehhilfen wie Brille und
          Kontaktlinsen, was für Eltern ein zusätzlicher Vorteil sein kann. Alle
          von DornsteinTabelle gelisteten Kinder Heilpraktiker-Versicherungen
          leisten ohne Einschränkungen analog zu Erwachsenen-Tarifen und können
          somit im Erwachsenenalter weitergeführt werden.
          <br /><br />
          <b style="color: #088a85">TIPP für Eltern:</b> Besonders
          empfehlenswert ist der Abschluss einer Heilpraktiker
          Zusatzversicherung im Kindesalter, da bei Heilpraktikerversicherungen
          Gesundheitsfragen zu beantworten sind. Und da man im Kindesalter eher
          gesund ist und keine chronischen Erkrankungen oder Vorerkrankungen
          hat, ist dies der ideale Zeitpunkt für den Abschluss einer
          Heilpraktikerversicherung. Nutzen auch Sie DornsteinTabelle, die Nr. 1
          Vergleichstabelle für Kinder Heilpraktikerversicherungen, um eine
          leistungsstarke Heilpraktikerversicherung für Ihr Kind zu finden.
          <v-divider class="my-8"></v-divider>
          <h2>Kinder Heilpraktikerversicherung bei Vorerkrankung</h2>

          Kann ich eine Heilpraktikerversicherung trotz Vorerkrankung meines
          Kindes abschließen? Eine Heilpraktiker-Zusatzversicherung kann auch
          für Kinder mit Vorerkrankungen abgeschlossen werden. Dabei muss
          abgeklärt werden, welche Vorerkrankung diagnostiziert wurde (in der
          Patientenakte des Kindes dokumentiert wurde) und ob auf Grund dieser
          Vorerkrankung ein Abschluss noch möglich ist.

          <br /><br />
          <b style="color: #088a85">TIPP für Eltern:</b> Wir helfen, auch bei
          Vorerkrankungen einen passenden Heilpraktiker-Tarif für Kinder zu
          finden. Wir bieten Tarife mit einfachen Gesundheitsfragen und drei
          Heilpraktiker-Tarife mit sog. abschließend gestellten
          Gesundheitsfragen. Weitere Informationen der DornsteinTabelle Experten
          finden Sie unter: Heilpraktiker-Zusatzversicherung bei Vorerkrankung
          <v-divider class="my-8"></v-divider>
          <h2>
            Heilpraktikerversicherungen für Kinder - Vergleich & Testsieger 2024
          </h2>
          <br />
          <v-row v-for="tarif in tarife" :key="tarif.id" class="mb-4">
            <v-col cols="12" md="12">
              <v-card elevation="4" class="pb-2">
                <v-card-text>
                  <h3 class="mb-2">
                    {{ tarif.rank }}. Leistungen
                    <a
                      :href="`/heilpraktiker_zusatzversicherung/${tarif.tarif_key}`"
                      >{{ tarif.versicherung }} {{ tarif.tarif_title }}
                    </a>
                  </h3>
                  <v-row>
                    <v-col cols="12" md="3">
                      <b>Naturheilkunde Leistung: </b><br />
                      <b>{{ tarif.hp_max_erstattung_1jahr }} €</b> pro Jahr
                      <br />
                    </v-col>
                    <v-col cols="12" md="3">
                      <b>Wartezeit: </b><br />
                      {{ tarif.hp_wartezeit }}
                    </v-col>
                    <v-col cols="12" md="3">
                      <b>Preis pro Monat: </b><br />
                      Kind 12 Jahre:
                      <b>{{ tarif.preis_value }} €</b>
                    </v-col>
                    <v-col cols="12" md="3">
                      <b>Dornstein Realwert </b><br />
                      <strong class="redtext"
                        >{{ tarif.waizmannwert }} %</strong
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="divider"></div>
                      <span v-html="tarif.hp_kinder_fazit"></span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <br />
          <h2>Häufige Fragen zur Kinder Heilpraktikerversicherung</h2>
          <br />

          <b>Ist eine Heilpraktikerversicherung für Kinder sinnvoll?</b><br />
          Ja. Eine Heilpraktikerversicherung ist für Kinder sinnvoll.
          Alternativmedizin wird für Kinder immer beliebter, da gerade Kinder
          und Jugendliche von schonenden Heilmethoden besonders profitieren.
          Alleine die ganzheitliche Behandlung bei einem Heilpraktiker oder Arzt
          für Naturheilverfahren bietet Heranwachsenden die Möglichkeit, mit den
          Kräften der Natur gesund zu werden sowie natürliche Abwehrkräfte
          aufzubauen. Ja. Eine Heilpraktikerversicherung ist für Kinder
          sinnvoll. Alternativmedizin für Kinder wird immer beliebter, da gerade
          Kinder und Jugendliche von schonenden Heilmethoden besonders
          profitieren. Alleine die ganzheitliche Behandlung bei einem
          Heilpraktiker oder Arzt für Naturheilverfahren bietet Heranwachsenden
          die Möglichkeit, mit den Kräften der Natur gesund zu werden sowie
          natürliche Abwehrkräfte aufzubauen.
          <br /><br />
          <b>Wieviel kostet eine Heilpraktikerversicherung für Kinder?</b><br />
          Eine gute Heilpraktikerversicherung für Kinder kostet 8 - 12 € pro
          Monat. Kinder Heilpraktikerversicherungen eröffnen Kindern und
          Jugendlichen Zugang zu alternativen Heilmethoden abseits der
          klassischen Schulmedizin, der sog. kassenärztlichen Regelversorgung.
          Man kann sagen: Hoher Nutzen für die Gesundheit des Kindes, zu sehr
          überschaubaren Versicherungsbeiträgen.
          <br /><br />

          <b
            >Gibt es eine Kinder Heilpraktikerversicherung ohne
            Gesundheitsfragen?</b
          ><br />
          Nein. Es gibt keine Heilpraktiker-Zusatzversicherung für Kinder ohne
          Gesundheitsfragen. Jede Zusatzversicherung für Naturheilverfahren
          stellt im Antrag Gesundheitsfragen, die es im Antrag zu beantworten
          gilt.
          <br /><br />

          <b
            >Warum soll eine Heilpraktikerversicherung für Kinder so früh wie
            möglich abgeschlossen werden?</b
          ><br />
          Jede ambulante Zusatzversicherung stellt Gesundheitsfragen. Diese sind
          im Antrag zu beantworten. Teils sind diese Fragen umfangreich, dass es
          ein Vorteil ist, eine Heilpraktikerversicherung bereits als Kind /
          Jugendlicher abzuschließen. Da Kinder und Jugendliche meist weder
          schwere Krankheiten, Vorerkrankungen noch chronische Krankheiten
          haben, ist die Annahme in jungen Jahren bei der Naturheilkunde
          Zusatzversicherung also besonders einfach. Die Gefahren einer
          Ablehnung des Antrags aufgrund einer später auftretenden chronischen
          Erkrankung wie z.B. Asthma oder Rückenleiden werden somit deutlich
          reduziert.
          <br /><br />

          <b>Gibt es Heilpraktikerversicherungen für Kinder ohne Wartezeit?</b
          ><br />
          Ja. Es gibt mehrere Kinder-Heilpraktiker-Zusatzversicherungen ohne
          Wartezeit. Die Barmenia Mehr Gesundheit 1.000 sowie die Barmenia Mehr
          Gesundheit 2.000 leisten ohne Wartezeit für Kinder ab dem ersten Tag.
          Zudem leisten die Heilpraktiker Zusatzversicherungen der Union
          Krankenversicherung NaturPRIVAT sowie die Signal Iduna AmbulantPLUSpur
          Heilpraktiker Zusatzversicherung ohne Wartezeit.
          <br /><br />
          <b
            >Ist Barmenia die beste Heilpraktiker Zusatzversicherung für
            Kinder?</b
          ><br />
          Ja. Aktuell bietet Barmenia mit dem Tarif Mehr Gesundheit die beste
          Kinder Heilpraktikerversicherung. Barmenia bietet zwei leistungsstarke
          Heilpraktiker-Tarife, die Barmenia Mehr Gesundheit 1.000 (1.000 €
          Versicherungsleistung pro Jahr) und die Barmenia Mehr Gesundheit 2.000
          (2.000 € Versicherungsleistung pro Jahr). Die Versicherungsleistungen
          umfassen Naturheilkunde, Vorsorgeleistungen, Impfungen, Heilmittel.
          <br /><br />
          <b
            >Welche Zusatzversicherung leistet für Akupunktur, Homöopathie,
            Osteopathie und Chiropraktik bei Kindern?</b
          ><br />
          Alle von DornsteinTabelle getesteten Kinder
          Heilpraktiker-Zusatzversicherungen leisten für Akupunktur,
          Homöopathie, Osteopathie und Chiropraktik. Dies bis zum Höchstbetrag
          für das Hufelandverzeichnis sowie für die GOÄ / GebüH. Unsere Tarife
          leisten für wichtige alternative Heilmethoden für Kinder.
          <br /><br />
          <b
            >Welche Leistungen soll eine Kinder Heilpraktiker-Zusatzversicherung
            bieten?</b
          ><br />
          Eine Kinder Heilpraktikerversicherung soll für
          Naturheilkunde-Leistungen / alternative Heilmethoden mindestens in
          Höhe von 800 € pro Jahr leisten. Besser ist es, wenn die
          Heilpraktiker-Zusatzversicherung 1.000 € pro Jahr für
          Naturheilkunde-Leistungen / alternative Heilmethoden bietet.
          Behandlungen sollen beim Heilpraktiker sowie beim Arzt für
          Naturheilverfahren erstattungsfähig sein. Die Erstattung soll bis zum
          Höchstbetrag für das Hufelandverzeichnis sowie für die GOÄ / GebüH
          gegeben sein.
          <b>Tipp:</b> Denken Sie an später, dass bereits das Kind eine
          leistungsstarke Heilpraktiker-Zusatzversicherung hat, welche im
          Erwachsenenalter dann ohne erneute Gesundheitsprüfung nahtlos
          weitergeführt werden kann. <br /><br />
          <b>Gibt es eine Familien Zusatzversicherung Heilpraktiker?</b><br />
          Nein. Es gibt keine Heilpraktiker-Zusatzversicherung für die ganze
          Familie. Im Gegensatz zur gesetzlichen Krankenversicherung (GKV) gibt
          es bei der privaten Heilpraktiker Zusatzversicherung keine
          Familienversicherung.
          <br /><br />
          <b>Warum gibt es keine Familien Zusatzversicherung Heilpraktiker?</b
          ><br />
          Bei der privaten Heilpraktiker-Zusatzversicherung birgt jede zu
          versichernde Person für die Versicherungsgesellschaft ein eigenes
          Risiko. Demnach muss jede Person einzeln versichert werden, da es in
          der privaten Zusatzversicherung kein Solidaritätsprinzip wie bei der
          Gesetzlichen Krankenversicherung gibt. Es sind also Gesundheitsfragen
          von jeder Person eigens zu beantworten. Jedes Familienmitglied muss
          demnach die Gesundheitsfragen für sich beantworten, um einzeln
          versichert zu werden. Annahme aufgrund der Gesundheitsprüfung
          vorausgesetzt.
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { ref, getCurrentInstance, onMounted } from "vue";
  import BaseCalls from "@/services/BaseCalls";

  const { proxy } = getCurrentInstance();
  const tarife = ref([]);
  async function getTarife() {
    let select = {};
    select.action = "getStaticHeilTarife";
    select.select = "heilAlleStartKind";
    select.addPreis = 11;

    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarife) {
        tarife.value = resp.data.tarife;
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  async function saveSeo(seo_type, seo_object_id) {
    let guestSelect = {};
    guestSelect.action = "saveSeo";
    guestSelect.seo_type = seo_type;
    guestSelect.seo_object_id = seo_object_id;
    var string = document.referrer,
      substring = ".google";
    var isGoogle = string.indexOf(substring) !== -1;
    if (isGoogle) {
      console.log("saveSeoStart");
      try {
        const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
        if (resp.data && resp.data) {
          console.log("saveSeoErg" + resp.data);
        }
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  onMounted(() => {
    saveSeo(1, 14);
    getTarife();
    proxy.$updateDocumentHead(
      "Heilpraktikerversicherung Kind | Die TOP 6 Tarife 2024",
      "Beste Kinder Heilpraktikerversicherungen mit 100% Erstattung | 1.000 € Budget pro Jahr | ohne Wartezeit | für 9,- € pro Monat."
    );
  });
</script>
