<template>
  <div style="overflow-wrap: break-word" class="pr-1">
    <v-row>
      <v-col cols="12">
        <div v-if="user.user_id > 0">
          <h3>Ihr Account</h3>
          <ul>
            <li>{{ user.user_name }}</li>
            <li>
              <router-link to="/kostenlos/anfordern"
                >DornsteinTabellen anfordern</router-link
              >
            </li>
            <li>
              <router-link to="/account">Account bearbeiten</router-link>
            </li>
          </ul>
          <v-divider class="mt-2 my-4"></v-divider>
        </div>
        <h3>Rechner starten</h3>
        Vergleichen Sie die leistungsstärksten
        Heilpraktiker-Zusatzversicherungen.
        <router-link to="/vergleich/starten/heilpraktiker">
          <v-btn color="turk" rounded="0" class="mt-2 button_caps"
            >Vergleich starten</v-btn
          ></router-link
        >
        <!-- <v-divider :thickness="2" class="my-6"></v-divider>
        <h3>Alle Rechner</h3>
        <ul>
          <li>
            <router-link to="/vergleich/starten">Zahnzusatz</router-link>
          </li>
          <li>
            <router-link to="/vergleich/starten/brille"
              >Brillenversicherung</router-link
            >
          </li>
          <li>
            <router-link to="/vergleich/starten/heilpraktiker"
              >Heilpraktiker</router-link
            >
          </li>
          <li>
            <router-link to="/vergleich/starten/krankenhaus"
              >Krankenhaus</router-link
            >
          </li>
        </ul> -->
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { computed } from "vue";
  import { useUserStore } from "@/stores/user";
  const userStore = useUserStore();
  const user = computed(() => userStore.userData);
  // import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  // const berechnungStore = useBerechnungStore();
</script>
