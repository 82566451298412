<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <VergleichHeader
          v-if="!sharedDataStore.isMobile() && tarife.length > 0"
          :componentKeyStatus="componentKeyStatus"
          :berechnung="berechnung"
          :vergleichCount="vergleichCount"
        />
        <VergleichHeaderMobil
          v-if="sharedDataStore.isMobile() && tarife.length > 0"
          :componentKeyStatus="componentKeyStatus"
          :berechnung="berechnung"
          :vergleichCount="vergleichCount"
        />
        <WechselVergleich
          :componentKeyStatus2="componentKeyStatus2"
          :tarif="wechselTarif"
          v-if="
            !sharedDataStore.isMobile() &&
            wechselTarif.tarif_id > 0 &&
            berechnung.ber_segment <= 1 &&
            berechnung.ber_wechsel_tarif_id > 0
          "
        />

        <div v-for="t in tarife" :key="t.tarif_id">
          <CardBig
            :componentKeyStatus="componentKeyStatus"
            v-if="!sharedDataStore.isMobile()"
            style="max-width: 999px"
            :tarif="t"
            :berechnung="berechnung"
          />
          <CardSmall
            :componentKeyStatus="componentKeyStatus"
            v-if="sharedDataStore.isMobile()"
            :tarif="t"
            :berechnung="berechnung"
          />
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="berechnungStore.tarif.showPopYes"
      :width="berechnungStore.tarif.dialogWidth"
    >
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn
              icon
              @click="berechnungStore.closePop(berechnungStore.tarif)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <SofortPop
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'sofortpop'"
          :tarif="berechnungStore.tarif"
        />
        <VergleichKinder
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'vergleichkinder'"
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <VergleichLuecke
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'vergleichluecke'"
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
          :berechnung="berechnung"
        />
        <KinderWert
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'kinderwert'"
          :tarif="berechnungStore.tarif"
        />
        <ClearmentSchutz
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'vertragsschutz'"
          :tarif="berechnungStore.tarif"
        />
        <WaizmannWert
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'waizmannwert'"
          :tarif="berechnungStore.tarif"
          :berechnung="berechnung"
        />

        <ClearmentLsk
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'clearmentlsk'"
          :tarif="berechnungStore.tarif"
        />
        <BeitragsEntwicklung
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'beitragsentwicklung'"
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <AnnahmeCheck
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'annahmecheck'"
          :berechnung="berechnung"
          :vergleichCount="vergleichCount"
          :notarife="notarife"
        />
        <AnnahmeKriterien
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'annahmekriterien'"
          :berechnung="berechnung"
        />
        <HightechVergleich
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'vergleichhightech'"
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <SchnellVergleich
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'schnellvergleich' &&
            berechnung.ber_segment <= 1
          "
          :berechnung="berechnung"
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <SchnellVergleichBrille
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'schnellvergleich' &&
            berechnung.ber_segment == 2
          "
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <SchnellVergleichHeil
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'schnellvergleich' &&
            berechnung.ber_segment == 3
          "
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <SchnellVergleichKrank
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'schnellvergleich' &&
            berechnung.ber_segment == 4
          "
          :tarif="berechnungStore.tarif"
          :alletarife="tarife"
        />
        <DreiVergleichZahn
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'dreivergleich' &&
            berechnung.ber_segment <= 1
          "
          :tarif1="berechnungStore.tarif"
          :alletarife="tarife"
          :berechnung="berechnung"
        />
        <DreiVergleichBrille
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'dreivergleich' &&
            berechnung.ber_segment == 2
          "
          :tarif1="berechnungStore.tarif"
          :alletarife="tarife"
          :berechnung="berechnung"
        />
        <DreiVergleichHeil
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'dreivergleich' &&
            berechnung.ber_segment == 3
          "
          :tarif1="berechnungStore.tarif"
          :alletarife="tarife"
          :berechnung="berechnung"
        />
        <AlleBerechnungen
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'alleberechnungen'"
        />
        <DreiVergleichKrank
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'dreivergleich' &&
            berechnung.ber_segment == 4
          "
          :tarif1="berechnungStore.tarif"
          :alletarife="tarife"
          :berechnung="berechnung"
        />
        <ZahnfehlstellungNicht
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'zahnfehlstellungnicht'"
          :berechnung="berechnung"
        />
        <KfoNicht
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'kfonichtmehr'"
          :berechnung="berechnung"
        />
        <LaufendeBehandlung
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'laufendebehandlung'"
          :berechnung="berechnung"
        />
        <KeineTarifeLaufendeBehandlung
          :componentKeyStatus="componentKeyStatus"
          v-if="
            berechnungStore.tarif.showPopType == 'keinetarifelaufendebehandlung'
          "
          :berechnung="berechnung"
        />
        <FamilienVersicherung
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'familienversicherung'"
          :berechnung="berechnung"
        />
        <ParoEmpfehlung
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'paroempfehlung'"
          :berechnung="berechnung"
        />
        <PrivateKranken
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'privatekranken'"
          :berechnung="berechnung"
        />
        <WechselSinnvoll
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'wechsel'"
          :berechnung="berechnung"
        />
        <WechselBehandlung
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'wechselbehandlung'"
          :berechnung="berechnung"
        />
        <TarifBeschreibung
          :componentKeyStatus="componentKeyStatus"
          v-if="berechnungStore.tarif.showPopType == 'tarifbeschreibung'"
          :tarif="berechnungStore.tarif"
          :berechnung="berechnung"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
  import { ref, watch, onMounted, getCurrentInstance } from "vue";
  import { useRoute } from "vue-router";
  import BaseCalls from "@/services/BaseCalls";
  import { useLoaderStore } from "@/stores/loaderStore";
  import BeitragsEntwicklung from "@/components/tarif/info/BeitragsEntwicklung";
  import ClearmentSchutz from "@/components/tarif/info/ClearmentSchutz";
  import ClearmentLsk from "@/components/tarif/info/ClearmentLsk";
  import KinderWert from "@/components/tarif/zahn/KinderWert";
  import SofortPop from "@/components/tarif/zahn/SofortPop";
  import CardBig from "@/components/tarif/layouts/CardBig";
  import CardSmall from "@/components/tarif/layouts/CardSmall";
  import TarifBeschreibung from "@/components/tarif/layouts/TarifBeschreibung";
  import DreiVergleichZahn from "@/components/tarif/vergleich/zahn/DreiVergleich";
  import DreiVergleichBrille from "@/components/tarif/vergleich/brille/DreiVergleich";
  import DreiVergleichHeil from "@/components/tarif/vergleich/heil/DreiVergleich";
  import DreiVergleichKrank from "@/components/tarif/vergleich/krank/DreiVergleich";
  import VergleichKinder from "@/components/tarif/vergleich/zahn/VergleichKinder";
  import VergleichLuecke from "@/components/tarif/vergleich/zahn/VergleichLuecke";
  import ZahnfehlstellungNicht from "@/components/tarif/vergleich/zahn/ZahnfehlstellungNicht";
  import KfoNicht from "@/components/tarif/vergleich/zahn/KfoNicht";
  import LaufendeBehandlung from "@/components/tarif/vergleich/zahn/LaufendeBehandlung";
  import KeineTarifeLaufendeBehandlung from "@/components/tarif/vergleich/zahn/KeineTarifeLaufendeBehandlung";
  import WechselSinnvoll from "@/components/tarif/vergleich/zahn/WechselSinnvoll";
  import WechselBehandlung from "@/components/tarif/vergleich/zahn/WechselBehandlung";
  import SchnellVergleich from "@/components/tarif/vergleich/zahn/SchnellVergleich";
  import SchnellVergleichBrille from "@/components/tarif/vergleich/brille/SchnellVergleich";
  import SchnellVergleichHeil from "@/components/tarif/vergleich/heil/SchnellVergleich";
  import SchnellVergleichKrank from "@/components/tarif/vergleich/krank/SchnellVergleich";
  import FamilienVersicherung from "@/components/tarif/vergleich/zahn/FamilienVersicherung";
  import ParoEmpfehlung from "@/components/berechnung/vergleich/zahn/ParoEmpfehlung";
  import HightechVergleich from "@/components/tarif/vergleich/zahn/HightechVergleich";
  import PrivateKranken from "@/components/tarif/vergleich/zahn/PrivateKranken";
  import VergleichHeader from "@/components/tarif/vergleich/VergleichHeader";
  import VergleichHeaderMobil from "@/components/tarif/vergleich/VergleichHeaderMobil";
  import AnnahmeCheck from "@/components/tarif/vergleich/AnnahmeCheck";
  import AnnahmeKriterien from "@/components/tarif/vergleich/AnnahmeKriterien";
  import WaizmannWert from "@/components/tarif/vergleich/WaizmannWert";

  import AlleBerechnungen from "@/components/berechnung/vergleich/AlleBerechnungen";
  import WechselVergleich from "@/components/berechnung/vergleich/WechselVergleich";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useBerechnungStore } from "@/stores/berechnung";
  const berechnungStore = useBerechnungStore();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();

  const loaderStore = useLoaderStore();
  const route = useRoute();
  const tarife = ref([]);
  const notarife = ref([]);
  const componentKeyStatus = ref(0);
  const componentKeyStatus2 = ref(0);
  const vergleichCount = ref({
    notarifeCount: 0,
    vergleichCount: 0,
  });
  const isLoaded = ref(false);
  const berechnung = ref(false);
  const wechselTarif = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  async function getVergleich(token) {
    let berSelect = {};
    berSelect.action = "getVergleichToken";
    berSelect.ber_identity_token = token;
    console.log("token: " + token);
    try {
      const resp = await BaseCalls.postVergleich(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        tarife.value = resp.data.tarife;
        berechnung.value = resp.data.berechnung;
        notarife.value = resp.data.notarife;
        vergleichCount.value.tarifeCount = resp.data.tarifeCount;
        vergleichCount.value.notarifeCount = resp.data.notarifeCount;
        berechnungStore.setBerechnung(resp.data.berechnung);
        if (
          resp.data.berechnung.ber_segment <= 1 &&
          resp.data.berechnung.ber_wechsel_tarif_id > 0
        ) {
          getWechselTarif(resp.data.berechnung.ber_wechsel_tarif_id);
        }
        componentKeyStatus.value = componentKeyStatus.value + 1;
        if (resp.data.berechnung.ber_id !== 0) {
          isLoaded.value = true;
        }
        setLoading(false);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  async function getWechselTarif(tarif_id) {
    let select = {};
    select.tarif_id = tarif_id;
    select.ber_identity_token = berechnung.value.ber_identity_token;
    select.action = "getTarifAntragBerechnung";

    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarif) {
        wechselTarif.value = resp.data.tarif;
        componentKeyStatus2.value = componentKeyStatus2.value + 1;
      } else {
        select.action = "getZahnTarifId";
        const resp = await BaseCalls.postTarif(select);
        if (resp.data && resp.data.tarif) {
          wechselTarif.value = resp.data.tarif;
          componentKeyStatus2.value = componentKeyStatus2.value + 1;
        }
      }
    } catch (err) {
      console.log("error tarife");
    }
  }
  watch(
    () => route.params.token,
    async (newToken, oldToken) => {
      if (newToken !== oldToken) {
        setLoading(true);
        await getVergleich(newToken);
      }
    },
    { immediate: true }
  );
  // watch(
  //   () => route.params.token,
  //   () => {
  //     // componentKeyStatus.value = componentKeyStatus.value + 1;
  //     setLoading(true);
  //   },
  //   { immediate: true }
  // );
  onMounted(() => {
    // getVergleich(route.params.token);
    berechnungStore.setShowFooter(false);
    proxy.$updateDocumentHead("Ergebnis", "Ergebnis");
  });
</script>
