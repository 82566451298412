<template>
  <v-layout class="greytext ggfont mt-4">
    <v-container class="mt-0">
      <v-row>
        <v-col cols="12" md="10">
          Liebe Heilpraktikerinnen, Liebe Heilpraktiker,
          <br /><br />
          immer mehr gesetzlich Versicherte wünschen sich einen bezahlbaren
          Zugang zu alternativen Heilmethoden / Naturheilkunde. Jedoch sehen die
          gesetzlichen Krankenkassen nun mal keine adäquate Kostenerstattung bei
          Heilpraktikern vor. Eine Zusatzversicherung bietet jedoch die
          Möglichkeit, Behandlungskosten beim Heilpraktiker teils erheblich zu
          senken. Helfen Sie Patienten und Besuchern Ihrer Praxis-Homepage, über
          leistungsstarke Naturheilkunde-Zusatzversicherungen (Abrechnung nach
          Hufelandverzeichnis / GebüH und GOÄ) zu informieren.
          <br /><br />
          <b>TIPP:</b><br />
          Bitte integrieren Sie jetzt DornsteinTabelle -
          https://www.dornsteintabelle.de/ auf Ihrer Praxis-Homepage. Ihre
          Patienten erhalten so Zugriff auf den tagesaktuellen Vergleich samt
          aussagekräftigen Testberichte. Bitte bestellen Sie DornsteinTabellen
          als DIN A4 Format zur Auslage in Ihren Praxisräumen.
          <br /><br />
          Sie möchten die DornsteinTabelle.de auf Ihrer Homepage integrieren und
          benötigen etwas Begleittext? Email an info@DornsteinTabelle.de genügt,
          ich verfasse individuell auf Ihre Seite einen Begleittext.
          <br /><br />
          Ihr Kilian Link
        </v-col>
        <v-col cols="12" md="2">
          <div style="border-left: 4px solid; border-left-color: #088a85">
            <v-avatar class="profile" tile color="grey" size="150">
              <v-img :src="require('@/assets/site/kilianlink.jpg')"></v-img>
            </v-avatar>
          </div>

          <br /><b>Kilian Link</b>, Entwickler der <b>Realwert-Methode</b> für
          Heilpraktiker-Zusatzversicherungen <br /><br /><br />
        </v-col>
      </v-row>
      <v-row v-if="user">
        <v-col cols="12" md="9">
          <v-card md12 xs12 class="pa-2">
            <h2 class="ggfont greytext">
              Jetzt als Heilpraktiker registrieren
              <sup class="green--text">kostenlos</sup>
            </h2>
            <br />Mit Ihrer Registrierung erhalten Sie Zugriff auf die
            DornsteinTabelle und können kostenlos weitere DornsteinTabellen für
            Ihre Praxis bestellen. <br />
            <br />

            <v-form ref="form" @submit.prevent="insertUser">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userdata.user_firma"
                    label="Praxisname"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userdata.user_email"
                    :rules="[checkTextFeld('user_email')]"
                    label="E-Mail"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                    label="Titel"
                    :items="['Frau', 'Herr', 'Dr.', 'Prof.']"
                  ></v-select
                ></v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="userdata.user_vorname"
                    :rules="[checkTextFeld('user_vorname')]"
                    label="Vorname"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="userdata.user_name"
                    :rules="[checkTextFeld('user_name')]"
                    label="Nachname"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="userdata.user_strasse"
                    :rules="[checkTextFeld('user_strasse')]"
                    label="Straße"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="userdata.user_hsnr"
                    label="Hausnummer"
                    :rules="[checkTextFeld('user_hsnr')]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="userdata.user_plz"
                    :rules="[checkTextFeld('user_plz')]"
                    label="PLZ"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="userdata.user_ort"
                    :rules="[checkTextFeld('user_ort')]"
                    label="Ort"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[checkTextFeld('user_pass_neu1')]"
                    v-model="userdata.user_pass_neu1"
                    label="Passwort"
                    type="password"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="userdata.user_pass_neu2"
                    label="Passwort wiederholen"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- :disabled="!valid" -->
              <v-btn color="turk" class="button_caps" @click="insertUser"
                >Jetzt kostenlos anmelden und Dornsteintabellen bestellen</v-btn
              >
            </v-form>
            <v-row>
              <v-col cols="12" md="10">
                <div v-if="isAntragError" class="my-4">
                  <h3 class="text-orange">
                    Bitte verbessern Sie noch ein paar Kleinigkeiten
                  </h3>
                  Bitte prüfen Sie noch Ihre Angaben der
                  <b class="text-red">rot-markierten</b> Felder.
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { ref, getCurrentInstance, computed, onMounted } from "vue";
  import { useUserStore } from "@/stores/user";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useErrorStore } from "@/stores/errorStore";
  import BaseCalls from "@/services/BaseCalls";
  import { useRouter } from "vue-router";

  const router = useRouter();
  const errorStore = useErrorStore();
  const loaderStore = useLoaderStore();
  const userStore = useUserStore();
  const user = computed(() => userStore.userData);
  const { proxy } = getCurrentInstance();

  const error_step2 = ref(false);
  const insertErfolg = ref(false);
  const error_step2_text = ref("");
  const isAntragError = ref(false);
  const form = ref(null);
  const userdata = ref({
    action: "insertUser",
    user_name: "",
    user_ansprechpartner: "",
    user_firma: "",
    user_email: "mails738@gmail.com",
    user_strasse: "",
    user_hsnr: "",
    user_plz: "",
    user_ort: "",
    user_pass_neu1: "",
    user_pass_neu2: "",
  });
  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }

  function checkFormular() {
    let fields = [
      "user_hsnr",
      "user_strasse",
      "user_plz",
      "user_ort",
      "user_name",
      "user_vorname",
      "user_email",
      "user_pass_neu1",
    ];

    // Stellen Sie sicher, dass antragdata.value existiert und korrekt strukturiert ist
    if (!userdata.value || typeof userdata.value !== "object") {
      console.error("Fehler: antragdata.value ist nicht korrekt definiert.");
      return false;
    }

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      let validateFunction = checkTextFeld(field);

      let errorField = validateFunction(userdata.value[field]);

      if (errorField !== true) {
        // Angenommen, checkTextFeld gibt 'true' zurück, wenn kein Fehler vorliegt
        console.log("Fehler im Feld: " + field + " - " + errorField);

        return false;
      }
    }

    // Alle Felder sind gültig
    isAntragError.value = false;
    return true;
  }

  const checkTextFeld = (feld) => {
    return (value) => {
      const trimValue = value && value.trim(); // trim einmal, verwende überall
      const errorMessages = {
        user_vorname: "Bitte geben Sie Ihren Vornamen an.",
        user_name: "Bitte geben Sie Ihren Nachnamen an.",
        user_ort: "Bitte geben Sie Ihren Ort an.",
        user_plz: "Bitte geben Sie Ihre PLZ an.",
        user_strasse: "Bitte geben Sie Ihre Straße an.",
        user_hsnr: "Bitte geben Sie Ihre Hausnummer an.",
        user_email: "Bitte geben Sie eine gültige E-Mail an.",
        user_pass_neu1: "Bitte geben Sie 2 identische Passwörter an.",
      };

      if (!trimValue) {
        return errorMessages[feld] || true;
      }
      if (feld == "user_email") {
        if (!validateEmail(trimValue)) {
          return errorMessages[feld] || true;
        }
      }

      if (feld == "user_pass_neu1") {
        if (userdata.value.user_pass_neu1 !== userdata.value.user_pass_neu2) {
          console.log("error in passwort");
          return errorMessages[feld] || true;
        }
      }

      return true; // Kein Fehler gefunden
    };
  };
  function validateEmail(value) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      console.log("error1 in email" + value);
      return false;
    } else if (!regex.test(value)) {
      console.log("error2 in email" + value);
      return false;
    }
    return true; // Kein Fehler
  }
  async function insertUser() {
    form.value.validate();
    if (!checkFormular()) {
      isAntragError.value = true;
      console.log("error vn form");
      return false;
    }
    setLoading(true);
    try {
      const resp = await BaseCalls.postUser(userdata.value); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.user) {
        userStore.setUser(resp.data.user);
        insertErfolg.value = true;
        router.push("/kostenlos/anfordern");
        setLoading(false);
      } else {
        error_step2_text.value = resp.data.error;
        error_step2.value = true;
      }
    } catch (err) {
      setLoading(false);
      errorStore.setError(true, "Fehler beim Registrieren.");
    }
  }

  onMounted(() => {
    proxy.$updateDocumentHead(
      "Kostenlos als Heilpraktiker registrieren | DornsteinTabelle",
      "Jetzt als Heilpraktiker auf DornsteinTabelle registrieren und kostenlos Support für Ihre Patienten rund um Heilpraktiker-Zusatzversicherungen erhalten."
    );
  });
</script>
